
/**
 * 
 * @param {string} url 
 * @param {{ width: number; height: number; x: number; y: number; }} pixelCrop 
 * @returns 
 */
export const generateImg = (url, pixelCrop) => {
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  return new Promise(resolve => {
    const img = new Image();
    if (pixelCrop.width) {
      img.onload = () => {
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
        resolve();
      };
    } else {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve();
      };
    }
    img.src = url;
  }).then(
    () =>
      new Promise(resolve => {
        canvas.toBlob(file => {
          file.name = 'sandTiket';
          resolve(file);
        }, 'image/jpeg');
      }),
  );
};

export const generateFormData = (data) => {
  const formData = new FormData();
  const file = new File([data.image], data.fileName);
  formData.append('orderId', data.orderId);
  formData.append('driverId', data.driverId);
  formData.append('sandTicketNo', data.ticketNo);
  formData.append('weight', data.weight);
  formData.append('bol', file);
  formData.append('king', data.king);
  formData.append('compartment', data.compartment);
  if (data.ticketType) {
    formData.append('sandTicketType', data.ticketType);
  }
  if (data.containerIds.length) {
    const selectedContainer = data.containerIds.map(item => item.value);
    if (selectedContainer.length) {
      formData.append('containerIds', `[${selectedContainer}]`);
    }
  }
  return formData;
}