import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import trackDriversActions from 'store/actions/TrackDrivers';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import { Col, Row } from 'react-bootstrap';
import { useDebounce } from 'helpers/hooks';
import { usePaginatedDrivers } from 'api/v2/drivers';
import FilterDrivers from './FilterDrivers';
import DriversList from './DriversList';
import TrackDriversMap from './Map';

const useQueryFilters = (filters, onFilterChange) => {
  const debouncedFilters = useDebounce(filters, 500);

  const mappedFilters = useMemo(
    () => ({
      truckNumber: debouncedFilters.number?.length
        ? debouncedFilters.number
        : undefined,
      driverName: debouncedFilters.name?.length
        ? debouncedFilters.name
        : undefined,
      status: [0, 1].includes(debouncedFilters.status)
        ? debouncedFilters.status
        : undefined,
      jobId: debouncedFilters.jobId ? debouncedFilters.jobId : undefined,
      online: debouncedFilters.offline === true,
      isDeleted: debouncedFilters.isDeleted ? 1 : 0,
    }),
    [debouncedFilters],
  );

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(mappedFilters);
    }
  }, [mappedFilters]);
  return mappedFilters;
};

const TrackDrivers = ({
  getJobs,
  clearStore,
  jobs,
  wellSites,
  sandSites,
  selectLocation,
  isLoadedJobs,
  filters,
}) => {
  useEffect(() => {
    getJobs();
    return () => clearStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { offline, name, number } = filters;

  const [pagination, setPagination] = useState({
    currentPage: 1,
  });
  const [pageLimit, setPageLimit] = useState(10);

  const applyFilters = () => {
    setPagination({ currentPage: 1 });
  };
  const queryFilters = useQueryFilters(filters, applyFilters);

  const { data: driverData, isLoading } = usePaginatedDrivers({
    ...queryFilters,
    page: pagination.currentPage,
    pageSize: pageLimit,
  });

  const drivers = useMemo(
    () =>
      (driverData?.drivers ?? []).filter(
        driver =>
          driver.name.toLowerCase().includes(name.toLowerCase()) &&
          driver.truck_no.toLowerCase().includes(number.toLowerCase()),
      ),
    [driverData, name, number],
  );

  const renderDriverList = () => (
    <Row>
      <Col md={5}>
        <TrackDriversMap
          drivers={drivers}
          sandPoints={sandSites}
          wellPoints={wellSites}
        />
      </Col>
      <Col md={7}>
        {isLoading ? (
          <Loader />
        ) : (
          <DriversList
            selectLocation={selectLocation}
            drivers={drivers}
            applyFilters={applyFilters}
            setPagination={setPagination}
            pagination={pagination}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            driverData={driverData}
          />
        )}
      </Col>
    </Row>
  );

  return (
    <div>
      <Header title="Track Driver" />
      <Authorize
        abilityPermissions={[{ resource: 'Drivers', permissions: ['read'] }]}>
        <FilterDrivers
          isLoadedJobs={isLoadedJobs}
          jobs={jobs}
          offline={offline}
        />
        {renderDriverList()}
      </Authorize>
    </div>
  );
};

const mapStateToProps = state => ({
  sandSites: state.lookUp.sandSites,
  wellSites: state.lookUp.wellSites,
  jobs: state.resourceReducer.jobs.jobs,
  isLoadedJobs: state.resourceReducer.jobs.isLoadedJobs,
  filters: state.trackDrivers.filterDrivers,
});

const mapDispatchToProps = dispatch => ({
  getJobs: () => dispatch(resourceActions.getJobs()),
  selectLocation: driver =>
    dispatch(trackDriversActions.selectDriverLocation(driver)),
  clearStore: () => dispatch(trackDriversActions.clearStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackDrivers);
