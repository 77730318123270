import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';

import { useCarrierDistricts, useCarriers } from 'api/v2/carriers';
import authService from 'services/auth/service';

import targetValue from 'utils/targetValue';
import { useEquipment } from 'api/v2/equipment';
import { useCreateDriver } from 'api/v2/drivers';
import { useAddDriverForm } from './hooks';

const user = authService.getUser();

const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const AddDriver = ({
  showModalAddDriver,
  closeAddDriver,
}) => {
  const [formState, { setValue }] = useAddDriverForm();
  const [carrierOptions, setCarrierOptions] = useState([]);

  const { data: carriers, isLoading: areTheCarriersLoading } = useCarriers();
  const { data: districts, isLoading: areTheCarriersDistrictsLoading } = useCarrierDistricts({ carrierId: Number(formState.carrierId) });
  const { data: requiredEquipment, isLoading: isTheRequiredEquipmentLoading } = useEquipment();
  const { mutate: addDriver, isPending: isTheCreateDriverRequestSending } = useCreateDriver();

  const handleSubmit = e => {
    e.preventDefault();
    const data = { ...formState, contactNo: formState.contactNo.replace(/\s+/g, '') };
    addDriver(
      data,
      {
        onSuccess: closeAddDriver
      }
    );
  }

  useEffect(() => {
    if (!carriers?.length || areTheCarriersLoading) {
      return () => setCarrierOptions([]);
    }

    // User is carrier
    if (carriers.length === 1 && carriers[0].id) {
      setValue('carrierId', carriers[0].id);
      setValue('carrierName', carriers[0].name || '');
    }

    setCarrierOptions(carriers.map(item => ({
      value: item.id,
      name: item.name,
      label: `${item.id} | ${item.name}`,
    })));

    return () => setCarrierOptions([]);
  }, [carriers, areTheCarriersLoading, setValue]);

  const isEmailValid = useCallback((email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }, []);

  const isFormValid = useMemo(() => (
    formState.name.trim().length > 0 &&
    formState.email.trim().length > 0 &&
    formState.contactNo.trim().length > 0 &&
    formState.carrierId !== undefined &&
    formState.vehicleNumber.trim().length > 0 &&
    formState.trailerNumber.trim().length > 0 &&
    formState.equipment > 0 &&
    isValidPhoneNumber(formState.contactNo) &&
    isEmailValid(formState.email)
  ), [formState, isEmailValid]);

  const districtOptions = useMemo(() => {
    if (!districts?.length) {
      return [];
    }

    const options = districts.map(item => ({
      value: item.id,
      label: `${item.id} | ${item.name}`,
    }))

    if (!options.find(i => i.value === defaultOnBoardingDistrict)) {
      options.push({
        value: defaultOnBoardingDistrict,
        label: `${defaultOnBoardingDistrict} | On-boarding`,
      });
    }

    return options;
  }, [districts]);


  const equipmentOptions = useMemo(() => {
    if (!requiredEquipment || isTheRequiredEquipmentLoading) {
      return [];
    }
    return requiredEquipment.map(item => ({
      value: item.id,
      label: item.title,
    }));
  }, [requiredEquipment, isTheRequiredEquipmentLoading]);

  return (
    <Modal
      className="modal-container"
      show={showModalAddDriver}
      onHide={closeAddDriver}>
      <Modal.Header>
        <Modal.Title as="h3">Add Driver</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {isTheCreateDriverRequestSending ? (
            <div data-testid="add-driver-modal-body-loader">
              <Loader />
            </div>
          ) : (
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={e => {
                    setValue('name', targetValue(e));
                  }}
                  testSelector="drivers-list_add_name_input"
                  value={formState.name}
                  label="Name"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  value={formState.email}
                  onChange={e => {
                    setValue('email', targetValue(e));
                  }}
                  testSelector="drivers-list_add_email_input"
                  required
                  label="Email"
                  type="email"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  type="tel"
                  onChange={e => {
                    setValue('contactNo', e.replace(/\s+/g, ''));
                  }}
                  value={formState.contactNo}
                  label="Country Code and Phone  Number"
                  required
                  testSelector="driver-details_edit_phone_input"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  onChange={e => {
                    setValue('carrierId', e.value);
                    setValue('carrierName', e.name);
                    setValue('district', null);
                  }}
                  options={carrierOptions}
                  label="Carrier"
                  value={carrierOptions.find(
                    item => item.value === formState.carrierId,
                  )}
                  required
                  accountForPadding={false}
                  testSelector="drivers_add_carrier_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  onChange={e => {
                    setValue('district', targetValue(e.value));
                  }}
                  options={districtOptions}
                  value={formState.district
                    ? districtOptions.find(d => d.value === formState.district)
                    : null
                  }
                  label="District"
                  required
                  isDisabled={areTheCarriersDistrictsLoading}
                  accountForPadding={false}
                  testSelector="drivers_add_district_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={e => {
                    setValue('vehicleNumber', targetValue(e));
                  }}
                  value={formState.vehicleNumber}
                  testSelector="drivers_add_truck-number_input"
                  label="Truck number"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Input
                  onChange={e => {
                    setValue('trailerNumber', targetValue(e));
                  }}
                  value={formState.trailerNumber}
                  testSelector="drivers_add_trailer-number_input"
                  label="Trailer number"
                  required
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <Select
                  value={equipmentOptions.find(
                    item => item.value === formState.equipment,
                  )}
                  options={equipmentOptions}
                  onChange={e => {
                    setValue('equipment', targetValue(e.value));
                  }}
                  label="Equipment"
                  accountForPadding={false}
                  required
                  testSelector="drivers_add_equipment_select"
                />
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeAddDriver}
            colour="white"
            testSelector="drivers-list_add-driver_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isFormValid || isTheCreateDriverRequestSending}
            testSelector="drivers-list_add-driver_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddDriver;
