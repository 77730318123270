import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import GoogleMap, {
  AutomatizeMarker,
  polyCoords,
  Polygon,
  Polyline,
  milesToMeters,
} from 'components/shared/GoogleMap';
import Icon from 'components/shared/Icon';
import { locationColors } from 'components/globalInfo/polygonColors';
import Loader from 'components/shared/Loader';

import { useOrder, useOrderBreadcrumbs } from 'api/v2/orders';
import { orderStatusMap, jobOrderStatus } from 'config/constants';
import { convertTimeWithSeconds } from 'utils/convertTime';
import { checkLocationIcon } from './helpers';

const OrderDetailsMap = ({ orderId }) => {
  const { data: orderDetails } = useOrder({ orderId });
  const { info: order } = useMemo(() => orderDetails ?? {}, [orderDetails]);
  const { data: breadcrumbs, isLoading } = useOrderBreadcrumbs({ orderId });

  const { jobDetails, driverJourneyLocationPoints, snappedPoints } = useMemo(
    () => breadcrumbs ?? {},
    [breadcrumbs],
  );

  const initialCenter = useMemo(() => {
    if (!jobDetails || !driverJourneyLocationPoints) {
      return {};
    }

    if (driverJourneyLocationPoints.length) {
      // Set center on last driver' location
      return {
        lat: Number(
          driverJourneyLocationPoints[driverJourneyLocationPoints.length - 1]
            .latitude,
        ),
        lng: Number(
          driverJourneyLocationPoints[driverJourneyLocationPoints.length - 1]
            .longitude,
        ),
      };
    }
    const viewLat =
      (Number(jobDetails.sandSite.latitude) +
        Number(jobDetails.wellSite.latitude)) /
      2;
    const viewLon =
      (Number(jobDetails.sandSite.longitude) +
        Number(jobDetails.wellSite.longitude)) /
      2;
    // Center between sandsite and wellsite
    return { lat: viewLat, lng: viewLon };
  }, [driverJourneyLocationPoints, jobDetails]);

  const formattedPoints = useMemo(() => {
    if (!driverJourneyLocationPoints?.length) {
      return null;
    }
    return driverJourneyLocationPoints.map(item => ({
      lat: item.latitude,
      lng: item.longitude,
    }));
  }, [driverJourneyLocationPoints]);

  const { wellSiteCoords, sandSiteCoords, stagingSiteCoords } = useMemo(() => {
    const coordinates = {
      wellSiteCoords: {
        lat: order.well_site_latitude,
        lng: order.well_site_longitude,
      },
      sandSiteCoords: {
        lat: order.sand_site_latitude,
        lng: order.sand_site_longitude,
      },
    };

    if (order.staging_site_latitude) {
      coordinates.stagingSiteCoords = {
        lat: order.staging_site_latitude,
        lng: order.staging_site_longitude,
      };
    }

    return coordinates;
  }, [order]);

  const locations = useMemo(() => {
    if (!jobDetails) {
      return null;
    }

    const driverPoints = cloneDeep(driverJourneyLocationPoints);

    const driverLocations = [
      {
        position: {
          lat: Number(jobDetails.sandSite.latitude),
          lng: Number(jobDetails.sandSite.longitude),
        },
        type: checkLocationIcon(jobDetails.sandSite.type),
        icon: checkLocationIcon(jobDetails.sandSite.type),
        title: jobDetails.sandSite.name,
        iconHeight: 40,
        iconWidth: 40,
      },
      {
        position: {
          lat: Number(jobDetails.wellSite.latitude),
          lng: Number(jobDetails.wellSite.longitude),
        },
        type: checkLocationIcon(jobDetails.wellSite.type),
        icon: checkLocationIcon(jobDetails.wellSite.type),
        title: jobDetails.wellSite.name,
        iconHeight: 40,
        iconWidth: 40,
      },
    ];

    if (jobDetails.stagingSite) {
      driverLocations.push({
        position: {
          lat: jobDetails.stagingSite.latitude,
          lng: jobDetails.stagingSite.longitude,
        },
        type: 'staging_site',
        icon: 'location_pending',
        title: jobDetails.stagingSite.name,
        iconHeight: 40,
        iconWidth: 40,
      });
    }
    if (driverPoints.length > 0) {
      driverLocations.push({
        position: {
          lat: driverPoints[driverPoints.length - 1].latitude,
          lng: driverPoints[driverPoints.length - 1].longitude,
        },
        type: 'driver',
        icon: 'driver',
        status:
          order.status === jobOrderStatus.DepartLoadingSite ||
            order.status === jobOrderStatus.Completed ||
            order.status === jobOrderStatus.EnteringWellSite ||
            order.status === jobOrderStatus.EnRouteToStage ||
            order.status === jobOrderStatus.ArrivedAtStage
            ? 'loaded'
            : 'empty',
        title: jobDetails.driverName,
        iconHeight: 40,
        iconWidth: 40,
      });
      driverLocations.push({
        position: {
          lat: driverPoints[0].latitude,
          lng: driverPoints[0].longitude,
        },
        type: 'start',
        icon: 'driver',
        title: 'Order Accepted',
        content: (
          <span style={{ fontSize: 16 }}>
            Time: {convertTimeWithSeconds(driverPoints[0].date)}
          </span>
        ),
        iconHeight: 40,
        iconWidth: 40,
      });
      driverPoints.shift();
      driverPoints.pop();

      driverPoints.forEach(item => {
        if (
          item.status === jobOrderStatus.JobAccepted ||
          item.status === jobOrderStatus.EnteringLoadingSite
        ) {
          driverLocations.push({
            position: {
              lat: Number(item.latitude),
              lng: Number(item.longitude),
            },
            type: 'to_origin',
            icon: 'driver',
            title: orderStatusMap[item.status],
            content: (
              <span style={{ fontSize: 16 }}>
                Time: {convertTimeWithSeconds(item.date)}
              </span>
            ),
            iconHeight: 10,
            iconWidth: 10,
            status: item.status,
          });
        }
        if (item.status === jobOrderStatus.DepartLoadingSite) {
          driverLocations.push({
            position: {
              lat: Number(item.latitude),
              lng: Number(item.longitude),
            },
            type: 'to_destination',
            icon: 'driver',
            title: jobDetails?.stagingSite
              ? 'En Route To Stage'
              : 'En Route to Destination',
            content: (
              <span style={{ fontSize: 16 }}>
                Time: {convertTimeWithSeconds(item.date)}
              </span>
            ),
            iconHeight: 10,
            iconWidth: 10,
            status: item.status,
          });
        }
        if (
          item.status === jobOrderStatus.Completed ||
          item.status === jobOrderStatus.EnteringWellSite ||
          item.status === jobOrderStatus.EnRouteToStage ||
          item.status === jobOrderStatus.ArrivedAtStage
        ) {
          driverLocations.push({
            position: {
              lat: Number(item.latitude),
              lng: Number(item.longitude),
            },
            type: 'to_destination',
            icon: 'driver',
            title: orderStatusMap[item.status],
            content: (
              <span style={{ fontSize: 16 }}>
                Time: {convertTimeWithSeconds(item.date)}
              </span>
            ),
            iconHeight: 10,
            iconWidth: 10,
            status: item.status,
          });
        }
      });
    }

    return driverLocations;
  }, [driverJourneyLocationPoints, jobDetails, order]);

  if (isLoading) {
    return <Loader />;
  }

  // TODO: Why is this unused??
  // const onReady = (pointData, google, map) => {
  //   const chunkedPoints = chunk(pointData, 24);

  //   chunkedPoints.forEach((pointArray, index) => {
  //     const directionsService = new google.maps.DirectionsService();
  //     const directionsDisplay = new google.maps.DirectionsRenderer({
  //       map,
  //       suppressMarkers: true,
  //       preserveViewport: true,
  //       polylineOptions: {
  //         strokeColor: 'green',
  //       },
  //     });

  //     const waypoints = pointArray.map(item => ({
  //       location: { lat: item.lat, lng: item.lng },
  //     }));

  //     const origin = waypoints.shift().location;
  //     const destination = waypoints.length ? waypoints.pop().location : origin;

  //     const request = {
  //       origin,
  //       destination,
  //       waypoints,
  //       travelMode: 'DRIVING',
  //     };

  //     setTimeout(() => {
  //       directionsService.route(request, (response, status) => {
  //         if (status === 'OK') {
  //           directionsDisplay.setDirections(response);
  //         } else {
  //           swal(`Directions request failed due to ${status}`, '', 'error');
  //         }
  //       });
  //     }, index * index * 100);
  //   });
  // };

  return (
    <div style={{ height: 650, position: 'relative' }}>
      <GoogleMap
        fitMarkers
        initialCenter={initialCenter}
        zoom={12}
        pointData={formattedPoints}
        setInitialCenter>
        <Polyline
          path={snappedPoints}
          strokeColor="#008000"
          strokeOpacity={0.8}
          strokeWeight={2}
        />
        {locations.map((item, index) => (
          <AutomatizeMarker
            position={item.position}
            type={item.type}
            status={item.status}
            key={`${item.type}${index}`}
            iconHeight={item.iconHeight}
            iconWidth={item.iconWidth}
            infoWindow={{
              title: (
                <>
                  <Icon icon={item.icon} colour="white" />
                  <span className="info-window__title--text">{item.title}</span>
                </>
              ),
              content: item.content,
              width: 380,
            }}
          />
        ))}
        <Polygon
          paths={order.well_site_polygon_geofence ? order.well_site_geofence_vertices : polyCoords(
            milesToMeters(order.well_site_geofence),
            30,
            wellSiteCoords.lat,
            wellSiteCoords.lng,
          )}
          strokeColor={locationColors[jobDetails.wellSite.type].color}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={locationColors[jobDetails.wellSite.type].color}
          fillOpacity={0}
        />
        <Polygon
          paths={order.sand_site_polygon_geofence ? order.sand_site_geofence_vertices : polyCoords(
            milesToMeters(order.sand_site_geofence),
            30,
            sandSiteCoords.lat,
            sandSiteCoords.lng,
          )}
          strokeColor={locationColors[jobDetails.sandSite.type].color}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={locationColors[jobDetails.sandSite.type].color}
          fillOpacity={0}
        />
        {stagingSiteCoords && (
          <Polygon
            paths={order.staging_site_polygon_geofence ? order.staging_site_geofence_vertices : polyCoords(
              milesToMeters(order.staging_site_geofence_range),
              30,
              stagingSiteCoords.lat,
              stagingSiteCoords.lng,
            )}
            strokeColor={locationColors[4].color}
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor={locationColors[4].color}
            fillOpacity={0}
          />
        )}
      </GoogleMap>
    </div>
  );
};
export default OrderDetailsMap;
