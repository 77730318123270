import React, { useState } from 'react';
import Modal, { ModalForm, FullWidth } from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import targetValue from 'utils/targetValue';
import { useChangeDriverPercentage } from 'api/v2/drivers';

const ChangePercentage = ({
  showModalPercentage,
  closePercentage,
  driver
}) => {
  const [percentage, setPercentage] = useState(driver.pay_percentage);
  const { mutate: changePercentage, isPending: changePercentagePending } = useChangeDriverPercentage();

  const handleSave = e => {
    e.preventDefault();
    changePercentage(
      {
        driverId: Number(driver.id),
        percentage
      },
      {
        onSuccess: closePercentage,
      },
    );
  }


  const header = <h3>Edit Pay Percentage</h3>;
  const body = (
    <ModalForm>
      <FullWidth>
        <Input
          type="number"
          onChange={e => setPercentage(targetValue(e))}
          value={percentage}
          label="Percentage"
          required
          testSelector="drivers_change-percentage_percentage_input"
        />
      </FullWidth>
    </ModalForm>
  );
  const footer = (
    <>
      <Button
        onClick={closePercentage}
        colour="white"
        testSelector="drivers-list_change-percentage_close_btn">
        Close
      </Button>
      <Button
        onClick={handleSave}
        disabled={!percentage || changePercentagePending}
        testSelector="drivers-list_change-percentage_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalPercentage}
      onClose={closePercentage}
    />
  );
};

export default ChangePercentage;
