import { order } from 'services/order/service';
import FileSaver from 'file-saver';
import swal from 'bootstrap-sweetalert';
import { queryClient } from 'api';

export const ticketsRequested = orderId => async dispatch => {
  dispatch({ type: 'TICKETS_REQUESTED' });

  const tickets = await order.getTickets(orderId).catch(error => dispatch({ type: 'TICKETS_REQUEST_FAILED' }));

  return dispatch({
    tickets: tickets.data.data.tickets,
    config: tickets.data.data.config,
    type: 'TICKETS_REQUEST_SUCCEEDED',
  });
};

export const jobPlansticketsRequested = orderId => async dispatch => {
  dispatch({ type: 'TICKETS_REQUESTED' });

  const tickets = await order.getTickets(orderId).catch(error => dispatch({ type: 'TICKETS_REQUEST_FAILED' }));

  return dispatch({
    tickets: tickets.data.data.tickets,
    config: tickets.data.data.config,
    type: 'TICKETS_REQUEST_SUCCEEDED',
  });
};

export const ticketPreviewRequested = (orderId, ticketId) => async dispatch => {
  dispatch({ type: 'TICKET_PREVIEW_REQUESTED' });

  const response = await order.getSandTicket(orderId, ticketId).catch(err => dispatch({ type: 'TICKET_PREVIEW_REQUEST_FAILED' }));
  const file = new Blob([response.data]);
  const fileURL = URL.createObjectURL(file);

  dispatch({
    type: 'TICKET_PREVIEW_REQUEST_SUCCEEDED',
  });
  return fileURL;
};

export const ticketUpdateRequested = (params, callback) => async dispatch => {
  dispatch({ type: 'TICKET_ACTION_REQUESTED' });

  await order.editSandTicket(params).catch(err => dispatch({ type: 'TICKET_ACTION_REQUEST_FAILED' }));

  queryClient.invalidateQueries({ queryKey: ['orders'] })
  dispatch({
    type: 'TICKET_ACTION_REQUEST_SUCCEEDED',
  });

  if (callback) {
    return callback();
  }
};

export const ticketRemovalRequested = (
  orderId,
  ticketId,
  callback
) => async dispatch => {
  dispatch({ type: 'TICKET_ACTION_REQUESTED' });

  await order.deleteSandTicket(orderId, ticketId).catch(err => dispatch({ type: 'TICKET_ACTION_REQUEST_FAILED' }));

  queryClient.invalidateQueries({ queryKey: ['orders'] });
  dispatch({
    type: 'TICKET_ACTION_REQUEST_SUCCEEDED',
  });
  swal.close();

  if (callback) {
    return callback();
  }
};

export const ticketsDownloadAllTickets = orderId => async dispatch => {
  dispatch({ type: 'DOWNLOAD_ALL_TICKETS_REQUESTED' });

  const response = await order.downloadAllTickets(orderId).catch(error => {
    swal(error.response.data.message, '', 'error');
    dispatch({ type: 'DOWNLOAD_ALL_TICKETS_FAILED' });
  });

  const file = new Blob([response.data], { type: 'application/zip' });
  FileSaver.saveAs(file, `order-${orderId}.zip`);
  return dispatch({
    type: 'DOWNLOAD_ALL_TICKETS_SUCCEEDED',
  });
};

export const ticketDownloadSingleTicket = (
  orderId,
  ticketId,
) => async dispatch => {
  dispatch({ type: 'DOWNLOAD_SINGLE_TICKETS_REQUESTED' });

  const response = await order.getSandTicket(orderId, ticketId).catch(error => {
    swal(error.response.data.message, '', 'error');
    dispatch({ type: 'DOWNLOAD_SINGLE_TICKETS_FAILED' });
  });

  const file = new Blob([response.data], { type: 'application/png' });
  FileSaver.saveAs(file, `order-${orderId}.png`);

  return dispatch({
    type: 'DOWNLOAD_SINGLE_TICKETS_SUCCEEDED',
  });
};

export default {
  ticketsRequested,
  jobPlansticketsRequested,
  ticketPreviewRequested,
  ticketUpdateRequested,
  ticketRemovalRequested,
  ticketsDownloadAllTickets,
  ticketDownloadSingleTicket,
};
