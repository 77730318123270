import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import actions from 'store/actions/OrderActions';
import { confirmAlert } from 'components/sweetalert';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';
import { authorize } from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import { useJobSharedOperators } from 'api/v2/jobs';

import Modify from './Modify';
import UploadOrderTicket from './UploadTicket';
import Divert from './DivertModal';
import Transfer from './Transfer';
import Requeue from './Requeue';
import DemurrageReason from './DemurrageReason';
import { setTitle } from './helpers';
import { useDemurrageModal, useOrderFromQR, useOrderModals } from './hooks';

const { VENDOR, CUSTOMER, CARRIER, JOB_COORDINATOR, JOB_MANAGER } = ROLES;
const accessAll = [VENDOR, CUSTOMER, CARRIER, JOB_COORDINATOR, JOB_MANAGER];
const allStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8];

const OrderActions = ({
  order: initOrder,
  exportComponent,

  // Actions
  releaseDriver,
  callToWell,
  deleteOrder,
  reviveOrder,
}) => {
  const { openModals, toggleModal } = useOrderModals();
  const demurrageModal = useDemurrageModal();

  const user = authService.getUser() || {};

  const order = useOrderFromQR(initOrder);
  const { data: operators } = useJobSharedOperators({ jobId: order.job_id });

  useEffect(() => {
    if (order.fromQr) {
      window.history.pushState('', '', window.location.pathname);
      toggleModal('modify')();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.fromQr]);

  const components = !order.is_deleted
    ? [
        {
          component: (
            <Dropdown.Item key="upload" onClick={toggleModal('uploadTicket')}>
              Upload Ticket
            </Dropdown.Item>
          ),
          statuses: [2, 3, 4, 5, 6, 7, 8],
          roles: accessAll,
        },
        {
          component: (
            <Dropdown.Item
              key="release_driver"
              onClick={() => confirmAlert(() => releaseDriver(order.order_id))}>
              Release Driver
            </Dropdown.Item>
          ),
          statuses: [2, 5],
          roles: [JOB_MANAGER, CARRIER, CUSTOMER, JOB_COORDINATOR],
          if: o => o.driver_id != null,
        },
        {
          component: (
            <Dropdown.Item key="call_to_well" onClick={() => callToWell(order)}>
              Call to Destination
            </Dropdown.Item>
          ),
          statuses: [6, 7, 8],
          roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER],
          if: o => o.call_to_well_status === 0,
        },
        {
          component: (
            <Dropdown.Item key="Requeue" onClick={toggleModal('requeue')}>
              {setTitle(order.status)}
            </Dropdown.Item>
          ),
          statuses: [0, 1, 2, 5],
          roles: [JOB_MANAGER, JOB_COORDINATOR, CUSTOMER],
          if: o =>
            (o.driver_id == null && (o.status === 0 || o.status === 1)) ||
            o.status === 2 ||
            o.status === 5,
        },
        {
          component: (
            <Dropdown.Item key="Requeue" onClick={toggleModal('requeue')}>
              {setTitle(order.status)}
            </Dropdown.Item>
          ),
          statuses: [0, 1, 2, 5],
          roles: [CARRIER],
          if: () =>
            order.accessLevel === 'owner' || order.accessLevel === 'shared',
        },
      ]
    : [];

  if (
    authorize({
      itemAccessGranted: !order.is_deleted,
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['delete'],
        },
      ],
    }) &&
    order.billing_status !== 21 &&
    order.billing_status !== 22 &&
    order.billing_status !== 23
  ) {
    components.push({
      component: (
        <Dropdown.Item
          key="delete"
          onClick={() => confirmAlert(() => deleteOrder(order))}>
          Delete
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER, CARRIER],
    });
  }
  if (
    authorize({
      itemAccessGranted: !order.is_deleted,
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['update'],
        },
      ],
    })
  ) {
    components.unshift({
      component: (
        <Dropdown.Item key="modify" onClick={toggleModal('modify')}>
          Modify & Complete Order
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER, CARRIER],
    });
  }

  if (
    order.is_deleted &&
    authorize({
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['update'],
        },
      ],
    })
  ) {
    components.push({
      component: (
        <Dropdown.Item
          key="revive"
          onClick={() => confirmAlert(() => reviveOrder(order))}>
          Revive Order
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [JOB_MANAGER, CUSTOMER, CARRIER],
    });
  }

  if (Number(user.companyType) !== 4) {
    components.splice(2, 0, {
      component: (
        <Dropdown.Item key="transfer" onClick={toggleModal('transfer')}>
          Transfer
        </Dropdown.Item>
      ),
      statuses: [2, 3, 5, 6, 7, 8],
      roles: [JOB_MANAGER, CARRIER, CUSTOMER],
    });
  }

  if (
    order.driver_id &&
    authorize({
      itemAccessGranted:
        user.role !== CARRIER || operators.some(op => op.id === user.entityId),
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['update'],
        },
      ],
    })
  ) {
    components.push({
      component: (
        <Dropdown.Item key="Divert" onClick={toggleModal('divert')}>
          Divert to job
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [JOB_MANAGER, CUSTOMER, JOB_COORDINATOR, CARRIER],
    });
  }

  const handleShowDemurrageReason = data => {
    if (
      (Number(data.sandDemurrage.price) !== 0 &&
        !data.sandSiteDemurrageReasonId) ||
      (Number(data.wellDemurrage.price) !== 0 &&
        !data.wellSiteDemurrageReasonId)
    ) {
      demurrageModal.toggle(data);
    }
  };

  return (
    <span>
      {exportComponent.modals && (
        <span>
          <Modify
            orderId={order.order_id}
            openModal={openModals.modify}
            turnModal={toggleModal('modify')}
            onSuccess={handleShowDemurrageReason}
          />
          <DemurrageReason
            orderId={order.order_id}
            openModal={demurrageModal.show}
            turnModal={demurrageModal.toggle}
            demurrageData={demurrageModal.data}
          />
          <UploadOrderTicket
            orderId={order.order_id}
            openModal={openModals.uploadTicket}
            turnModal={toggleModal('uploadTicket')}
          />
          <Divert
            order={order}
            openModal={openModals.divert}
            turnModal={toggleModal('divert')}
          />
          {openModals.transfer && (
            <Transfer
              order={order}
              openModal={openModals.transfer}
              turnModal={toggleModal('transfer')}
            />
          )}
          {openModals.requeue && (
            <Requeue
              order={order}
              openModal={openModals.requeue}
              setTitle={setTitle}
              turnModal={toggleModal('requeue')}
            />
          )}
        </span>
      )}
      {exportComponent.actions && (
        <Dropdown style={{ display: 'inline' }}>
          <span key="Download">
            <Dropdown.Toggle
              as="button"
              className="button button--label-type button--orange">
              Actions
              <Icon icon="down" className="icon--margin-left" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-user">
              {components.map(
                item =>
                  item.statuses.includes(order.status) &&
                  item.roles.includes(user.role) &&
                  item.component,
              )}
            </Dropdown.Menu>
          </span>
        </Dropdown>
      )}
    </span>
  );
};

const mapStateToProps = state => ({
  orderActions: state.orderActions,
  showModalDemurrage: state.orderActions.demurrageReason.openModal,
});

const mapDispatchToProps = dispatch => ({
  releaseDriver: orderId => dispatch(actions.releaseDriver(orderId)),
  callToWell: order => dispatch(actions.callToWell(order)),
  deleteOrder: order => dispatch(actions.deleteOrder(order)),
  reviveOrder: order => dispatch(actions.reviveOrder(order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderActions);
