import React, { useEffect } from 'react';

import Loader from 'components/shared/Loader';

import { useOrders } from 'api/v2/orders';
import Pagination from 'components/shared/Pagination';
import { useDebounce, usePaging } from 'helpers/hooks';
import { Accordion, Card } from 'react-bootstrap';
import CardToggle from 'components/common/CardToggle';
import Order from './Order';
import FilterOrders from './Filters';
import { formatOrderSearchParams } from './helpers';
import { useOrderFilters } from './hooks';

const OrdersList = ({ initFilters, onFilterChange }) => {
  const [pageState, pageHandlers] = usePaging();

  const { data: filters, handlers } = useOrderFilters({
    defaultValue: initFilters,
  });
  const debouncedFilters = useDebounce(filters, 1000);

  const { data: orderData, refetch, isPlaceholderData, isLoading } = useOrders({
    params: formatOrderSearchParams(debouncedFilters),
    page: pageState.page,
    pageSize: pageState.size,
  });

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(filters);
    }
  }, [filters, onFilterChange]);

  const handleFilterChange = (e, name) => {
    handlers.valueChange(name)(e);
    pageHandlers.handlePageChange(1);
  };

  const handleDateChange = range => {
    handlers.valueChange('startDate')(range.selection.startDate);
    handlers.valueChange('endDate')(range.selection.endDate ?? '');
    pageHandlers.handlePageChange(1);
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header as="h3">
          Orders
          <CardToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ minHeight: window.innerHeight }}>
            {filters.show && (
              <FilterOrders
                filters={filters}
                setFilterValue={handleFilterChange}
                setDate={handleDateChange}
                count={orderData?.count}
              />
            )}
            <Pagination
              totalPages={orderData?.totalPages}
              currentPage={pageState.page}
              onPageChange={({ currentPage }) =>
                pageHandlers.handlePageChange(currentPage)
              }
              currentLimit={pageState.size}
              onLimitChange={pageHandlers.handleSizeChange}
              isDisabled={isPlaceholderData}
              withLimit
            />
            {isLoading || !orderData?.orders ? (
              <Loader />
            ) : (
              <Order orders={orderData?.orders} refreshOrder={refetch} />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default OrdersList;
