import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import swal from 'bootstrap-sweetalert';
import FileSaver from 'file-saver';
import AddTrailer from './AddTrailer';

const BulkUpload = ({
  showModal,
  closeModal,
  customers
}) => {
  const [file, setFile] = useState();
  const [containerNumbers, setContainerNumbers] = useState([]);

  const validateFile = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const { result } = e.target;
      const lines = result.split('\n')
      const numbers = lines.map(line => line.split(',')[0].trim()).filter(name => name.length !== 0);

      if (!numbers.length) {
        swal('Error', 'Invalid file format', 'error');
        setFile(null);
      } else {
        setContainerNumbers(numbers);
      }
    }

    reader.readAsText(file);
  }

  if (containerNumbers?.length) {
    return (
      <AddTrailer
        bulkUploadContainers
        containerNumbers={containerNumbers}
        closeAddTrailer={closeModal}
        showAddTrailer={showModal}
        customers={customers}
      />
    );
  }

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showModal}
      onHide={closeModal}>
      <Modal.Header>
        <h3>Bulk Upload Containers</h3>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <span>Upload a CSV file to bulk upload containers. (First column, 1 container number per row)
            <a href="#" onClick={
              () =>
                FileSaver.saveAs(
                  new Blob([['Container_A', 'Container_B', 'Container_C'].join('\n')]),
                  'container_upload_example.csv'
                )
            }> Download Example
            </a>
          </span>
        </Row>
        <br />
        <Row>
          <Input
            type="file"
            label="Upload CSV"
            accept=".csv"
            required
            onChange={e => setFile(e.target.files[0])}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button colour="white" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!file}
          onClick={validateFile}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BulkUpload;
