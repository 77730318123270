import { useEffect, useMemo, useState } from 'react';
import { useDemurrageReasons, useOrder } from 'api/v2/orders';
import targetValue from 'utils/targetValue';

export const useDemurrageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();

  const toggleModal = demurrageData => {
    setData(demurrageData);
    setShowModal(value => !value);
  };

  return {
    data,
    show: showModal,
    toggle: toggleModal,
  };
};

export const useDemurrageOptions = () => {
  const { data: demurrageReasons } = useDemurrageReasons();
  const demurrageOptions = useMemo(
    () =>
      demurrageReasons.map(item => ({
        id: item.id,
        name: item.name,
        label: item.name,
        options: item.children.map(child => ({
          label: child.name,
          value: child.id,
        })),
      })),
    [demurrageReasons],
  );
  return demurrageOptions;
};

export const useDemurrageData = ({ orderId, priceType }) => {
  const { data: orderDetails, isLoading } = useOrder({
    orderId,
  });
  const { demurrageData, demurrageReason } = useMemo(() => {
    const data = {
      demurrageData: {
        sandsiteDemurrageReasonId:
          orderDetails?.info?.sandsite_demurrage_reason_id,
        wellsiteDemurrageReasonId:
          orderDetails?.info?.wellsite_demurrage_reason_id,
      },
    };
    if (priceType === 3) {
      data.demurrageReason = data.demurrageData.wellsiteDemurrageReasonId;
    } else {
      data.demurrageReason = data.demurrageData.sandsiteDemurrageReasonId;
    }
    return data;
  }, [orderDetails, priceType]);

  return {
    demurrageData,
    demurrageReason,
    isLoading,
  };
};

export const useDemurrageReasonForm = ({ defaultValue, showModal }) => {
  /** @type {[number, () => {}]} */
  const [reason, setReason] = useState();

  useEffect(() => {
    if (showModal && defaultValue) {
      setReason(defaultValue);
    }
  }, [defaultValue, showModal]);

  const isValid = useMemo(() => !!reason, [reason]);

  const handlers = useMemo(
    () => ({
      valueChange: e => setReason(targetValue(e.value ? e.value : e)),
      clear: () => setReason(undefined),
    }),
    [],
  );

  return {
    data: {
      reason,
    },
    isValid,
    handlers,
  };
};
