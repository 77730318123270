export const jsonToCsv = (data, doesCommasNeedToBeSanitized = false) => {
  const csv = [[Object.keys(data[0])]];
  for (let i = 0; i < data.length; i++) {
    csv.push([Object.values(data[i])]);
  }
  if (doesCommasNeedToBeSanitized) {
    for (let i = 0; i < csv.length; i++) {
      for (let j = 0; j < csv[i].length; j++) {
        for (let k = 0; k < csv[i][j].length; k++) {
          if (
            typeof csv[i][j][k] === 'string' ||
            csv[i][j][k] instanceof String
          ) {
            csv[i][j][k] = csv[i][j][k].replaceAll(',', '');
          }
        }
      }
    }
  }
  return csv.map(row => row.join(',')).join('\n');
};

