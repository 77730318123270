import React, { useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Label from 'components/shared/Label';
import Select from 'components/shared/Select';
import multiSelectOptions from 'utils/multiSelectOptions';
import { useCarrierDistricts } from 'api/v2/carriers';
import { useChangeDriverDistricts } from 'api/v2/drivers';
import targetValue from 'utils/targetValue';

const ChangeDistrict = ({
  closeModalAddDistrict,
  showModalAddDistrict,
  driverDistricts,
  carrierId,
  driverId
}) => {
  const [selectedDistricts, setSelectedDistricts] = useState(multiSelectOptions(driverDistricts, 'id', 'name'));
  const { data: carrierDistricts } = useCarrierDistricts({ carrierId });
  const { mutate: changeDistricts, isPending: isChangeDistrictsPending } = useChangeDriverDistricts();

  const handleSubmit = e => {
    e.preventDefault();
    changeDistricts(
      {
        driverId,
        districtIds: selectedDistricts.map(district => district.id),
      },
      {
        onSuccess: closeModalAddDistrict
      }
    );
  }

  const saveDisabled = useMemo(() => (
    !selectedDistricts?.length || isChangeDistrictsPending
  ), [selectedDistricts, isChangeDistrictsPending]);

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showModalAddDistrict}
        onHide={closeModalAddDistrict}>
        <Modal.Header closeButton>
          <h3>Change districts</h3>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {carrierDistricts && carrierDistricts.length ? (
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={e => setSelectedDistricts(targetValue(e))}
                options={multiSelectOptions(carrierDistricts, 'id', 'name')}
                placeholder="Select district"
                value={selectedDistricts}
                testSelector="driver-details_change-district_districts_select"
              />
            ) : (
              <Label type="warning">Carrier has no districts</Label>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeModalAddDistrict}
              colour="white"
              children="Close"
              testSelector="driver-details_change-district_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              disabled={saveDisabled}
              testSelector="driver-details_change-district_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ChangeDistrict;
