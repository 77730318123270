import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const usePolyline = ({
  onClick,
  onDrag,
  onDragStart,
  onDragEnd,
  onMouseOver,
  onMouseOut,
  encodedPath,
  ...polylineOptions
}) => {
  const callbacks = useRef((e) => ({}));
  callbacks.current = {
    ...callbacks.current,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut
  }

  const geometryLibrary = useMapsLibrary('geometry');

  const polyline = useRef(new google.maps.Polyline(polylineOptions)).current;

  useMemo(() => {
    if (!polyline) {
      return;
    }

    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useMap();

  useMemo(() => {
    if (!encodedPath || !geometryLibrary) {
      return;
    }

    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [polyline, encodedPath, geometryLibrary]);

  useEffect(() => {
    if (!map) {
      return;
    }

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, polyline]);

  useEffect(() => {
    if (!polyline) {
      return;
    }

    const gme = google.maps.event;
    [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut']
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polyline, eventName, (e) => {
        const callback = callbacks.current[eventCallback];

        if (callback) {
          callback(e);
        }
      });
    });

    return () => {
      gme.clearInstanceListeners(polyline);
    };

  }, [polyline]);

  return polyline
};

const Polyline = forwardRef((props, ref) => {
  const polyline = usePolyline(props);
  useImperativeHandle(ref, () => polyline);
  return null;
});

export default Polyline;
