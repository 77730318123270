import React, { useState } from 'react';
import { authorize } from 'components/common/Authorize';
import Button from 'components/shared/Button';
import ChangeDistrict from './ChangeDistrict';
import DistrictBox from './DistrictBox';

const Districts = ({ driverDistricts, carrierId, driverId }) => {
  const [showModalAddDistrict, turnModalAddDistrict] = useState(false);

  return (
    <div>
      {showModalAddDistrict && (
        <ChangeDistrict
          driverDistricts={driverDistricts}
          carrierId={carrierId}
          driverId={driverId}
          showModalAddDistrict={showModalAddDistrict}
          closeModalAddDistrict={() => {
            turnModalAddDistrict(false);
          }}
        />
      )}
      {(authorize({
        access: ['JOB_MANAGER'],
        abilityPermissions: [
          {
            resource: 'Drivers',
            permissions: ['update'],
          },
        ],
      }) ||
        authorize({
          access: ['CARRIER'],
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        })) && (
          <Button
            onClick={() => turnModalAddDistrict(true)}
            testSelector="driver-details_change-district_btn"
            className="float-end">
            Change districts
          </Button>
        )}
      {(driverDistricts.length && (
        <div className="row districts-box-container">
          {driverDistricts.map(district => (
            <DistrictBox id={district.id} name={district.name} key={`dist-${district.id}`} />
          ))}
        </div>
      )) || <h4>You don't have districts yet</h4>}
    </div>
  );
};

export default Districts;
