import { combineReducers } from '@reduxjs/toolkit';
import { resourceConstants } from 'store/modules/resourceReducer/constants';

const initialStateEquipmentStatus = {
  customerId: '',
  equipmentId: '',
  sandTypeId: {},
  sandVendorId: {},
  weight: 0,
  status: {},
};

const equipmentStatus = (state = initialStateEquipmentStatus, action) => {
  const getStatusLabel = (status) => {
    if (status === 1) {
      return 'Staged';
    }
    if (status === 2) {
      return 'Unloading';
    }
    return 'Empty';
  }

  switch (action.type) {
    case 'SET_EQUIPMENT_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.id,
      };
    case 'SET_EQUIPMENT_DATA':
      return {
        ...state,
        equipmentId: action.data.id,
        customerId: action.data.customerId,
        sandTypeId: {
          value: action.data.sandTypeId,
          label: action.data.sandTypeName,
        },
        sandVendorId: {
          value: action.data.sandVendorId,
          label: action.data.sandVendorName,
        },
        weight: action.data.weight,
        status: {
          value: action.data.status,
          label: getStatusLabel(action.data.status),
        },
      };
    case 'SET_EQUIPMENT_STATUS':
      return {
        ...state,
        status: action.status,
      };
    case 'SET_EQUIPMENT_SAND_TYPE_ID':
      return {
        ...state,
        sandTypeId: action.sandTypeId,
      };
    case 'SET_EQUIPMENT_SAND_VENDOR_ID':
      return {
        ...state,
        sandVendorId: action.vendorId,
      };
    case 'SET_EQUIPMENT_WEIGHT':
      return {
        ...state,
        weight: action.weight,
      };
    case resourceConstants.CLEAR_STORE_EQUIPMENT:
      return initialStateEquipmentStatus;

    default:
      return state;
  }
};

const initialStateEquipmentLocation = {
  locationType: '',
  locationId: '',
  locations: [],
  equipmentId: '',
  comment: null,
};

const equipmentLocation = (state = initialStateEquipmentLocation, action) => {
  switch (action.type) {
    case 'SET_EQUIPMENT_LOCATION_DATA':
      return {
        ...state,
        locationType: action.data.locationType,
        locationId: action.data.locationId,
        equipmentId: action.data.id,
        customerId: action.data.customerId
      };
    case 'SET_LOCATIONS_TO_TYPE':
      return {
        ...state,
        locations: action.locations,
      };
    case 'SET_LOCATION_TYPE':
      return {
        ...state,
        locations: [],
        locationType: action.locationType,
      };
    case 'SET_EQUIPMENT_LOCATION_ID':
      return {
        ...state,
        locationId: action.locationId,
      };
    case 'SET_EQUIPMENT_LOCATION_COMMENT':
      return {
        ...state,
        comment: action.comment,
      };
    case 'CLOSE_MODAL_EQUIPMENT':
      return initialStateEquipmentLocation;
    default:
      return state;
  }
};

const initialStateEquipmentHistory = {
  isLoadedEquipmentHistory: false,
  history: [],
};

const equipmentHistory = (state = initialStateEquipmentHistory, action) => {
  switch (action.type) {
    case 'GET_EQUIPMENT_HISTORY_REQUEST':
      return {
        ...state,
      };
    case 'GET_EQUIPMENT_HISTORY_SUCCESS':
      return {
        ...state,
        isLoadedEquipmentHistory: true,
        history: action.history,
      };
    case 'CLEAR_STORE_EQUIPMENT_HISTORY':
      return {
        ...state,
        ...initialStateEquipmentHistory
      }
    case 'GET_EQUIPMENT_HISTORY_FAILURE':
      return {};
    default:
      return state;
  }
};

const initialStateAddTrailer = {
  trailerNumber: '',
  wellSiteId: '',
  homeLocationId: '',
  vendorId: '',
};

const addTrailer = (state = initialStateAddTrailer, action) => {
  switch (action.type) {
    case 'SET_TRAILER_NUMBER':
      return {
        ...state,
        trailerNumber: action.trailerNumber,
      };
    case 'SET_WELLSITE_LOCATION':
      return {
        ...state,
        wellSiteId: action.wellSiteId,
      };
    case 'SET_HOME_LOCATION':
      return {
        ...state,
        homeLocationId: action.homeLocationId,
      };
    case 'SET_TRAILER_VENDOR':
      return {
        ...state,
        vendorId: action.vendorId,
      };
    case 'CLEAR_ADD_TRAILER':
      return initialStateAddTrailer;
    default:
      return state;
  }
};

const initialStateEditContainer = {
  containerId: '',
  customerId: {},
  containerNumber: '',
  containerVendorId: {},
  homeLocation: '',
};

const editContainer = (state = initialStateEditContainer, action) => {
  switch (action.type) {
    case 'SET_EDIT_EQUIPMENT_DATA':
      return {
        ...state,
        containerId: action.data.id,
        customerId: {
          value: action.data.customerId,
          label: action.data.name,
        },
        containerNumber: action.data.equipmentNumber,
        containerVendorId: {
          value: action.data.containerVendorId,
          label: action.data.containerVendorName,
        },
        homeLocation: {
          value: action.data.homeLocationId,
          label: action.data.homeLocationName,
        },
      };
    case 'SET_EDIT_EQUIPMENT_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.customerId,
      };
    case 'SET_EDIT_EQUIPMENT_NUMBER':
      return {
        ...state,
        containerNumber: action.equipmentNumber,
      };
    case 'SET_EDIT_EQUIPMENT_CONTAINER_VENDOR':
      return {
        ...state,
        containerVendorId: action.containerVendor,
      };
    case 'SET_EDIT_HOME_LOCATION':
      return {
        ...state,
        homeLocation: action.homeLocation,
      };
    default:
      return state;
  }
};

export const equipmentTrailers = combineReducers({
  equipmentStatus,
  equipmentLocation,
  equipmentHistory,
  addTrailer,
  editContainer,
});
