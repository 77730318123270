import { useEffect, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

export const useDrawingManager = ({ initialValue = null, drawingEnabled, strokeColor, fillColor }) => {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');
  const [drawingManager, setDrawingManager] = useState(initialValue);

  useEffect(() => {
    if (!map || !drawing || !drawingEnabled) {
      return;
    }

    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      polygonOptions: {
        strokeColor,
        fillColor,
      }
    });
    setDrawingManager(newDrawingManager);

    return () => {
      newDrawingManager.setMap(null);
    }
  }, [map, drawing, fillColor, strokeColor, drawingEnabled]);

  return drawingManager;
}

export const drawingReducer = (state, action) => {
  switch (action.type) {
    case "ADD": {
      const { overlay } = action.payload;
      const snapshot = {};
      snapshot.path = overlay.getPath()?.getArray();

      return [{ snapshot, geometry: overlay }];
    }
    case "UPDATE": {
      const paths = action.payload;
      return [...paths];
    }
    case "CLEAR": {
      return [];
    }
    default:
      return state;
  }
}

export const useDrawingManagerEvents = (drawingManager, dispatch, confirmSelection, drawingEnabled) => {
  useEffect(() => {
    if (!drawingManager) {
      return;
    }

    if (!drawingEnabled) {
      dispatch({ type: "CLEAR" });
    }

    const overlayCompleteListener = google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (drawResult) => {
        dispatch({ type: "ADD", payload: drawResult });
        confirmSelection(drawResult);
      }
    );
    const eventListeners = [overlayCompleteListener];

    return () => {
      eventListeners.forEach(listener =>
        google.maps.event.removeListener(listener));
    }
  }, [drawingManager, dispatch, drawingEnabled, confirmSelection]);
};

export const useOverlaySnapshots = (map, state) => {
  useEffect(() => {
    if (!map || !state) {
      return;
    }

    state.forEach(overlay => {
      const { path } = overlay.snapshot;
      overlay.geometry.setPath(path ?? []);
      overlay.geometry.setMap(map);
    });

    return () => {
      state.forEach(overlay => {
        overlay.geometry.setMap(null);
      });
    };
  }, [map, state]);
};
