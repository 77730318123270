import { authorize } from 'components/common/Authorize';

export const findPaymentItemType = data => {
  switch (data.type) {
    case 1:
      if (data.rangeType === 2 && data.billableValueField === 1) {
        return 'Per mileage range';
      }
      if (data.rangeType === 2 && data.billableValueField === 3) {
        return 'Per mileage range/ per ton';
      }
      if (data.rangeType === 4 && data.billableValueField === 1) {
        return 'Per Volume';
      }
      if (data.rangeType === 5 && data.billableValueField === 1) {
        return 'Per Hour';
      }
      return null;
    case 2:
      if (data.billableValueField === 4) {
        return 'Loading Demurrage';
      }
      return 'Unloading Demurrage';
    case 3:
      return 'Leasing';
    case 4:
      if (data.billableValueField === 1) {
        return 'Per mileage range fuel surcharge';
      }
      if (data.billableValueField === 0) {
        return 'Flat percentage fuel surcharge';
      }
      return 'Per milage range/ per ton fuel surcharge';
    default:
      return null;
  }
};

export const filterPaymentTemplates = templates => {
  const filteredTemplates = templates;
  const templateKeys = Object.keys(templates);
  for (let i = 0; i < templateKeys.length; i++) {
    const template = templateKeys[i];
    if (
      Number(template) === 12 ||
      Number(template) === 13 ||
      Number(template) === 14
    ) {
      if (
        !authorize({
          abilityPermissions: [
            {
              resource: 'Managerial-fees',
              permissions: ['create'],
            },
          ],
        })
      ) {
        delete filteredTemplates[template];
      }
    }
  }
  return filteredTemplates;
};

export const filterBreakdown = breakdowns => {
  const filteredBreakdown = breakdowns.filter(
    breakdown =>
      breakdown.type !== 6 ||
      authorize({
        abilityPermissions: [
          {
            resource: 'Managerial-fees',
            permissions: ['read'],
          },
        ],
      }),
  );
  return filteredBreakdown;
};
