import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import { getDemurrageReasons, saveDemurrageReason } from './queries';

export const useDemurrageReasons = () =>
  useQuery({
    queryKey: ['demurrage-reasons'],
    queryFn: getDemurrageReasons,
    placeholderData: [],
  });

export const useSaveDemmurageReason = ({ orderId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reason => saveDemurrageReason({ orderId, reason }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['job-plans'] });
      swal('Success!', 'Successfully Saved Reason', 'success');
    },
    onError: error =>
      swal('Unable to Save Reason', error.response?.data?.message, 'error'),
  });
};
