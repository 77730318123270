import React, { useState } from 'react';
import { Marker, useMap } from '@vis.gl/react-google-maps';

import AutomatizeInfoWindow from './AutomatizeInfoWindow';

import sandSiteMarker from '../../../../public/img/icons/mapMarker-sandsite.svg';
import wellSiteMarker from '../../../../public/img/icons/mapMarker-wellsite.svg';
import stagingSiteMarker from '../../../../public/img/icons/mapMarker-staging-site.svg';
import acidFacilityMarker from '../../../../public/img/icons/mapMarker-acidFacility.svg';
import loadedDriverMarker from '../../../../public/img/icons/mapMarker-driverLoaded.svg';
import emptyDriverMarker from '../../../../public/img/icons/mapMarker-driverEmpty.svg';
import disposalMarker from '../../../../public/img/icons/mapMarker-disposalSite.svg';
import productionMarker from '../../../../public/img/icons/mapMarker-productionSite.svg';

import driverEmptyMarkerWarning from '../../../../public/img/icons/mapMarker-driverEmpty-warning.svg';
import driverLoadedMarkerWarning from '../../../../public/img/icons/mapMarker-driverLoaded-warning.svg';
import wellSiteMarkerWarning from '../../../../public/img/icons/mapMarker-wellsite-warning.svg';
import disposalMarkerWarning from '../../../../public/img/icons/mapMarker-disposalSite-warning.svg';
import productionMarkerWarning from '../../../../public/img/icons/mapMarker-productionSite-warning.svg';
import sandSiteMarkerWarning from '../../../../public/img/icons/mapMarker-sandsite-warning.svg';

import locationGreen from '../../../../public/img/icons/locationGreen.png';
import locationRed from '../../../../public/img/icons/locationRed.png';
import start from '../../../../public/img/icons/start.svg';

const icons = {
  sand_site: sandSiteMarker,
  well_site: wellSiteMarker,
  water_disposal: disposalMarker,
  water_production: productionMarker,
  acid_facility: acidFacilityMarker,
  to_origin: locationRed,
  to_destination: locationGreen,
  start,
  staging_site: stagingSiteMarker,
};

const alertIcons = {
  well_site: wellSiteMarkerWarning,
  sand_site: sandSiteMarkerWarning,
  water_disposal: disposalMarkerWarning,
  water_production: productionMarkerWarning,
};

const getIcon = ({ hasAlert, type, status }) => {
  const driverLoaded = type === 'driver' && status === 'loaded';
  const driverEmpty = type === 'driver' && status !== 'loaded';

  if (hasAlert) {
    if (driverLoaded) {
      return driverLoadedMarkerWarning;
    }

    if (driverEmpty) {
      return driverEmptyMarkerWarning;
    }

    return alertIcons[type];
  }

  if (driverLoaded) {
    return loadedDriverMarker;
  }

  if (driverEmpty) {
    return emptyDriverMarker;
  }

  return icons[type];
};

const AutomatizeMarker = ({
  position,
  children,
  onClick = null,
  infoWindow = null,
  hasAlert = false,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const map = useMap();
  const scaledWidth = props.iconWidth || 40;
  const scaledHeight = props.iconHeight || 40;

  const onMarkerClick = (e) => {
    setVisible(!visible);

    if (!visible) {
      map.panTo(e.latLng);
    }

    if (onClick) {
      onClick(props, marker, e);
    }
  };

  return (
    <>
      <Marker
        icon={{
          url: getIcon({ hasAlert, status: props.status, type: props.type }),
          // Nota Bene: Implicitly imported via Google Maps component
          // eslint-disable-next-line no-undef
          scaledSize: new google.maps.Size(scaledHeight, scaledWidth),
          // eslint-disable-next-line no-undef
          anchor: new google.maps.Point(scaledHeight / 2, scaledWidth / 2),
        }}
        onClick={onMarkerClick}
        map={map}
        position={position}
      />
      {infoWindow && visible && (
        <AutomatizeInfoWindow
          map={map}
          position={position}
          width={infoWindow.width || 220}
          title={infoWindow.title}
          onHide={() => setVisible(false)}
          {...props}>
          {infoWindow.content}
        </AutomatizeInfoWindow>
      )}
    </>
  );
};

export default AutomatizeMarker;
