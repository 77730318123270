import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  addDistrictCarrierRatio,
  addManyCarrierDistricts,
  createCarrier,
  deleteCarrier,
  deleteCarrierDistrict,
  getCarrierDistricts,
  getCarrierDetails,
  getCarrierJobs,
  getCarrierTruckAnalytics,
  getCarriers,
  restoreCarrier,
  sendCarrierNotification,
  syncBc,
  toggleCarrierRemit,
  updateCarrier,
  updateCarrierBillingAddress,
  updateCarrierFinancials,
  updateCarrierLogo,
  updateCarrierRemit,
  updateCarrierSettings,
} from './queries';

/**
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} options
 */
export const useCarriers = (options) =>
  useQuery({
    ...options,
    queryKey: ['carriers'],
    queryFn: getCarriers,
    placeholderData: []
  });

export const useCarrierDetails = carrierId =>
  useQuery({
    queryKey: ['carriers', Number(carrierId)],
    queryFn: () => getCarrierDetails(carrierId),
    enabled: !!carrierId,
  });

export const useSuspenseCarrierDetails = carrierId =>
  useSuspenseQuery({
    queryKey: ['carriers', Number(carrierId)],
    queryFn: () => getCarrierDetails(carrierId),
    enabled: !!carrierId,
  });

export const useCreateCarrier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers'],
    mutationFn: createCarrier,
    onSuccess: () => {
      swal('Successfully Created Carrier', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers'] });
    },
    onError: error => swal('Unable to Create Carrier', error?.response?.data?.message, 'error'),
  });
};

export const useUpdateCarrier = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: info => updateCarrier({ carrierId, info }),
    onSuccess: () => {
      swal('Successfully Edited Carrier', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
      // Invalidating all for carrier list info.
      queryClient.invalidateQueries({ queryKey: ['carriers'] });
    },
    onError: error => swal('Unable to Edit Carrier', error.message, 'error'),
  });
};

export const useUpdateCarrierFinancials = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: info => updateCarrierFinancials({ carrierId, info }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Financials', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
      // Invalidating all for carrier list badges.
      queryClient.invalidateQueries({ queryKey: ['carriers'] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Financials', error.message, 'error'),
  });
};

export const useUpdateCarrierBillingAddress = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: address => updateCarrierBillingAddress({ carrierId, address }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Billing Address', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Billing Address', error.message, 'error'),
  });
};

export const useUpdateCarrierSettings = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: settings => updateCarrierSettings({ carrierId, settings }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Settings', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Settings', error.message, 'error'),
  });
};

export const useToggleCarrierRemit = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: remit => toggleCarrierRemit({ carrierId, remit }),
    onSuccess: () => {
      swal('Successfully Toggled Carrier Remit', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Toggled Carrier Remit', error.message, 'error'),
  });
};

export const useUpdateCarrierRemit = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: remitInfo => updateCarrierRemit({ carrierId, remitInfo }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Remit Info', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Remit Info', error.message, 'error'),
  });
};

export const useUpdateCarrierLogo = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: logo => updateCarrierLogo({ carrierId, logo }),
    onSuccess: () => {
      swal('Successfully Edited Carrier Logo', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Edit Carrier Logo', error.message, 'error'),
  });
};

export const useDeleteCarrier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers'],
    mutationFn: carrierId => deleteCarrier(carrierId),
    onSuccess: () => {
      swal('Successfully Deleted Carrier', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers'] });
    },
    onError: error => swal('Unable to Delete Carrier', error.message, 'error'),
  });
};

export const useRestoreCarrier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers'],
    mutationFn: carrierId => restoreCarrier(carrierId),
    onSuccess: () => {
      swal('Successfully Restored Carrier', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers'] });
    },
    onError: error => swal('Unable to Restore Carrier', error.message, 'error'),
  });
};

export const useCarrierDistricts = ({ carrierId }) =>
  useQuery({
    queryKey: ['carriers', Number(carrierId), 'districts'],
    queryFn: () => getCarrierDistricts({ carrierId }),
    placeholderData: [],
    enabled: !!carrierId
  });

export const useAddCarrierDistricts = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: districts => addManyCarrierDistricts({ carrierId, districts }),
    onSuccess: () => {
      swal('Successfully Updated Carrier Districts', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
  });
};

export const useRemoveCarrierDistrict = ({ carrierId, districtId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCarrierDistrict,
    onSuccess: () => {
      swal('Successfully Deleted Carrier District', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
      queryClient.invalidateQueries({ queryKey: ['districts', Number(districtId), 'details'] });
    },
    onError: error =>
      swal('Unable to Delete Carrier District', error.message, 'error'),
  });
};

export const useCarrierSyncBc = () =>
  useMutation({
    mutationKey: ['carriers'],
    mutationFn: syncBc,
    onSuccess: () => {
      swal(
        'Carrier BC Sync Successfully Initiated. This may take a few moments.',
        '',
        'success',
      );
    },
    onError: error => swal('Unable to Sync Carrier', error.message, 'error'),
  });

export const useSendCarrierNotification = () =>
  useMutation({
    mutationKey: ['carriers'],
    mutationFn: sendCarrierNotification,
    onSuccess: () => swal('Successfully Sent Notification', '', 'success'),
    onError: error =>
      swal('Unable to Send Notification', error.message, 'error'),
  });

export const useCarrierTruckAnalytics = carrierId =>
  useQuery({
    queryKey: ['carriers', 'truck-analytics', carrierId],
    queryFn: () => getCarrierTruckAnalytics(carrierId),
    enabled: !!carrierId,
  });

export const useAddDistrictCarrierRatio = carrierId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['carriers', Number(carrierId)],
    mutationFn: carrierRatios =>
      addDistrictCarrierRatio({ carrierId, carrierRatios }),
    enabled: !!carrierId,
    onSuccess: () => {
      swal('Successfully Updated District Carrier Ratios', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['carriers', Number(carrierId)] });
    },
    onError: error =>
      swal('Unable to Update District Carrier Ratios', error.message, 'error'),
  });
};

export const useCarrierJobs = carrierId =>
  useQuery({
    queryKey: ['carriers-jobs', carrierId],
    queryFn: () => getCarrierJobs(carrierId),
    enabled: !!carrierId,
  });

export const useSuspenseCarrierJobs = carrierId =>
  useSuspenseQuery({
    queryKey: ['carriers-jobs', carrierId],
    queryFn: () => getCarrierJobs(carrierId),
    enabled: !!carrierId,
  });
