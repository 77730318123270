import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-bootstrap';
import { apiSlice } from 'features/api/apiSlice';

import { loginActions } from 'store/actions/Login';
import { prefetchDrivers } from 'api/v2/drivers';
import LookUp from 'components/views/LookUp';
import Navigation from '../common/Navigation/index';
import Header from '../common/Header/index';
import { correctHeight, detectBody } from './Helpers';
import AppLogout from './AppLogout';
import Events from '../common/Events/component';
import eventsActions from '../common/Events/actions';

import { links } from '../common/Navigation/components/links';
import { routes } from '../common/Navigation/components/routes';

const Main = ({
  count,
  countAPI,
  user,
  changeEventsBarStatus,
  logout,
  sessionLifetime,
}) => {
  const match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const onLoadResize = () => {
      correctHeight();
      detectBody();
    };
    window.addEventListener('load', onLoadResize);
    window.addEventListener('resize', onLoadResize);
  }, []);

  const pageClass = `${location.pathname.split('/')[2]}-page`;
  const wrapperClass = `dark-background ${location.pathname}`;

  prefetchDrivers();

  if (count >= countAPI && user) {
    return (
      <div id="wrapper" className={`wrapper ${pageClass}`}>
        <NotificationsSystem theme={theme} />
        <AppLogout sessionLifetime={sessionLifetime}>
          <Navigation />
          <div id="page-wrapper" className={`page-wrapper ${wrapperClass}`}>
            <Header
              changeEventsBarStatus={changeEventsBarStatus}
              logout={logout}
            />
            <div className="content-area__wrapper">
              <Switch>
                {links.map(
                  (item, index) =>
                    item.component && (
                      <Route
                        path={`${match.url}${item.path}`}
                        key={`links${index}`}>
                        <item.component />
                      </Route>
                    ),
                )}
                {routes().map((item, index) => {
                  const RouteArray = item.children.map(
                    (secondItem, secondIndex) => {
                      const RouteArrayThird = secondItem.children.map(
                        (thirdItem, thirdIndex) =>
                          thirdItem.component && (
                            <Route
                              path={`${match.url}${item.path}${secondItem.path
                                }${thirdItem.path}`}
                              key={`${index}_${secondIndex}_${thirdIndex}`}>
                              <thirdItem.component />
                            </Route>
                          ),
                      );
                      if (secondItem.component) {
                        RouteArrayThird.push(
                          <Route
                            path={`${match.url}${item.path}${secondItem.path}`}
                            key={`${index}_${secondIndex}`}>
                            <secondItem.component />
                          </Route>,
                        );
                      }
                      return RouteArrayThird;
                    },
                  );
                  if (item.component) {
                    RouteArray.push(
                      <Route path={`${match.url}${item.path}`} key={index}>
                        <item.component />
                      </Route>,
                    );
                  }
                  return RouteArray;
                })}
              </Switch>
            </div>
          </div>
          <Events />
        </AppLogout>
      </div>
    );
  }
  return <LookUp />;
};

const mapStateToProps = state => ({
  count: state.lookUp.count,
  sessionLifetime: state.lookUp.sessionLifetime,
  countAPI: state.lookUp.countAPI,
  user: state.lookUp.user,
});

const mapDispatchToProps = dispatch => ({
  changeEventsBarStatus: () => dispatch(eventsActions.changeEventsBarStatus()),
  logout: () => dispatch(loginActions.logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
