import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Header from 'components/common/Header';
import Icon from 'components/shared/Icon';

import actions from 'store/actions/Equipments';

import AddTrailer from './AddTrailer';
import BulkUpload from './BulkUpload';

const EquipmentsHeader = ({ customers }) => {
  const [showAddTrailer, turnModal] = useState(false);
  const [showBulkUpload, turnBulkUpload] = useState(false);
  const { pathname } = useLocation();
  const isContainers = pathname.split('/')[3] === 'containers';

  return (
    <Header title={isContainers ? 'Container Details' : 'Trailer Details'}>
      {isContainers && (
        <Button inverse className="float-end">
          <Icon
            icon="upload"
            onClick={() => turnBulkUpload(true)}
          />
        </Button>
      )}
      {isContainers ? (
        <Authorize
          abilityPermissions={[
            { resource: 'Containers', permissions: ['create'] },
          ]}>
          <Button
            onClick={() => turnModal(true)}
            inverse
            testSelector="equipments_header_add-container_btn"
            className="float-end">
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Container
          </Button>
        </Authorize>
      ) : (
        <Authorize
          abilityPermissions={[
            { resource: 'Trailers', permissions: ['create'] },
          ]}>
          <Button
            onClick={() => turnModal(true)}
            inverse
            testSelector="equipments_header_add-trailer_btn"
            className="float-end">
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Trailer
          </Button>
        </Authorize>
      )}

      {showAddTrailer && (
        <AddTrailer
          customers={customers}
          isTrailer={!isContainers}
          showAddTrailer={showAddTrailer}
          closeAddTrailer={() => turnModal(false)}
        />
      )}
      {showBulkUpload && (
        <BulkUpload
          customers={customers}
          showModal={showBulkUpload}
          closeModal={() => turnBulkUpload(false)}
        />
      )}
    </Header>
  );
};

const mapStateToProps = state => ({
  customerId: state.equipmentTrailers.equipmentStatus.customerId,
});

const mapDispatchToProps = dispatch => ({
  setCustomerId: id => dispatch(actions.setCustomerId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EquipmentsHeader);
