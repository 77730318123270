import { useEffect, useMemo, useReducer } from 'react';

const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);

const initAddDriverFormState = {
  name: '',
  email: '',
  carrierId: '',
  carrierName: '',
  vehicleNumber: '',
  district: '',
  homeDistrict: `${defaultOnBoardingDistrict} | On-boarding`,
  trailerNumber: '',
  contactNo: '',
  certificatePhotos: '',
  equipment: '',
};

const AddDriverFormReducer = (state, action) => {
  switch (action.type) {
    case 'set_value':
      return { ...state, [action.name]: action.payload };
    case 'reset':
      return initAddDriverFormState;
    default:
      return state;
  }
}

export const useAddDriverForm = () => {
  const [state, dispatch] = useReducer(AddDriverFormReducer, initAddDriverFormState);

  const handlers = useMemo(() => ({
    setValue: (name, payload) => dispatch({ type: 'set_value', name, payload }),
    reset: () => dispatch({ type: 'reset' }),
  }), [dispatch]);

  useEffect(() => () => handlers.reset(), [handlers]);

  return [state, handlers]
}
