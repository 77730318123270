import axios from 'axios';
import { apiSlice } from 'features/api/apiSlice';
import store from 'store/store';

export const driver = {
  get() {
    return axios.get('v2/lookup/drivers');
  },
  async changeDistricts(districtIds, driverId) {
    const result = await axios.post(`v2/driver/${driverId}/districts`, {
      districtIds,
    });
    store.dispatch(
      apiSlice.util.invalidateTags([{ type: 'Driver', id: driverId }]),
    );
    return result;
  },
  sendMessage(data) {
    return axios.post(`v2/drivers/${data.driversId}/send-push`, data);
  },
  pushNotification(data) {
    return axios.post('v2/sendPushToDriver', data);
  },
  getDriverProfile(id) {
    return axios.post('v2/getDriver', { id });
  },
  async editDriver(params) {
    const result = await axios.post('v1/editDriver', params);
    store.dispatch(apiSlice.util.invalidateTags(['Driver']));
    return result;
  },
};
