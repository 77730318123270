const { client } = require('api/client');

/**
 * @returns {Promise<Array>}
 */
export const getContainers = async (driverId, districtIds) => {
  const { data } = await client.get(
    `v2/driver/containers/${driverId}`,
    {
      params: { districtIds  }
    }
  );
  return data.data.containers;
};
