import React from 'react';
import { Modal } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';

import { useUpdateStorageConfig } from 'api/v2/jobs/storage';
import OnsiteStorageType from './OnsiteStorageType';
import { useAddUOMStorageSectionForm } from './hooks';

/**
 *
 * @param {{
 *  jobId: number;
 *  closeModal: () => {};
 *  storageConfig: any[];
 *  showModal: boolean;
 *  storageGroups: import('..').StorageGroup[]
 *  customerId: number;
 * }} props
 */
const OnsiteStorage = ({
  jobId,
  closeModal,
  storageConfig,
  showModal,
  storageGroups,
}) => {
  const {
    data: formData,
    storageTypeOptions,
    commodityOptions,
    handlers: { setArrayValue, addArrayValue, deleteArrayValue },
    isValid,
  } = useAddUOMStorageSectionForm({
    showModal,
    defaultValue: storageConfig,
    jobId,
  });
  const { mutate: addNewUOMStorageUpdate, isPending } = useUpdateStorageConfig(
    jobId,
  );

  const submitUpdateAddNewUOMStorageUpdateForm = e => {
    e.preventDefault();

    const data = cloneDeep(formData.storageConfig).map(item => ({
      id: item.id,
      name: item.name,
      commodityId: item.commodity?.id,
      type: Number(item.storageType.id),
      isLive: Boolean(item.storageType.isLive),
      storageId: item.storageId,
      capacity: Number(item.capacity),
      currentVolume: !item.storageType.isLive
        ? storageGroups.find(i => i.storageId === item.id)?.units?.[0]
          ?.currentWeight
        : 0,
    }));

    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === null || item[key] === undefined) {
          delete item[key];
        }
      });
    });

    addNewUOMStorageUpdate(data, { onSuccess: closeModal });
  };

  if (isPending) {
    return <Loader />;
  }

  const renderOnsiteStorage = (item, index) => (
    <OnsiteStorageType
      setArrayValue={setArrayValue}
      deleteArrayValue={deleteArrayValue}
      commodityOptions={commodityOptions}
      storageTypeOptions={storageTypeOptions}
      item={item}
      index={index}
    />
  );

  return (
    <Modal show={showModal}>
      <form onSubmit={submitUpdateAddNewUOMStorageUpdateForm}>
        <Modal.Body>
          <div style={{ padding: '20px 10px' }}>
            <div className="step3">
              <h4 className="title">Storage Details</h4>
              <fieldset id="form-p-2" role="tabpanel" className="body">
                {formData.storageConfig.map(renderOnsiteStorage)}
              </fieldset>
              <Button theme="small" onClick={addArrayValue}>
                <Icon icon="plus" /> Add Storage
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} colour="white">
            Close
          </Button>
          <Button
            disabled={!isValid}
            type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OnsiteStorage;
