import React, { useState } from 'react';
import { Tabs, Tab, Col } from 'react-bootstrap';
import Label from 'components/shared/Label';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import ToolTip from 'components/shared/ToolTip';
import Authorize, { authorize } from 'components/common/Authorize';

import { calculateTotal, currency } from 'utils/calculateTotal';
import { availableTo } from 'utils/calculateTime';
import { locationPermission } from 'components/globalInfo/locationPermission';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';

import { useEquipment } from 'api/v2/equipment';
import { useDownloadDriverRejectionReport } from 'api/v2/drivers';
import AcceptedOrders from './AcceptedOrders';
import RejectedOrders from './RejectedOrders';
import Logs from './Logs';
import Comments from './Comments';
// import DriverChat from './Chat';
import EditDriverRate from './ChangeRating';
import ChangeTruck from './EditTruck';
import Districts from './Districts/index';

const user = authService.getUser();

const MoreInformation = ({
  ratings,
  driverDistricts,
  driverInf,
  orders,
  rejectedOrders,
  logs,
}) => {
  // const [tabKey, changeTabKey] = useState(1);
  const [showRating, changeRating] = useState(false);
  const [showEditTruck, turnEditTruck] = useState(false);
  const { data: equipmentRequired } = useEquipment();
  const { mutate: downloadDriverRejections } = useDownloadDriverRejectionReport();

  const {
    name,
    status,
    location_permission: driverLocationPermission,
    rating,
    deviceVersion,
    carrier_id: carrierId,
    carrier_name: carrierName,
    truck_no: truckNo,
    trailer_no: trailerNo,
    equipment,
    accepted_jobs_count: acceptedJobsCount,
    rejected_jobs_count: rejectedJobsCount,
    timedout_jobs_count: timedoutJobsCount,
    available_to: driverAvailableTo,
    is_active: isActive,
    on_hold: onHold,
  } = driverInf;

  return (
    <Col lg={8} md={8} sm={8} style={{ paddingRight: 0 }}>
      <div className="animated fadeInRight">
        <div className="ibox">
          <div className="ibox-content">
            <div className="row">
              <h3>More information about {name}</h3>
              <div className="row">
                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Status: </strong>
                    <ToolTip
                      title={
                        locationPermission[driverLocationPermission].title
                      }>
                      <Label
                        style={{ marginBottom: '3px' }}
                        type={
                          locationPermission[driverLocationPermission].label
                        }
                        title={
                          locationPermission[driverLocationPermission].title
                        }>
                        {locationPermission[driverLocationPermission].text}
                      </Label>
                    </ToolTip>
                    {status ? (
                      <Label style={{ marginBottom: '3px' }} type="danger">
                        Busy
                      </Label>
                    ) : (
                      <Label style={{ marginBottom: '3px' }} type="success">
                        Free
                      </Label>
                    )}
                    {driverAvailableTo && (
                      <Label
                        title="Available for"
                        style={{ marginBottom: '3px' }}
                        type={availableTo(driverAvailableTo).label}>
                        {availableTo(driverAvailableTo).hours}
                      </Label>
                    )}
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Carrier #{carrierId}: </strong>
                    <span>{carrierName}</span>
                    {!isActive && user.role === ROLES.JOB_MANAGER && (
                      <Label style={{ marginBottom: '3px' }} type="danger">
                        InActive
                      </Label>
                    )}
                    {!!onHold && user.role === ROLES.JOB_MANAGER && (
                      <Label style={{ marginBottom: '3px' }} type="danger">
                        On Hold
                      </Label>
                    )}
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Truck: </strong>
                    <span>{truckNo}</span>
                    <Authorize
                      {...{
                        forbidden: ['CARRIER'],
                        abilityPermissions: [
                          {
                            resource: 'Drivers',
                            permissions: ['update'],
                          },
                        ],
                      }}>
                      <Button
                        theme="small"
                        colour="white"
                        testSelector="driver-details_more-info_edit-truck_btn"
                        onClick={() => {
                          turnEditTruck(true);
                        }}>
                        <Icon icon="pencil" />
                      </Button>
                    </Authorize>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Trailer: </strong>
                    <span>{trailerNo}</span>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Equipment: </strong>
                    <span>
                      {equipmentRequired.map(
                        item => item.id === equipment && item.title,
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-6" style={{ paddingRight: 0 }}>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Rating: </strong>
                    <span>
                      {rating} <Icon icon="star" />
                    </span>
                    <Authorize
                      {...{
                        forbidden: ['CARRIER'],
                        abilityPermissions: [
                          {
                            resource: 'Drivers',
                            permissions: ['update'],
                          },
                        ],
                      }}>
                      <Button
                        theme="small"
                        colour="white"
                        testSelector="driver-details_more-info_edit-rating_btn"
                        onClick={() => {
                          changeRating(true);
                        }}>
                        <Icon icon="pencil" />
                      </Button>
                    </Authorize>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Device version: </strong>
                    <span>{deviceVersion}</span>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Accepted orders: </strong>
                    <span>{acceptedJobsCount}</span>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Rejected orders: </strong>
                    <span>{rejectedJobsCount}</span>
                    <Button
                      theme="small"
                      colour="white"
                      testSelector="driver-details_downloadRejections_btn"
                      onClick={() => {
                        downloadDriverRejections({ driverId: Number(driverInf.id) });
                      }}>
                      <Icon icon="download" />
                    </Button>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Timed-out orders: </strong>
                    <span>{timedoutJobsCount}</span>
                  </div>
                  <div style={{ padding: '0.5rem 0' }}>
                    <strong>Total earned: </strong>
                    <span>{currency.format(calculateTotal(orders))}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="panel blank-panel tab">
                <Tabs>
                  {authorize({
                    abilityPermissions: [
                      {
                        resource: 'Orders',
                        permissions: ['read'],
                      },
                    ],
                  }) && (
                      <Tab eventKey="accepted-orders" title="Accepted Orders">
                        <AcceptedOrders orders={orders} />
                      </Tab>
                    )}
                  {authorize({
                    abilityPermissions: [
                      {
                        resource: 'Orders',
                        permissions: ['read'],
                      },
                    ],
                  }) && (
                      <Tab eventKey="rejected-orders" title="Rejected Orders">
                        <RejectedOrders orders={rejectedOrders} />
                      </Tab>
                    )}
                  <Tab eventKey="comments" title="Comments">
                    <Comments
                      jobManagers={ratings.jobManagerMarks}
                      sandCoordinators={ratings.sandCoMarks}
                    />
                  </Tab>
                  <Tab eventKey="logs" title="Logs">
                    <Logs logs={logs} />
                  </Tab>
                  {/* {authorize({
                    abilityPermissions: [
                      {
                        resource: 'Chat',
                        permissions: ['read'],
                      },
                    ],
                  }) && (
                    <Tab eventKey={4} title="Chat">
                      {tabKey == 4 && (
                        <DriverChat chatId={chat_id} driverId={id} />
                      )}
                    </Tab>
                  )} */}
                  {authorize({
                    abilityPermissions: [
                      {
                        resource: 'Districts',
                        permissions: ['read'],
                      },
                    ],
                  }) && (
                      <Tab eventKey="districts" title="Districts">
                        <Districts
                          driverDistricts={driverDistricts}
                          carrierId={Number(driverInf.carrier_id)}
                          driverId={Number(driverInf.id)} />
                      </Tab>
                    )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRating && (
        <EditDriverRate
          driverInf={driverInf}
          showModalDriverRate={showRating}
          closeRate={() => changeRating(false)}
        />
      )}
      {showEditTruck && (
        <ChangeTruck
          driverInf={driverInf}
          showEditTruck
          driverDistricts={driverDistricts}
          closeEditTruck={() => turnEditTruck(false)}
          requiredEquipment={equipmentRequired}
        />
      )}
    </Col>
  );
};

export default MoreInformation;
