import React from 'react';
import Add from './Add';
import Android from './Android';
import Bars from './Bars';
import BusyDriver from './BusyDriver';
import CSV from './CSV';
import Calendar from './Calendar';
import CheckMark from './CheckMark';
import Clock from './Clock';
import Close from './Close';
import District from './District';
import DoubleLeft from './DoubleLeft';
import DoubleRight from './DoubleRight';
import Down from './Down';
import Download from './Download';
import Drag from './Drag';
import Driver from './Driver';
import Edit from './Edit';
import Email from './Email';
import Excel from './Excel';
import Export from './Export';
import False from './False';
import Filter from './Filter';
import FreeDriver from './FreeDriver';
import Help from './Help';
import IOS from './IOS';
import Left from './Left';
import Link from './Link';
import LocationAlways from './LocationAlways';
import LocationPending from './LocationPending';
import LocationRejected from './LocationRejected';
import LocationTracking from './LocationTracking';
import LoggedOut from './LoggedOut';
import Manage from './Manage';
import Minus from './Minus';
import Mobile from './Mobile';
import More from './More';
import NotVerified from './NotVerified';
import NoPreview from './NoPreview';
import PDF from './PDF';
import Plus from './Plus';
import Preview from './Preview';
import Question from './Question';
import Right from './Right';
import Rotate from './Rotate';
import Sand from './Sand';
import Sandsite from './Sandsite';
import SortArrow from './SortArrow';
import TrucksBehind from './TrucksBehind';
import TrucksVsOrders from './TrucksVsOrders';
import True from './True';
import User from './User';
import Verified from './Verified';
import Warning from './Warning';
import WarningOrange from './WarningOrange';
import WarningYellow from './WarningYellow';
import Water from './Water';
import WaterDisposal from './WaterDisposal';
import WaterProduction from './WaterProduction';
import Waterdrop from './Waterdrop';
import Wellsite from './Wellsite';
import ZoomIn from './ZoomIn';
import ZoomOut from './ZoomOut';
import AcidFacility from './AcidFacility';
import Truck from './Truck';
import Job from './Job';
import Polygon from './Polygon';
import LocationPin from './LocationPin';

export default {
  add: <Add />,
  android: <Android />,
  bars: <Bars />,
  busy_driver: <BusyDriver />,
  calendar: <Calendar />,
  check_mark: <CheckMark />,
  clock: <Clock />,
  close: <Close />,
  csv: <CSV />,
  district: <District />,
  double_left: <DoubleLeft />,
  double_right: <DoubleRight />,
  down: <Down />,
  download: <Download />,
  drag: <Drag />,
  driver: <Driver />,
  edit: <Edit />,
  email: <Email />,
  excel: <Excel />,
  export: <Export />,
  false: <False />,
  filter: <Filter />,
  free_driver: <FreeDriver />,
  help: <Help />,
  ios: <IOS />,
  job: <Job />,
  left: <Left />,
  link: <Link />,
  location_always: <LocationAlways />,
  location_pending: <LocationPending />,
  location_rejected: <LocationRejected />,
  location_tracking: <LocationTracking />,
  logged_out: <LoggedOut />,
  manage: <Manage />,
  minus: <Minus />,
  mobile: <Mobile />,
  more: <More />,
  nopreview: <NoPreview />,
  not_verified: <NotVerified />,
  pdf: <PDF />,
  plus: <Plus />,
  preview: <Preview />,
  question: <Question />,
  right: <Right />,
  rotate: <Rotate />,
  sand: <Sand />,
  sandsite: <Sandsite />,
  sand_site: <Sandsite />,
  sort_arrow: <SortArrow />,
  truck: <Truck />,
  trucks_behind: <TrucksBehind />,
  trucks_vs_orders: <TrucksVsOrders />,
  true: <True />,
  user: <User />,
  verified: <Verified />,
  warning: <Warning />,
  warning_orange: <WarningOrange />,
  warning_yellow: <WarningYellow />,
  water: <Water />,
  water_disposal: <WaterDisposal />,
  water_production: <WaterProduction />,
  waterdrop: <Waterdrop />,
  wellsite: <Wellsite />,
  well_site: <Wellsite />,
  zoom_in: <ZoomIn />,
  zoom_out: <ZoomOut />,
  acid_facility: <AcidFacility />,
  polygon: <Polygon />,
  location_pin: <LocationPin />
};
