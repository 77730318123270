import React from 'react';
import Masonry from 'react-masonry-css';
import Pagination from 'components/shared/Pagination';
import Loader from 'components/shared/Loader';
import Driver from 'components/views/DriversList/Driver';
import DriverFilters from 'components/views/DriversList/DriverFilters';

const DriverListBlock = ({
  drivers,
  apiIsLoading,
  setFilter,
  setPagination,
  pagination,
  pageLimit,
  setPageLimit,
  totalPages,
  filters
}) => {
  const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    856: 1,
  };

  return (
    <div>
      <div className="ibox-content">
        <h3 className="text-white">Drivers</h3>
        <DriverFilters setFilter={setFilter} filters={filters} />
        <div className="wrapper-content animated fadeInRight">
          {apiIsLoading ? (
            <div data-testid="drivers-list-block-loading">
              <Loader />
            </div>
          ) : (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {drivers.map(item => (
                <Driver
                  key={item.id}
                  data={item}
                />
              ))}
            </Masonry>
          )}
        </div>
        <div>
          <Pagination
            currentPage={pagination.currentPage}
            totalPages={totalPages ?? 0}
            onPageChange={setPagination}
            withLimit
            currentLimit={pageLimit}
            onLimitChange={e => {
              setPagination({ currentPage: 1 });
              setPageLimit(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};


export default DriverListBlock;
