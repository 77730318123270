import { useQuery } from '@tanstack/react-query';
import { getContainers } from './queries';

export const useContainers = (driverId, districtIds) => {
  return useQuery({
    queryKey: ['containers', driverId, districtIds],
    queryFn: () => getContainers(driverId, districtIds),
    placeholderData: [],
    enabled: !!driverId && !!districtIds.length
  });
}