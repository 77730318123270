import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { filterOptions } from 'utils/selectUtils';
import { useEditOrderTicket, useOrderTicketTypes } from 'api/v2/orders';
import { useOrderTicket, useEditTicketForm } from './hooks';

const EditSandTicket = ({
  orderId,
  selectedTicket,

  showModalEditTicket,
  closeEditTicket,
}) => {
  const { mutate: editTicket, isPending } = useEditOrderTicket({
    orderId,
    ticketId: selectedTicket?.id,
  });
  const ticket = useOrderTicket(orderId, selectedTicket);
  const { data: ticketTypes } = useOrderTicketTypes({ orderId });

  const sandTicketTypesList = filterOptions(
    ticketTypes ?? [],
    null,
    null,
    'sandTicketTypeId',
  );

  const { data: formData, isValid, handlers } = useEditTicketForm({
    showModal: showModalEditTicket,
    defaultValue: ticket,
  });

  const handleClose = () => {
    handlers.clear();
    closeEditTicket();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const params = {
      orderId,
      sandTicketId: formData.id,
      sandTicketType: formData.ticketType,
      sandTicketNumber: formData.ticketNumber,
    };
    editTicket(params, {
      onSuccess: handleClose,
    });
  };

  return (
    <Modal
      style={{ top: `${20}%` }}
      className="modal-container"
      show={showModalEditTicket}
      onHide={closeEditTicket}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title as="h3">Edit Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Input
                onChange={handlers.valueChange('ticketNumber')}
                value={formData.ticketNumber}
                label="Ticket Number"
                required
                testSelector="order-details_sand-tickets_edit_number_input"
              />
            </Col>
            <Col lg={6} md={6} className="form-group has-feedback">
              <Select
                label="Ticket Type"
                onChange={handlers.valueChange('ticketType')}
                options={sandTicketTypesList}
                value={sandTicketTypesList.find(
                  item => item.value === formData.ticketType,
                )}
                required
                testSelector="order-details_sand-tickets_edit_type_select"
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="order-details_sand-tickets_edit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isValid || isPending}
            testSelector="order-details_sand-tickets_edit_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default EditSandTicket;
