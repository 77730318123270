import React, { useReducer, useEffect } from 'react'
import { useMap } from '@vis.gl/react-google-maps';
import Button from 'components/shared/Button';
import {
  useOverlaySnapshots,
  drawingReducer,
  useDrawingManagerEvents
} from './hooks';


const DrawingControls = ({ drawingManager, confirmSelection, drawingEnabled, paths }) => {
  const [state, dispatch] = useReducer(drawingReducer, paths ?? []);
  const map = useMap();

  useEffect(() => {
    dispatch({ type: "UPDATE", payload: paths });
  }, [paths]);

  useDrawingManagerEvents(drawingManager, dispatch, confirmSelection, drawingEnabled, paths);
  useOverlaySnapshots(map, state);

  return (
    <Button onClick={() => {
      dispatch({ type: "CLEAR" });
      confirmSelection(null);
    }}>
      Clear
    </Button>
  );
}

export default DrawingControls;
