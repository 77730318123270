import React from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

import { useSaveDemmurageReason } from 'api/v2/orders';
import Loader from 'components/shared/Loader';
import {
  useDemurrageData,
  useDemurrageOptions,
  useDemurrageReasonForm,
} from './hooks';

const EditPriceDemurrage = ({ orderId, price, closeModal, showModal }) => {
  const { mutate: saveDemurrageReason, isPending } = useSaveDemmurageReason({
    orderId,
  });

  const { demurrageData, demurrageReason, isLoading } = useDemurrageData({
    orderId: showModal ? orderId : undefined,
    priceType: price?.type,
  });
  const demurrageOptions = useDemurrageOptions();
  const { data: formData, handlers } = useDemurrageReasonForm({
    defaultValue: demurrageReason,
    showModal,
  });

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = e => {
    e.preventDefault();

    const demurrageType =
      price.type === 3
        ? 'wellsiteDemurrageReasonId'
        : 'sandsiteDemurrageReasonId';

    const params = {
      orderId,
      ...demurrageData,
      [demurrageType]: formData.reason,
    };

    return saveDemurrageReason(params, {
      onSuccess: handleClose,
    });
  };

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${20}%` }}
        className="modal-container"
        show={showModal}
        onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <h4 className="modal-title">Reason for demurrage</h4>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : (
              <Row>
                <div className="form-group has-feedback col-md-12">
                  <Select
                    placeholder="Demurrage Reason"
                    onChange={handlers.valueChange}
                    value={demurrageOptions
                      .flatMap(i => i.options)
                      .find(item => item.value === formData.reason)}
                    options={demurrageOptions}
                    testSelector="order-details_pricing_demurrage_reason_select"
                  />
                </div>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="order-details_pricing_demurrage-modal_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={isPending}
              testSelector="order-details_pricing_demurrage-modal_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditPriceDemurrage;
