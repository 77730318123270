import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { useChangeDriverRating, useDriverRating } from 'api/v2/drivers';

const EditDriverRate = ({
  showModalDriverRate,
  closeRate,
  driverInf,
}) => {
  const [{ rating, comment }, setEditData] = useState({ rating: 0, comment: '' });
  const setModalValue = (e, name) => {
    setEditData(prevState => ({ ...prevState, [name]: targetValue(e) }));
  }
  const { mutate: changeDriverRate, isPending: isChangeDriverRatePending } = useChangeDriverRating();
  const { data: rateData } = useDriverRating({ driverId: driverInf.id });
  const rateOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ];

  useEffect(() => {
    if (rateData?.comment && rateData?.rating) {
      setEditData(rateData);
    }
  }, [rateData]);

  const handleSubmit = e => {
    e.preventDefault();

    changeDriverRate(
      {
        driverId: Number(driverInf.id),
        rating: Number(rating),
        comment
      },
      {
        onSuccess: closeRate
      }
    );
  }

  const saveDisabled = useMemo(() => (
    !rating || !comment?.trim().length || isChangeDriverRatePending
  ), [rating, comment, isChangeDriverRatePending]);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${10}%` }}
        className="modal-container"
        show={showModalDriverRate}
        onHide={closeRate}>
        <form onSubmit={handleSubmit} className="m-t" role="form">
          <Modal.Header>
            <h3>Edit Rate</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Please Select Rating"
                  onChange={item => setModalValue(item.value, 'rating')}
                  value={rateOptions.find(item => item.value === rating)}
                  options={rateOptions}
                  required
                  testSelector="driver-details_change-rating_rating_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'comment')}
                  value={comment}
                  label="Comment"
                  required
                  testSelector="driver-details_change-rating_comment_input"
                />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={closeRate}
              colour="white"
              children="Close"
              testSelector="driver-details_chanage-rating_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              disabled={saveDisabled}
              testSelector="driver-details_chanage-rating_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditDriverRate;
