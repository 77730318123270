import { client } from 'api/client';

/**
 * @typedef {{
 *  isSuccessful: boolean;
 *  steps: {
 *    stepType: number;
 *    stepName: string;
 *    isSuccessful: boolean;
 *    description: string;
 *  }[]
 * }} NotarizationReport
 */

/**
 * 
 * @param {number} orderId 
 * @returns {Promise<NotarizationReport>}
 */
export const getOrderNotarizations = async (orderId) => {
  const { data } = await client.get(`v2/order/${orderId}/notarization/report`);
  return data.data.notarizationReport;
};
