import React, { useState } from 'react';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import formatString from 'utils/formatString';
import ToolTip from 'components/shared/ToolTip';
import { locationPermission } from 'components/globalInfo/locationPermission';
import { freeBusyStatus } from 'components/globalInfo/driverStatuses';
import {
  useDriverOsVersions,
  useDriverAppVersions
} from 'api/v2/drivers';

const DriverFilters = ({ filters, setFilter: setFilterValue }) => {
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [activeFilters, setActiveFilters] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const { data: osVersions } = useDriverOsVersions();
  const { data: appVersions } = useDriverAppVersions();
  const locationPermissionsList = Object.keys(locationPermission).map(item => ({
    value: item,
    label: locationPermission[item].text,
  }));
  locationPermissionsList.unshift({ value: '', label: 'All' });
  const androidList = [];
  const iosList = [];

  if (osVersions?.android?.length) {
    osVersions.android.forEach(info => {
      androidList.push({ value: info.version, label: info.version });
    });
  }

  androidList.unshift({ value: '', label: 'All' });

  if (osVersions?.iOS?.length) {
    osVersions.iOS.forEach(info => {
      iosList.push({
        value: info.version,
        label: info.version,
      });
    });
  }
  iosList.unshift({ value: '', label: 'All' });

  const driverAppVersions = [];
  if (appVersions?.length) {
    appVersions.forEach(version => {
      if (!version || version === 'unknown') return;
      driverAppVersions.push({ value: version, label: version });
    });
  }
  driverAppVersions.unshift({ value: '', label: 'All' });

  const filterMethods = {
    Name: value => setFilterValue(value, 'name'),
    Id: value => setFilterValue(value, 'id'),
    Email: value => setFilterValue(value, 'email'),
    Phone: value => setFilterValue(value, 'phone'),
    District: value => setFilterValue(value, 'district'),
    Truck: value => setFilterValue(value, 'truck'),
    Carrier: value => setFilterValue(value, 'carrier'),
    Location: value => setFilterValue(value, 'location'),
    Android: value => setFilterValue(value, 'android'),
    iOS: value => setFilterValue(value, 'ios'),
    appVersion: value => setFilterValue(value, 'appVersion'),
    Deleted: value => setFilterValue(!value, 'deletedStatus'),
    Status: value => setFilterValue(value, 'freeBusyStatus'),
  };
  const removeFilter = type => {
    if (type === 'Deleted') {
      setCheckbox(!checkbox);
      setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
      filterMethods[type](checkbox);
    } else {
      setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
      filterMethods[type]('');
    }
  };

  const removeCheckboxFilter = type => {
    setActiveFilters([...activeFilters.filter(filter => filter !== type)]);
    filterMethods[type](checkbox);
  };

  const addFilter = (type, value) => {
    setActiveFilters([
      ...activeFilters.filter(filter => filter !== type),
      type,
    ]);
    return filterMethods[type](value);
  };

  const handleCheckBoxFilters = type => {
    setCheckbox(!checkbox);
    if (checkbox) {
      addFilter(type, checkbox);
    } else {
      removeCheckboxFilter(type);
    }
  };

  const clearAllFilters = () => {
    activeFilters.forEach(type => {
      if (type === 'Deleted') {
        removeCheckboxFilter(type);
        setCheckbox(false);
      } else {
        removeFilter(type);
      }
    });
    setActiveFilters([]);
  };

  return (
    <div className="filters driver-filters">
      <div
        className={`filters__top-row ${activeFilters.length > 0 ? '' : 'driver-filters__top-row'
          }`}>
        {activeFilters.length > 0 && (
          <div className="filters__active">
            {activeFilters.map(filter => (
              <span className="label label--filter" key={filter}>
                {formatString(filter, 't')}
                <Icon
                  data-testid={`remove-driver-filter-${filter}`}
                  icon="close"
                  onClick={() => removeFilter(filter)}
                />
              </span>
            ))}
            <Button
              testSelector="drivers-list_filter_clear-all_btn"
              theme="small"
              onClick={() => clearAllFilters()}>
              Clear All
            </Button>
          </div>
        )}
        <ToolTip
          title={`${filterVisibility ? 'Hide' : 'Show'} Filters`}
          placement="left">
          <Button
            theme="small"
            className="filter-toggle button--small--square"
            onClick={() => setFilterVisibility(!filterVisibility)}
            inverse={filterVisibility}
            testSelector="drivers-list_filter_toggle_btn">
            <Icon icon="filter" />
          </Button>
        </ToolTip>
      </div>
      <div
        className={`filters__form-row ${filterVisibility ? 'filters__form-row--open' : ''
          }`}>
        <div className="filters__form">
          <div className="filters__item">
            <Input
              label="Driver Name"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Name', value);
                } else {
                  removeFilter('Name');
                }
              }}
              value={filters.name}
              testSelector="drivers_filter_name_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Driver Id"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Id', value);
                } else {
                  removeFilter('Id');
                }
              }}
              value={filters.id}
              testSelector="drivers_filter_id_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Driver Email"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Email', value);
                } else {
                  removeFilter('Email');
                }
              }}
              value={filters.email}
              type="email"
              testSelector="drivers_filter_email_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Phone Number"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Phone', value);
                } else {
                  removeFilter('Phone');
                }
              }}
              value={filters.phone}
              testSelector="drivers_filter_phone_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="District"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('District', value);
                } else {
                  removeFilter('District');
                }
              }}
              value={filters.district}
              testSelector="drivers_filter_district_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Truck Number"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Truck', value);
                } else {
                  removeFilter('Truck');
                }
              }}
              value={filters.truck}
              testSelector="drivers_filter_truck-number_input"
            />
          </div>
          <div className="filters__item">
            <Input
              label="Carrier Name"
              onChange={({ target: { value } }) => {
                if (value !== '') {
                  addFilter('Carrier', value);
                } else {
                  removeFilter('Carrier');
                }
              }}
              value={filters.carrier}
              testSelector="drivers_filter_carrier-name_input"
            />
          </div>
          <div className="filters__item">
            <Select
              label="Location Permission"
              options={locationPermissionsList}
              onChange={item => {
                addFilter('Location', item.value);
              }}
              value={
                locationPermissionsList.find(
                  location => filters.location === location.value,
                ) || null
              }
              accountForPadding={false}
              testSelector="drivers_filter_location-permission_select"
            />
          </div>
          <div className="filters__item">
            <Select
              label="Android Version"
              options={androidList}
              onChange={item => {
                addFilter('Android', item.value);
              }}
              value={
                androidList.find(
                  android => filters.android === android.value,
                ) || null
              }
              accountForPadding={false}
              testSelector="drivers_filter_android_select"
            />
          </div>
          <div className="filters__item">
            <Select
              label="iOS Version"
              options={iosList}
              onChange={item => {
                addFilter('iOS', item.value);
              }}
              value={iosList.find(ios => filters.ios === ios.value) || null}
              accountForPadding={false}
              testSelector="drivers_filter_ios_select"
            />
          </div>
          <div className="filters__item">
            <Select
              label="App Version"
              options={driverAppVersions}
              value={
                driverAppVersions.find(
                  appVersion => filters.appVersion === appVersion.value,
                ) || null
              }
              onChange={item => {
                addFilter('appVersion', item.value);
              }}
            />
          </div>
          {!checkbox && (
            <div className="filters__item">
              <Select
                label="Status"
                options={freeBusyStatus}
                onChange={item => {
                  addFilter('Status', item.value);
                }}
                value={
                  freeBusyStatus.find(
                    status => filters.freeBusyStatus === status.value,
                  ) || null
                }
                accountForPadding={false}
                testSelector="drivers_filter_status_select"
              />
            </div>
          )}
          <div className="filters__item">
            <Input
              type="checkbox"
              testSelector="drivers-list_filters_deleted_input"
              label="Deleted"
              onChange={() => {
                handleCheckBoxFilters('Deleted');
                removeFilter('Status');
              }}
              isChecked={checkbox}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverFilters;
