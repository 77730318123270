import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import {
  getOrderTicketTypes,
  getOrderTickets,
  uploadOrderTicket,
  editOrderTicket,
} from './queries';

export const useOrderTicketTypes = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'ticket-types'],
    queryFn: () => getOrderTicketTypes(orderId),
    enabled: !!orderId,
  });

export const useOrderTickets = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'tickets'],
    queryFn: () => getOrderTickets(orderId),
    enabled: !!orderId,
  });

export const useUploadOrderTicket = ({ orderId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ticket => uploadOrderTicket({ orderId, ticket }),
    onSuccess: () => {
      swal('Success!', 'Successfully Uploaded Ticket', 'success');
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
    onError: error =>
      swal('Unable to Upload Ticket', error.response?.data?.message, 'error'),
  });
};

export const useEditOrderTicket = ({ orderId, ticketId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ticket => editOrderTicket({ orderId, ticketId, ticket }),
    onSuccess: () => {
      swal('Success!', 'Successfully Edited Ticket', 'success');
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
    onError: error =>
      swal('Unable to Edit Ticket', error.response?.data?.message, 'error'),
  });
};
