import { queryClient } from "api/APIProvider";
import { get } from "./queries";

export const prefetchDrivers = () =>
  queryClient.prefetchQuery({
    queryKey: ['drivers'],
    queryFn: get,
    staleTime: Infinity,
    cacheTime: Infinity
  });


export const selectLookupDrivers = () => queryClient.getQueryData(['drivers']);
