import { client } from 'api/client';

export const getCustomers = async () => {
  const { data } = await client.get('v2/customer');
  return data.data.customers;
};

export const getCustomerDetails = async (id = 0) => {
  const { data } = await client.get(`v2/customer/${id}/details`);
  return data.data;
}

export const editCustomer = async (info) => {
  const { data } = await client.put(`v2/customer/${info.id}`, info);
  return data.data;
}

export const getCustomerJobs = async (id) => {
  const { data } = await client.get(`v2/customer/${id}/jobs`);
  return data.data;
}

export const deleteCustomer = async (id) => {
  const { data } = await client.delete(`v2/customer/${id}`);
  return data.data;
}

export const unDeleteCustomer = async (id) => {
  const { data } = await client.patch(`v2/customer/${id}/enable`);
  return data.data;
}

export const syncCustomerBC = async (id) => {
  const { data } = await client.post(`v2/customer/${id}/bc-sync`, { bcSync: true });
  return data.data;
}

export const addCustomer = async (customer) => {
  const { data } = await client.post('v2/customer', customer)
  return data.data;
}

export const editCustomerAddress = async (params) => {
  const { data } = await client.put(`v2/customer/${params.id}/address`, params);
  return data.data;
}

export const editCustomerBillingTerms = async (params) => {
  const { data } = await client.put(`v2/customer/${params.id}/billing-terms`, params);
  return data.data;
}

export const editLogo = async (params, id) => {
  const { data } = await client.post(`v2/customer/${id}/logo`, params, {
    headers: {
      dataType: 'json',
      processData: false,
      contentType: false,
    },
  });
  return data.data;
}

export const getScadaReport = async (id) => {
  const { data } = await client.get(`v2/customer/${id}/scada-report`);
  return data.data;
}

export const shareCustomer = async ({ customerId, data }) => {
  const response = await client.post(`v2/customer/${customerId}/share`, data);
  return response.data.data;
}

export const getCustomerBillingProfiles = async ({ customerId, status }) => {
  const numStatus = Number(status);
  const isValid = !Number.isNaN(numStatus);
  const queryItem = isValid ? numStatus : 1;

  const { data } = await client.get(
    `v2/billing/customer/${customerId}/profiles?active=${queryItem}`,
  );
  return data.data;
};

export const validateCustomerJobId = async ({ customerId, customerJobId }) => {
  const { data } = await client.post(`v2/job/customer/${customerId}/validate`, {
    customerId,
    customerJobId
  });
  return data.data;
}
