import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
	get,
	getDetails,
	add,
	voidProfile,
	addPaymentIntoProfile,
	editPaymentInProfile,
	getPaymentsInProfile,
	getOrders,
	reRateOrders,
} from './queries';

export const useCustomerBillingProfiles = ({ id, status }) =>
	useQuery({
		queryFn: () => get(id, status),
		queryKey: ['customers', id, 'profiles', { status: Number(status ?? 1) }],
		enabled: !!id
	});

export const useCustomerBillingProfileDetails = ({ customerId, profileId }) =>
	useQuery({
		queryFn: () => getDetails({ customerId, profileId }),
		queryKey: ['customers', customerId, 'profiles', profileId],
		enabled: !!profileId && !!customerId
	});

export const useAddCustomerBillingProfile = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ data }) => add({ ...data, id: customerId }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'profiles'] });
			swal('Success', 'Profile Added', 'success');
		},
	});

};

export const useVoidCustomerBillingProfile = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ profileId, data }) => voidProfile({ profileId, customerId, data }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'profiles'] });
		},
	});
};

export const useAddPaymentToBillingProfile = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ profileId, payment }) => addPaymentIntoProfile({ customerId, profileId, payment }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'profiles'] });
			swal('Success', 'Payment added', 'success');
		},
	});
};

export const useEditPaymentInBillingProfile = (customerId) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ profileId, paymentId, payment }) => editPaymentInProfile({ customerId, profileId, payment, paymentId }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'profiles'] });
			swal('Success', 'Payment updated', 'success');
		},
	});

};

export const usePaymentsInBillingProfile = (customerId) =>
	useQuery({
		queryFn: ({ profileId }) => getPaymentsInProfile({ customerId, profileId }),
		queryKey: ['customers', customerId, 'profiles'],
	});


export const useOrdersWithBillingProfile = (params) =>
	useQuery({
		queryFn: () => getOrders(params),
		queryKey: ['customers', params.customerId, 'profiles', params.profileId, 'orders'],
	});

export const useReRateOrders = ({ customerId, profileId }) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (params) => reRateOrders(params),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['customers', customerId, 'profiles', profileId, 'orders'] });
		},
		onError: error => swal('Unable to Re-Rate Order', error?.response?.data?.data?.message, 'error'),
	});
}
