import React from 'react';
import { Row } from 'react-bootstrap';

import ToolTip from 'components/shared/ToolTip';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';

import { useCommodityOptions } from './hooks';

/**
 *
 * @param {{
 *  jobDesign: any;
 *  handlers: import('./hooks').EditJobHandlers;
 * }} props
 */
const JobDesign = ({ jobDesign, handlers, customerCommodities }) => {
  const commodityOptions = useCommodityOptions(jobDesign);
  const getCustomerCommodityDetails = ( id, customerCommodities) => {
    if (id && customerCommodities) {
      const commodity = customerCommodities.find(c => c.id === id);
      return commodity ? commodity.unitOfMeasureName : '';
    }
    return '';
  }

  const renderJobDesign = (item, index) => (
    <Row key={`stage-${index}`}>
      {item.volume > 2000000000 && (
        <div className="alert-input-msg">
          Quantity should be less than 2,000,000,000
        </div>
      )}
      {item.minVolume > 2000000000 && (
        <div className="alert-input-msg">
          Alert should be less than 2,000,000,000
        </div>
      )}
      {(item.loadWeight < 100 || item.loadWeight > 85000) && (
        <div className="alert-input-msg">
          Load quantity should be more than 100 and less than 85,000
        </div>
      )}
      <div className="form-group has-feedback col-md-3 p-w-xxs">
        <div className="form-group">
          <Select
            placeholder="Commodity"
            onChange={handlers.arrayValueChange(index, 'commodity', 'jobDesign')}
            value={item.commodity}
            options={commodityOptions}
            required
            testSelector="jobs_add_job-design_sand-type_select"
          />
        </div>
      </div>
      <div className="form-group has-feedback col-md-3 p-w-xxs">  
        <Input
          type="number"
          onChange={handlers.arrayValueChange(index, 'volume', 'jobDesign')}
          value={item.volume || ''}
          label={`Quantity, ${getCustomerCommodityDetails( item?.commodity?.id, customerCommodities) || ''}`}
          required
          min="0"
          max="2000000000"
          testSelector="jobs_add-job-design_volume_input"
        />
      </div>
      <div className="form-group has-feedback col-md-3 p-w-xxs">
        <Input
          type="number"
          onChange={handlers.arrayValueChange(index, 'minVolume', 'jobDesign')}
          value={item.minVolume || ''}
          label="Alert"
          required
          min="0"
          testSelector="jobs_add-job-design_alert_input"
        />
      </div>
      <div className="form-group has-feedback col-md-2 p-w-xxs">
        <Input
          type="number"
          onChange={handlers.arrayValueChange(index, 'loadWeight', 'jobDesign')}
          value={item.loadWeight || ''}
          label="Load Quantity"
          required
          min="100"
          testSelector="jobs_add_job-design_load-weight_input"
        />
      </div>
      {index !== 0 && (
        <div className="form-group has-feedback col-md-1 p-w-xxs">
          <Icon
            style={{
              marginTop: '30px',
            }}
            icon="trash del-icon"
            onClick={handlers.arrayValueDelete(index, 'jobDesign')}
          />
        </div>
      )}
    </Row>
  );

  return (
    <div className="modify-ticket panel panel-default">
      <div className="modify-ticket__header">
        <span className="modify-ticket__header__text">
          <span className="white">Job Design</span>
        </span>
        <div className="modify-ticket__header__buttons">
          <ToolTip title="Preview">
            <Button
              theme="small"
              className="button--small--square collapsed panel-title--with-arrow"
              testSelector="order-actions_modify_order-info-ticket_accordion_btn"
              data-bs-toggle="collapse"
              data-parent="#accordion"
              href="#job-design">
              <Icon icon="down" />
            </Button>
          </ToolTip>
        </div>
      </div>
      <div
        className="modify-ticket__preview panel-collapse collapse"
        id="job-design">
        <fieldset id="form-p-2" role="tabpanel" className="body">
          {jobDesign?.map(renderJobDesign)}
        </fieldset>
        <Button
          onClick={handlers.arrayValueAdd('jobDesign', { loadWeight: 60000 })}
          theme="small"
          testSelector="jobs-list_add-modal_job-design_add-item_btn">
          <Icon icon="plus" /> Add Stage Design Item
        </Button>
      </div>
    </div>
  );
};
export default JobDesign;
