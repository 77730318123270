import { client } from 'api/client';
import FormData from 'form-data';
import { jsonToCsv } from 'utils/csvMaker';

export const get = async () => {
  const { data } = await client.get('v2/lookup/drivers');
  return data.data;
};

export const getPaginated = async params => {
  const { data } = await client.post('v2/driver/search', params);
  return data.data;
};

export const getOsVersions = async () => {
  const { data } = await client.get('v2/driver/os-versions');
  return data.data;
};

export const getRating = async ({ driverId }) => {
  const { data } = await client.get(`v2/driver/${driverId}/rating`);
  return data.data;
}

export const getAppVersions = async () => {
  const { data } = await client.get('v2/driver/app-versions');
  return data.data;
}

export const getDistricts = async ({ driverId }) => {
  const { data } = await client.get(`v2/driver/${driverId}/districts`);
  return data.data.districts;
}

export const changeDistricts = async ({ districtIds, driverId }) => {
  const { data } = await client.post(`v2/driver/${driverId}/districts`, {
    districtIds,
    driverId
  });
  return data.data;
};

export const changeRating = async ({ driverId, rating, comment }) => {
  const { data } = await client.post(`v2/driver/${driverId}/rate`, { rating, comment });
  return data.data;
};

export const changePercentage = async ({ driverId, percentage }) => {
  const { data } = await client.post(`v2/driver/${driverId}/percentage`, {
    driverID: Number(driverId),
    percents: Number(percentage),
  });
  return data.data;
};

export const logOut = async ({ driverId }) => {
  const { data } = await client.post('v2/driverLogOut', { driverId });
  return data.data;
};

export const deleteDriver = async ({ driverId }) => {
  const { data } = await client.delete(`v2/driver/${driverId}`, {
    data: { driverId },
  });
  return data.data;
};

export const restore = async ({ driverId }) => {
  const { data } = await client.put(`v2/driver/reActivate/${driverId}`);
  return data.data;
};

export const free = async ({ driverId }) => {
  const { data } = await client.put(`v2/driver/freeUpDriver/${driverId}`);
  return data.data;
};

export const sendMessage = async params => {
  const { data } = await client.post(`v2/drivers/send-push`, params);
  return data.data;
};

export const pushNotification = async params => {
  const { data } = await client.post('v2/sendPushToDriver', params);
  return data.data;
};

export const create = async (params) => {
  const formData = new FormData();
  formData.append('name', params.name);
  formData.append('password', params.password);
  formData.append('email', params.email);
  formData.append('carrierId', params.carrierId);
  formData.append('district', params.district);
  formData.append('homeDistrict', params.homeDistrict);
  formData.append('contactNo', params.contactNo);
  formData.append('vehicleNumber', params.vehicleNumber);
  formData.append('trailerNumber', params.trailerNumber);
  formData.append('signature', params.signature);
  formData.append('certificatePhotos', params.certificatePhotos);
  formData.append('equipment', params.equipment);
  formData.append('deviceType', '0');
  formData.append('deviceToken', '0');
  formData.append('lat', '0');
  formData.append('long', '0');

  const { data } = await client.post('v2/createDriver', formData, {
    headers: {
      contentType: "multipart/form-data",
    },
  });

  return data.data;
};

export const getProfile = async ({ driverId }) => {
  const { data } = await client.post('v2/getDriver', { id: Number(driverId) });
  return data.data;
};

export const getCertificates = async ({ id }) => {
  const { data } = await client.post(`v2/driver/${id}/certificates`);
  return data.data.certificates;
};

export const edit = async params => {
  const { data } = await client.post('v2/editDriverData', params);

  if (params.includesDistricts) {
    await client.post(`v2/driver/${params.driverId}/districts`, {
      districtIds: params.districtIds
    });
  }

  return data.data;
};

export const editTruck = async ({ driverId, truckNumber, trailerNumber, containers, equipmentType }) => {
  const { data } = await client.post(`v2/driver/${driverId}/equipment`, {
    truckNumber,
    trailerNumber,
    containers,
    equipmentType
  });
  return data.data;
};

export const editAllDrivers = async params => {
  const { data } = await client.post('v2/editDriverData', params, {
    headers: {
      dataType: 'json',
      processData: false,
      contentType: false,
    },
  });
  return data.data;
};

export const downloadRejectionReport = async ({ driverId }) => {
  const { data } = await client.get(`/driver/rejection/report/${driverId}`);
  const rejectionReportData = data.data.rejectedOrders;
  const csvData = jsonToCsv(rejectionReportData, true);
  const hiddenElement = document.createElement('a');
  const blob = new Blob([csvData], {
    type: 'application/csv;charset=utf-8',
  });
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = `driver${driverId}Rejection.csv`;
  hiddenElement.click();

  return data.data;
};


