import React, { useEffect, useCallback, useState } from 'react';
import { Map, APIProvider, useMap, MapControl, ControlPosition } from '@vis.gl/react-google-maps';

import AutomatizeMarkerComponent from './AutomatizeMarker';
import { GoogleMapProvider } from './GoogleMapContext';
import MapControls from './MapControls';
import MapStyles from './mapStyles.json';
import PolygonComponent from './Polygon';
import PolylineComponent from './Polyline';
import DrawingControls from './DrawingControls';
import {
  useDrawingManager,
} from './hooks';


const MapWrapper = ({ fitMarkers, ...props }) => (
  <APIProvider apiKey="AIzaSyApTE5fGGyJCPs2XaO6uEcEhTVrI9kJlQA">
    <GoogleMapProvider fitMarkers={fitMarkers}>
      <GoogleMap {...props} />
    </GoogleMapProvider>
  </APIProvider>
);

export const toRadians = angle => angle * (Math.PI / 180);
export const milesToMeters = miles => 1609.344 * miles;

export const polyCoords = (radius, sides, lat, lng) => {
  // radius in meters, creates coords for circle
  const coords = [];

  const angle = (2 * Math.PI) / sides;
  for (let i = 0; i < sides; i++) {
    const y =
      Number.parseFloat(radius * Math.sin(i * angle)).toFixed(15) / 111111 +
      lat;
    const x =
      Number.parseFloat(radius * Math.cos(i * angle)).toFixed(15) /
      (111111 * Math.cos(toRadians(lat))) +
      lng;
    coords.push({ lat: y, lng: x });
  }

  return coords;
};

const GoogleMap = ({
  onMapUpdate = null,
  initialCenter = { lat: 32.768799, lng: -97.309341 },
  children,
  setInitialCenter,
  fitMarkers = false,
  zoom,
  onClick,
  center,
  onReady,
  strokeColor,
  fillColor,
  polyFenceCoords,
  drawable,
  confirmPolygonSelection
}) => {
  const map = useMap();
  const drawingManager = useDrawingManager({ drawingEnabled: Boolean(drawable), strokeColor, fillColor });
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    if (!drawable) {
      drawingManager?.setMap(null);
    }
  }, [drawable, drawingManager]);

  // Necessary if map hasn't been loaded yet in a session
  const { google } = window;
  useEffect(() => {
    if (google?.maps?.Polygon) {
      setPaths(polyFenceCoords
        ? [
          {
            geometry: new google.maps.Polygon({ strokeColor, fillColor, map }),
            snapshot: { path: polyFenceCoords.map(coords => new google.maps.LatLng(coords)) }
          }
        ]
        : []);
    }
  }, [google, polyFenceCoords, strokeColor, fillColor, map]);

  useEffect(() => {
    if (map) {
      map.panTo(initialCenter);
      map.setZoom(zoom);
    }
  }, [map, initialCenter, zoom]);

  const fitBounds = useCallback(() => {
    const markers = Array.isArray(children) ? children : [children];
    if (Array.isArray(markers) && map && fitMarkers && markers[0]) {
      if (markers.length > 0 && !setInitialCenter) {
        const bounds = new google.maps.LatLngBounds();

        markers.forEach(marker => {
          if (!marker?.props?.position) {
            return;
          }

          bounds.extend(marker.props.position);
        });

        map.fitBounds(bounds);
        map.setZoom(zoom);
      } else {
        map.setZoom(3);
      }

      if (onMapUpdate) {
        onMapUpdate(map);
      }
    }
  }, [fitMarkers, map, onMapUpdate, setInitialCenter, zoom, children]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (onReady) {
      onReady(map);
    }

    if (!children)
      return;

    fitBounds();
  }, [fitBounds, onReady, map, children]);

  return (
    <>
      {drawable &&
        <MapControl position={ControlPosition.BOTTOM_CENTER}>
          <DrawingControls
            drawingManager={drawingManager}
            confirmSelection={confirmPolygonSelection}
            drawingEnabled={Boolean(drawable)}
            paths={paths}
          />
        </MapControl>}

      {map && (
        <div className="home-page__map-controls">
          <MapControls map={map} />
        </div>
      )}
      <Map
        disableDefaultUI
        defaultCenter={initialCenter}
        defaultZoom={zoom}
        styles={MapStyles}
        minZoom={3}
        reuseMaps
        onClick={onClick}>
        {children}
      </Map>
    </>
  );
};

export const Polygon = PolygonComponent;
export const Polyline = PolylineComponent;
export const AutomatizeMarker = AutomatizeMarkerComponent;
export * from './hooks';

export default MapWrapper;
