import React from 'react';

import { useOrderPriceBreakdown } from 'api/v2/orders';
import Loader from 'components/shared/Loader';

const Price = ({ order }) => {
  const { data: priceBreakdown, isLoading } = useOrderPriceBreakdown({
    orderId: order?.order_id,
  });

  const prices = priceBreakdown?.price ?? [];
  const endToEndTime = prices.find(item => Boolean(item.endtoEndTime))
    ?.endtoEndTime;

  const sharedOrder = order.accessLevel === 'shared';
  const { totalPrice } = order;
  const mileage = order?.mileage;

  if (isLoading) {
    return <Loader />;
  }

  const renderPriceItem = (item, index) => (
    <div key={`price-${index}`}>
      {!!item.price && (
        <div
          style={
            prices?.length > 1
              ? {
                  borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
                  marginBottom: '15px',
                }
              : { marginBottom: '15px' }
          }>
          <strong>{item.title}</strong>
          {item.name && <span className="text-navy">{` - ${item.name}`}</span>}
          {': '}
          <strong className="text-navy">${item.price} </strong>- {item.comment}
        </div>
      )}
    </div>
  );

  return (
    <>
      <h3>
        Total price:
        <span className="text-navy"> ${sharedOrder ? 0 : totalPrice}</span>
      </h3>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Mileage: </strong>
        <span className="text-navy">
          {sharedOrder ? 'N/A' : mileage ?? 'N/A'}
        </span>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Order Time: </strong>
        <span className="text-navy">
          {endToEndTime && !sharedOrder ? `${endToEndTime} minutes` : 'N/A'}
        </span>
      </div>
      {prices.map(renderPriceItem)}
    </>
  );
};

export default Price;
