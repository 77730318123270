import swal from 'bootstrap-sweetalert';
import { selectLookupDrivers } from 'api/v2/drivers';
import { resourceConstants } from './constants';
import { customer } from '../../../services/customer/service';
import { jobManager } from '../../../services/jobManager/service';
import { carrier } from '../../../services/carrier/service';
import { jobs as jobsService } from '../../../services/jobs/service';
import { district } from '../../../services/district/service';
import { vendor } from '../../../services/vendor/service';
import { certificates } from '../../../services/certificate/service';
import { equipment } from '../../../services/equipment/service';

const getCustomers = () => {
  const request = () => ({ type: resourceConstants.GET_CUSTOMERS_REQUEST });
  const success = customers => ({
    type: resourceConstants.GET_CUSTOMERS_SUCCESS,
    customers,
  });
  const failure = error => ({
    type: resourceConstants.GET_CUSTOMERS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    customer
      .get()
      .then(response => {
        dispatch(success(response.data.data.customers));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.error, '', 'error');
      });
  };
};

const getJobManagers = () => {
  const request = () => ({ type: resourceConstants.GET_JOB_MANAGERS_REQUEST });
  const success = jobManagers => ({
    type: resourceConstants.GET_JOB_MANAGERS_SUCCESS,
    jobManagers,
  });
  const failure = error => ({
    type: resourceConstants.GET_JOB_MANAGERS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    jobManager
      .getJobManagers()
      .then(response => {
        dispatch(success(response.data.data.jobManager));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getCarriers = () => {
  const request = () => ({ type: resourceConstants.GET_CARRIERS_REQUEST });
  const success = carriers => ({
    type: resourceConstants.GET_CARRIERS_SUCCESS,
    carriers,
  });
  const failure = error => ({
    type: resourceConstants.GET_CARRIERS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    carrier
      .getCarriers()
      .then(response => {
        dispatch(success(response.data.data.carriers));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getAllCarriers = () => {
  const request = () => ({ type: resourceConstants.GET_CARRIERS_REQUEST });
  const success = carriers => ({
    type: resourceConstants.GET_CARRIERS_SUCCESS,
    carriers,
  });
  const failure = error => ({
    type: resourceConstants.GET_CARRIERS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    carrier
      .getCarriers()
      .then(response => {
        dispatch(success(response.data.data.carriers));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getJobs = () => {
  const request = () => ({ type: resourceConstants.GET_JOBS_REQUEST });
  const success = jobs => ({ type: resourceConstants.GET_JOBS_SUCCESS, jobs });
  const failure = error => ({
    type: resourceConstants.GET_JOBS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());
    jobsService
      .get()
      .then(response => {
        dispatch(success(response.data.data.jobs));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getReasons = () => {
  const request = () => ({
    type: resourceConstants.GET_DEMURRAGE_REASONS_REQUEST,
  });
  const success = reasons => ({
    type: resourceConstants.GET_DEMURRAGE_REASONS_SUCCESS,
    reasons,
  });
  const failure = error => ({
    type: resourceConstants.GET_DEMURRAGE_REASONS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    jobManager
      .getDemurrageReason()
      .then(response => {
        dispatch(success(response.data.data.demurageReasons));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getAllDistricts = () => {
  const request = () => ({ type: resourceConstants.GET_ALL_DISTRICTS_REQUEST });
  const success = allDistricts => ({
    type: resourceConstants.GET_ALL_DISTRICTS_SUCCESS,
    allDistricts,
  });
  const failure = error => ({
    type: resourceConstants.GET_ALL_DISTRICTS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    district
      .get()
      .then(response => {
        dispatch(success(response.data.data.district));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getVendors = () => {
  const request = () => ({ type: resourceConstants.GET_SAND_VENDORS_REQUEST });
  const success = vendors => ({
    type: resourceConstants.GET_SAND_VENDORS_SUCCESS,
    vendors,
  });
  const failure = error => ({
    type: resourceConstants.GET_SAND_VENDORS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    vendor
      .get()
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getCertificates = () => {
  const request = () => ({ type: resourceConstants.GET_CERTIFICATES_REQUEST });
  const success = certificate => ({
    type: resourceConstants.GET_CERTIFICATES_SUCCESS,
    certificate,
  });
  const failure = error => ({
    type: resourceConstants.GET_CERTIFICATES_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    certificates
      .get()
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.message, '', 'error');
      });
  };
};

const getContainerVendors = () => {
  const request = () => ({
    type: resourceConstants.GET_CONTAINER_VENDORS_REQUEST,
  });
  const success = vendors => ({
    type: resourceConstants.GET_CONTAINER_VENDORS_SUCCESS,
    vendors,
  });
  const failure = error => ({
    type: resourceConstants.GET_CONTAINER_VENDORS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());
    vendor
      .getContainerVendor()
      .then(response => {
        dispatch(success(response.data.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

const getTrailers = param => {
  const request = () => ({ type: resourceConstants.GET_EQUIPMENT_REQUEST });
  const success = (trailers, totalPages) => ({
    type: resourceConstants.GET_EQUIPMENT_SUCCESS,
    trailers,
    totalPages,
  });
  const failure = error => ({
    type: resourceConstants.GET_EQUIPMENT_FAILURE,
    error,
  });

  if (!param) {
    return dispatch => {
      dispatch(success([], 0));
    };
  }

  return (dispatch, getState) => {
    const state = getState();
    const {
      lookUp: { sandSites, wellSites },
      resourceReducer: {
        jobs: { jobs },
        trailers: { filterNumber },
        containerVendors: { vendors },
      },
      equipmentTrailers: {
        equipmentStatus: { customerId },
      },
    } = state;
    const drivers = selectLookupDrivers();

    const params = {
      id: customerId,
      type: param.type,
      equipmentNumber: filterNumber,
      page: param.page || 0,
      limit: 25,
    };
    dispatch(request());

    if (param.withFilter || !customerId) {
      equipment
        .getEquipmentWithFilters(params)
        .then(response => {
          const equipments = handleEquipment(response);
          dispatch(success(equipments, response.data.data.totalPages));
        })
        .catch(error => {
          swal(error.response.data.message, '', 'error');
        });
    } else {
      equipment
        .getEquipment(params)
        .then(response => {
          const equipments = handleEquipment(response);
          dispatch(success(equipments, response.data.data.totalPages));
        })
        .catch(error => {
          swal(error.response.data.message, '', 'error');
        });
    }

    const handleEquipment = response => {
      const equipments =
        (response && response.data && response.data.data.equipments) || [];
      const findSandInfo = data => {
        const sand = sandSites.filter(i => i.id == data.locationId);
        data.locationName = sand && sand.length ? sand[0].name : 'unknown';
      };
      const findWellInfo = data => {
        const well = wellSites.filter(i => i.id == data.locationId);
        data.locationName = well && well.length ? well[0].name : 'unknown';
      };
      const findJobInfo = data => {
        const job = jobs.filter(i => i.id == data.locationId);
        data.locationName = job && job.length ? job[0].job_name : 'unknown';
      };
      const findDriverInfo = data => {
        const filteredDriver = drivers.filter(i => i.id === data.locationId)[0];
        data.locationName = filteredDriver ? filteredDriver.name : 'unknown';
        data.carrier_name = filteredDriver
          ? filteredDriver.carrier_name
          : 'unknown';
        data.truck_no = filteredDriver ? filteredDriver.truck_no : 'unknown';
        data.order_id = filteredDriver ? filteredDriver.order_id : 'unknown';
      };

      for (let i = 0; i < equipments.length; i++) {
        switch (+equipments[i].locationType) {
          case 1:
            findSandInfo(equipments[i]);
            break;
          case 2:
            findWellInfo(equipments[i]);
            break;
          case 3:
            findDriverInfo(equipments[i]);
            break;
          case 4:
            findJobInfo(equipments[i]);
            break;
        }

        equipments[i].containerVendorName = '-';
        if (equipments[i].containerVendorId != null) {
          for (let j = 0; j < vendors.length; j++) {
            if (equipments[i].containerVendorId == vendors[j].id) {
              equipments[i].containerVendorName = vendors[j].name;
              break;
            }
          }
        }
      }
      return equipments;
    };
  };
};

const clearStoreEquipment = () => ({
  type: resourceConstants.CLEAR_STORE_EQUIPMENT,
});
const setEquipmentFilterNumber = filterNumber => ({
  type: resourceConstants.SET_EQUIPMENT_FILTER_NUMBER,
  filterNumber,
});

const getDistrictAccordingToCarrier = carrierId => {
  const request = () => ({
    type: resourceConstants.GET_DISTRICT_TO_CARRIER_REQUEST,
  });
  const success = districts => ({
    type: resourceConstants.GET_DISTRICT_TO_CARRIER_SUCCESS,
    districts,
  });
  const failure = error => ({
    type: resourceConstants.GET_DISTRICT_TO_CARRIER_FAILURE,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    carrier
      .getDistrictAccordingToCarrier(carrierId)
      .then(response => {
        dispatch(success(response.data.data.district));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(
          error.response.data ? error.data.message : error.data,
          '',
          'error',
        );
      });
  };
};

export const resourceActions = {
  getCustomers,
  getJobManagers,
  getCarriers,
  getAllCarriers,
  getJobs,
  getReasons,
  getAllDistricts,
  getVendors,
  getCertificates,
  getContainerVendors,
  getTrailers,
  clearStoreEquipment,
  setEquipmentFilterNumber,
  getDistrictAccordingToCarrier,
};
