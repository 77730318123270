import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { resourceActions } from 'store/modules/resourceReducer/actions';
import actions from 'store/actions/Equipments';
import eventTarget from 'utils/dispatchWithFieldValue';
import { useLocations } from 'api/v2/locations';

import Authorize, { authorize } from 'components/common/Authorize';
import PaginationRequest from 'components/common/ReduxPagination';
import { trailerLocation } from 'components/globalInfo/trailerLocation';
import { deleteAlert } from 'components/sweetalert';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import Label from 'components/shared/Label';
import Input from 'components/shared/Input';

import { useSearchParams } from 'helpers/hooks';
import Status from './StatusModal';
import Location from './LocationModal';
import History from './HistoryModal';
import EditContainer from './EditContainer';

const Trailers = ({
  setEquipmentData,
  setLocationData,
  getHistory,
  clearHistory,
  setEquipmentEditData,
  customers,
  isLoadedTrailers,
  trailers,
  setCustomerId,
  customerId,
  setFilterNumber,
  filterNumber,
  deleteContainer,
  totalPages,
}) => {
  const [showStatus, turnStatus] = useState(false);
  const [showLocation, turnLocation] = useState(false);
  const [showHistory, turnHistory] = useState(false);
  const [showEditContainer, turnEditContainer] = useState(false);
  const { data: acidFacilities } = useLocations({ type: 5 });

  const history = useHistory();
  const { pathname } = useLocation();
  const searchParams = useSearchParams();

  useEffect(() => {
    const customer = searchParams.get('customerId');
    if (customer) {
      setCustomerId(Number(customer));
    }
  }, [searchParams, setCustomerId]);

  const customerList = customers.map(customer => ({
    value: customer.id,
    label: `${customer.id} | ${customer.name}`,
  }));
  customerList.unshift({ value: '', label: 'All customers' });

  return (
    <div className="row">
      {showStatus && (
        <Status
          equipmentType={pathname.split('/')[3] === 'containers' ? 2 : 1}
          showStatus={showStatus}
          closeStatus={() => turnStatus(false)}
        />
      )}
      {showLocation && (
        <Location
          equipmentType={pathname.split('/')[3] === 'containers' ? 2 : 1}
          showLocation={showLocation}
          closeLocation={() => turnLocation(false)}
        />
      )}
      {showEditContainer && (
        <EditContainer
          showEditContainer={showEditContainer}
          closeEditContainer={() => turnEditContainer(false)}
        />
      )}
      {showHistory && (
        <History
          showHistory={showHistory}
          closeHistory={() => {
            clearHistory();
            turnHistory(false);
          }}
        />
      )}
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <Authorize {...{ access: ['CUSTOMER'] }}>
            <h5>
              {pathname.split('/')[3] === 'containers'
                ? 'Containers'
                : 'Trailers'}{' '}
              list
            </h5>
          </Authorize>
          <Authorize {...{ forbidden: ['CUSTOMER'] }}>
            <div className="row">
              {pathname.split('/')[3] === 'containers' && (
                <div className="col-lg-3">
                  <Input
                    type="text"
                    value={filterNumber || ''}
                    onChange={setFilterNumber}
                    label="Equipment Number"
                    testSelector="equipment-trailers_trailers_number_input"
                  />
                </div>
              )}
              <div className="col-lg-3">
                <Select
                  options={customerList}
                  value={
                    isLoadedTrailers && trailers
                      ? customerList.find(item => item.value === customerId)
                      : ''
                  }
                  onChange={item => {
                    searchParams.set('customerId', item.value);
                    history.replace({ search: searchParams.toString() });
                    setCustomerId(item.value);
                  }}
                  placeholder="Please Select a Customer"
                  testSelector="equipment-trailers_trailers_customer_select"
                />
              </div>
            </div>
          </Authorize>
        </div>
        <div>
          <PaginationRequest totalPages={totalPages} />
        </div>
        <div className="ibox-content">
          <table className="table table--hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Equipment №</th>
                <th>Status</th>
                <th>Location</th>
                <th>Home Location</th>
                <th>Vendor</th>
                <th>Order info</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoadedTrailers &&
                trailers.map(trailer => (
                  <tr key={trailer.id}>
                    <td>{trailer.id}</td>
                    <td>{trailer.equipmentNumber}</td>
                    <td>
                      {trailer.status === 3 && (
                        <Label type="primary">Empty</Label>
                      )}
                      {trailer.status === 1 && trailer.locationType !== 3 && (
                        <Label type="success">Staged</Label>
                      )}
                      {trailer.status === 1 && trailer.locationType === 3 && (
                        <Label type="success">Full</Label>
                      )}
                      {trailer.status === 2 && (
                        <Label type="warning">Unloading</Label>
                      )}
                      {trailer.status === 3 && trailer.locationType === 3 && (
                        <Label type="info">En route to Origin</Label>
                      )}
                      {trailer.status === 1 && trailer.locationType === 3 && (
                        <Label type="info">En route to Destination</Label>
                      )}
                    </td>
                    <td>
                      <div className="text-left">
                        {trailer.locationType === 1 && (
                          <span>
                            <Icon
                              icon="sand_site"
                            />
                            <strong>
                              {' '}
                              {
                                trailerLocation[trailer.locationType].name
                              } /{' '}
                            </strong>
                            <span
                              className="text-navy"
                              style={{ cursor: 'pointer' }}>
                              {trailer.locationName}
                            </span>
                          </span>
                        )}
                        {trailer.locationType === 2 && (
                          <span>
                            <Icon
                              icon="well_site"
                            />
                            <strong>
                              {' '}
                              {
                                trailerLocation[trailer.locationType].name
                              } /{' '}
                            </strong>
                            <span
                              className="text-navy"
                              style={{ cursor: 'pointer' }}>
                              {trailer.locationName}
                            </span>
                          </span>
                        )}
                        {trailer.locationType === 3 && (
                          <span>
                            <Icon
                              icon="truck"
                            />
                            <strong>
                              {' '}
                              {
                                trailerLocation[trailer.locationType].name
                              } /{' '}
                            </strong>
                            <span
                              className="text-navy"
                              style={{ cursor: 'pointer' }}>
                              {trailer.locationName}
                            </span>
                          </span>
                        )}
                        {trailer.locationType === 4 && (
                          <span>
                            <Icon
                              icon="job"
                            />
                            <strong>
                              {' '}
                              {
                                trailerLocation[trailer.locationType].name
                              } /{' '}
                            </strong>
                            <span
                              className="text-navy"
                              style={{ cursor: 'pointer' }}>
                              {trailer.locationName}
                            </span>
                          </span>
                        )}
                        {trailer.locationType === 5 && (
                          <span>
                            <Icon
                              icon="acid_facility"
                              width={40}
                              height={40}
                            />
                            <strong>
                              {' '}
                              {
                                trailerLocation[trailer.locationType].name
                              } /{' '}
                            </strong>
                            <span
                              className="text-navy"
                              style={{ cursor: 'pointer' }}>
                              {acidFacilities.find(loc => trailer.locationId === loc.id)?.name ?? ''}
                            </span>
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <span>
                        <Icon
                          icon="well_site"
                        />
                        <span
                          className="text-navy"
                          style={{ cursor: 'pointer' }}>
                          {trailer.homeLocationName ?? 'unknown'}
                        </span>
                      </span>
                    </td>
                    <td>
                      <strong>{trailer.containerVendorName}</strong>
                    </td>
                    <td>
                      {trailer.locationType === 3 ? (
                        <span>
                          <strong> Carrier: </strong>{' '}
                          <span>{trailer.carrier_name}</span>
                          <br />
                          <strong> Truck # {trailer.truck_no}</strong>
                          <span
                            className="text-navy"
                            style={{ cursor: 'pointer' }}>
                            {trailer.order_id != null
                              ? `/ Order #${trailer.order_id}`
                              : ''}
                          </span>
                        </span>
                      ) : (
                        <strong className="text-center">-</strong>
                      )}
                    </td>
                    <td>
                      {(authorize({
                        abilityPermissions: [
                          { resource: 'Trailers', permissions: ['update'] },
                        ],
                      }) ||
                        authorize({
                          abilityPermissions: [
                            { resource: 'Containers', permissions: ['update'] },
                          ],
                        })) && (
                          <>
                            <Button
                              onClick={() => {
                                turnStatus(true);
                                setEquipmentData(trailer);
                              }}
                              testSelector="equipments_trailers_status_btn"
                              colour="white"
                              theme="small">
                              <Icon
                                icon="pencil"
                                className="icon--margin-right"
                              />
                              Status
                            </Button>
                            <Button
                              onClick={() => {
                                turnLocation(true);
                                setLocationData(trailer);
                              }}
                              testSelector="equipments_trailers_location_btn"
                              colour="white"
                              theme="small">
                              <Icon
                                icon="pencil"
                                className="icon--margin-right"
                              />
                              location
                            </Button>
                            {pathname.split('/')[3] === 'containers' && (
                              <Authorize
                                {...{
                                  abilityPermissions: [
                                    {
                                      resource: 'Containers',
                                      permissions: ['update'],
                                    },
                                  ],
                                }}>
                                <Button
                                  onClick={() => {
                                    getHistory(trailer.customerId, trailer.id);
                                    setEquipmentEditData(trailer);
                                    turnEditContainer(true);
                                  }}
                                  testSelector="equipments_trailers_edit_btn"
                                  colour="green"
                                  theme="small">
                                  <Icon
                                    icon="pencil"
                                    className="icon--margin-right"
                                  />
                                  edit
                                </Button>
                              </Authorize>
                            )}
                            <Button
                              onClick={() => {
                                getHistory(
                                  trailer.customerId,
                                  trailer.id,
                                  trailer.locationName,
                                );
                                turnHistory(true);
                              }}
                              testSelector="equipments_trailers_history_btn"
                              colour="white"
                              theme="small">
                              <Icon
                                icon="history"
                                className="icon--margin-right"
                              />
                              history
                            </Button>
                            {pathname.split('/')[3] === 'containers' && (
                              <Authorize
                                {...{
                                  abilityPermissions: [
                                    {
                                      resource: 'Containers',
                                      permissions: ['delete'],
                                    },
                                  ],
                                }}>
                                <Button
                                  onClick={() =>
                                    deleteAlert(deleteContainer, trailer.id)
                                  }
                                  testSelector="equipments_trailers_delete_btn"
                                  colour="white"
                                  theme="small">
                                  <Icon
                                    icon="trash"
                                    className="icon--margin-right"
                                  />
                                  delete
                                </Button>
                              </Authorize>
                            )}
                          </>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  customerId: state.equipmentTrailers.equipmentStatus.customerId,
  filterNumber: state.resourceReducer.trailers.filterNumber,
});

const mapDispatchToProps = dispatch => ({
  setCustomerId: id => dispatch(actions.setCustomerId(id)),
  setEquipmentData: data => dispatch(actions.setEquipmentData(data)),
  setLocationData: data => dispatch(actions.setLocationData(data)),
  getHistory: (customerId, trailerId) =>
    dispatch(actions.getHistory(customerId, trailerId)),
  clearHistory: () => dispatch(actions.clearHistory()),
  setFilterNumber: eventTarget(
    resourceActions.setEquipmentFilterNumber,
    dispatch,
  ),
  setEquipmentEditData: data => dispatch(actions.setEquipmentEditData(data)),
  deleteContainer: containerId =>
    dispatch(actions.deleteContainer(containerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Trailers);
