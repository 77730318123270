import { client } from 'api/client';

/**
 * @typedef {{
 *   comment_id: number;
 *   character_id: number;
 *   character_type: string;
 *   comment: string;
 *   created_at: string;
 *   is_system: 0 | 1;
 *   profile_url: string;
 * }} Comment
 */

/**
 *
 * @param {number} orderId
 * @returns {Promise<Comment[]>}
 */
export const getOrderComments = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/comment`);
  return data.data.comments;
};

/**
 *
 * @param {{
 *  orderId: number;
 *  comment: any;
 * }} params
 * @returns
 */
export const addOrderComment = async ({ orderId, comment }) => {
  const { data } = await client.post(`v2/order/${orderId}/comment`, {
    comment,
  });
  return data.data;
};
