import { useQuery } from '@tanstack/react-query';

import { getOrderPriceBreakdown, getOrderPriceModel } from './queries';

export const useOrderPriceBreakdown = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'price-breakdown'],
    queryFn: () => getOrderPriceBreakdown(orderId),
    enabled: !!orderId,
  });

export const useOrderPriceModel = ({ orderId }) => 
  useQuery({
    queryKey: ['orders', Number(orderId), 'price-model'],
    queryFn: () => getOrderPriceModel(orderId),
    enabled: !!orderId,
  });
