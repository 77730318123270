import React from 'react';

import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { useDriverProfile } from 'api/v2/drivers';
import DriverInf from './DriverInfo';
import MoreInformation from './MoreInformation';

const DriverDetails = () => {
  const { driverId } = useParams();
  const { data: driverInf, isLoading: isLoadingDriverProfile } = useDriverProfile({ driverId })

  if (isLoadingDriverProfile) {
    return <Loader />;
  }

  return (
    <div>
      <Header title="Driver Details" />
      <Row>
        {driverInf.driver[0] ? (
          <>
            <DriverInf
              key="driver-info"
              driverInf={driverInf.driver[0]}
              driverDistricts={driverInf.districts}
              carrierId={driverInf.driver[0].carrier_id}
              orders={driverInf.driverAcceptedOrders}
            />
            <MoreInformation
              key="more-info"
              ratings={driverInf.driverRatings}
              driverDistricts={driverInf.districts}
              driverInf={driverInf.driver[0]}
              orders={driverInf.driverAcceptedOrders}
              rejectedOrders={driverInf.driverRejectedOrders}
              logs={driverInf.logs}
            />
          </>
        ) : (
          <div className="wrapper-content project-manager text-center">
            <h3>Driver not found, deleted or you have no permissions</h3>
          </div>
        )}
      </Row>
    </div>
  );
};

export default DriverDetails;
