import {
  useQuery,
} from '@tanstack/react-query';

import { getOrderNotarizations } from './queries';

export const useOrderNotarizations = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'notarizations'],
    queryFn: () => getOrderNotarizations(orderId),
    enabled: !!orderId,
  });

