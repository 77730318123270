import React, { useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { priceTypes } from 'components/globalInfo/priceTypes';
import Button from 'components/shared/Button';
import { useOrderPriceBreakdown } from 'api/v2/orders';
import Loader from 'components/shared/Loader';

const PriceInfo = ({ orderId, showModalPriceInfo, closeInfo }) => {
  const { data: priceBreakdown, isLoading } = useOrderPriceBreakdown({
    orderId,
  });

  const distancePriceType = useMemo(
    () => (priceBreakdown?.price || []).find(p => p.type === 1),
    [priceBreakdown],
  );

  const handleClose = () => {
    closeInfo();
  };

  const renderPriceBreakdownItem = (item, index) => (
    <Row
      key={`price-${index}`}
      className="form-group has-feedback"
      style={
        priceBreakdown?.price?.length > 1
          ? { borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }
          : {}
      }>
      <span>
        <strong className="stats-label">{priceTypes[item.type].title}: </strong>
        <span className="text-navy">${item.price}</span>
        <strong className="stats-label"> - </strong>
        <span className="stats-label">{item.comment}</span>
      </span>
    </Row>
  );

  return (
    <Modal
      style={{ top: `${20}%` }}
      className="modal-container"
      show={showModalPriceInfo}
      onHide={handleClose}>
      <Modal.Header>
        <Modal.Title as="h3" className="modal-title">
          Price Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row className="form-group has-feedback">
              <span>
                <strong className="stats-label">Mileage: </strong>
                <span className="text-navy">
                  {distancePriceType && distancePriceType?.mileage
                    ? `${distancePriceType?.mileage} mi`
                    : 'N/A'}
                </span>
              </span>
            </Row>
            <Row className="form-group has-feedback">
              <span>
                <strong className="stats-label">Order Time: </strong>
                <span className="text-navy">
                  {distancePriceType && distancePriceType?.endtoEndTime
                    ? `${distancePriceType?.endtoEndTime} minutes`
                    : 'N/A'}
                </span>
              </span>
            </Row>
            <Row>{priceBreakdown.price.map(renderPriceBreakdownItem)}</Row>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handleClose}
          colour="white"
          testSelector="order-details_pricing_info-modal_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PriceInfo;
