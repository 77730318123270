import {
  useCommoditiesByPo,
  useFreeDrivers,
  useJobDetails,
  useJobStages,
} from 'api/v2/jobs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useReducer } from 'react';
import targetValue from 'utils/targetValue';

export const usePoCommodities = jobId => {
  const { data: commodities } = useCommoditiesByPo({ jobId });
  const { data: jobDetails } = useJobDetails({ jobId });

  const poCommodities = useMemo(() => {
    if (commodities.length && !isEmpty(commodities[0])) {
      const purchaseOrders = jobDetails?.purchaseOrders || [];
      return commodities
        .map(item => {
          const matched = purchaseOrders.find(
            p => p.poNo.toString() === item.poNo.toString(),
          );
          item.limitExceeded =
            matched.dailyLimit > 0
              ? matched.usedForLast24Hours + matched.loadWeight >
              matched.dailyLimit
              : false;
          item.equipment = matched.equipment;
          return item;
        })
        .filter(item => item.enabled && !item.limitExceeded)
        .map(item => ({
          value: item.poNo,
          label: `${item.poNo} | ${item.sandTypeName} | ${item.originName}`,
          ...item,
          // interstateCertRequired: item.interstateCertRequired,
        }));
    }
    return [];
  }, [commodities, jobDetails?.purchaseOrders]);

  return poCommodities;
};

export const useDriverOptions = ({ jobId, po }) => {
  const { data: drivers } = useFreeDrivers({ locationId: po?.originId });
  const { data: jobDetails } = useJobDetails({ jobId });

  const filteredDrivers = useMemo(() => {
    if (!drivers.length || !jobDetails || !po) {
      return [];
    }

    const filtered = drivers.filter(
      driver =>
        driver &&
        driver.districts &&
        driver.districts.length &&
        driver.districts.some(driverDistrict =>
          jobDetails.districts.find(d => d.id === driverDistrict.id),
        ) &&
        jobDetails.details.equipments.some(
          equipment => equipment === driver.equipment,
        ) &&
        (!po?.equipment || po.equipment === driver.equipment) &&
        (po.isCertRequired ? driver.interstateCertified : true),
    );
    return filtered.map(driver => ({
      value: driver.id,
      label: `${driver.id} | ${driver.name} | ${(
        driver.distanceFromSand / 1609.344
      ).toFixed(0)} mi away from sand site`,
    }));
  }, [drivers, jobDetails, po]);
  return filteredDrivers;
};

export const useStageOptions = jobId => {
  const { data: stages } = useJobStages(jobId);

  const stageOptions = useMemo(
    () => [
      ...(stages ?? []).map(stageItem => ({
        value: stageItem.stageId,
        label: `${stageItem.stageId}`,
      })),
      { value: 0, label: 'Dispatch new' },
    ],
    [stages],
  );
  return stageOptions;
};

/**
 * @typedef {{
 *   startDate: string;
 *   loadDate: string;
 *   po: string;
 *   stage: string;
 *   count: string;
 *   stagingSite: string;
 *   autoAssign?: boolean;
 *   driverId?: string;
 * }} AddOrder
 */

const initialAddOrder = {
  startDate: '',
  loadDate: '',
  po: '',
  stage: '',
  count: '',
  stagingSite: '',
  autoAssign: true,
  driverId: '',
};

/**
 * @typedef {
 * { type: 'init'; data: AddOrder[]; } |
 * { type: 'set_value'; name: string; value: string; } |
 * { type: 'clear_state'}
 * } AddOrderAction
 */

/**
 *
 * @param {AddOrder} state
 * @param {AddOrderAction} action
 * @returns {AddOrder}
 */
const AddOrderReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialAddOrder;
    default:
      return state;
  }
};

export const useAddOrderForm = ({ showModal, stagingSite }) => {
  /** @type {[AddOrder, () => void]} */
  const [data, dispatch] = useReducer(AddOrderReducer, initialAddOrder);

  useEffect(() => {
    if (showModal) {
      dispatch({
        type: 'init',
        data: {
          ...initialAddOrder,
          startDate: moment().add(1, 'm'),
          stagingSite,
        },
      });
    }
  }, [showModal, stagingSite]);

  const isValid = useMemo(
    () =>
      Boolean(
        data.startDate &&
        data.loadDate &&
        data.po &&
        data.count &&
        (!data.autoAssign ? data.driverId : true),
      ),
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e => {
        if (name === 'po') {
          return dispatch({
            type: 'set_value',
            name,
            value: e,
          });
        }

        return dispatch({
          type: 'set_value',
          name,
          value: targetValue(e?.value ?? e),
        });
      },
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
