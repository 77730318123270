import React, { useState, useEffect, useMemo, useCallback } from 'react';
import targetValue from 'utils/targetValue';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Label from 'components/shared/Label';
import Loader from 'components/shared/Loader';

import { useDebounce } from 'helpers/hooks';
import { usePaginatedDrivers, useSendDriverMessage } from 'api/v2/drivers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal, Row } from 'react-bootstrap';
import { useSendNotificationDriverFilters, useSendNotificationForm } from './hooks';

const getStatus = (free, busy) => {
  if (free) {
    return 0;
  }
  if (busy) {
    return 1;
  }
  return undefined;
};

const SendNotifications = ({
  showModalSendNotifications,
  closeSendNotifications,
}) => {
  const [newDrivers, setNewDrivers] = useState([]);
  const [filters, { setFilter, setPage, selectFree, selectBusy }] = useSendNotificationDriverFilters();
  const [{ recipients, selectAllLabel }, { selectAll, checkDriver }] = useSendNotificationForm();
  const [message, setMessage] = useState('');
  const debouncedFilters = useDebounce(filters, 500);

  const { mutate: sendDriverMessage } = useSendDriverMessage();

  const sendMessage = useCallback((e) => {
    e.preventDefault();
    const driverIds = Object.keys(recipients)
      .filter(id => recipients[id])
      .map(Number);
    sendDriverMessage(
      {
        driversId: driverIds,
        message
      },
      {
        onSuccess: closeSendNotifications
      }
    );
  }, [recipients, message, sendDriverMessage, closeSendNotifications]);

  const mappedFilters = useMemo(() => ({
    driverId: debouncedFilters?.driverId?.length
      ? Number(debouncedFilters.driverId)
      : undefined,
    driverName: debouncedFilters.name.length ? debouncedFilters.name : undefined,
    districtDetail: debouncedFilters.district.length
      ? debouncedFilters.district
      : undefined,
    truckNumber: debouncedFilters.truck.length ? debouncedFilters.truck : undefined,
    carrierName: debouncedFilters.carrier.length ? debouncedFilters.carrier : undefined,
    isDeleted: 0,
    status: getStatus(filters.free, filters.busy),
    page: filters.page,
  }), [filters, debouncedFilters]);

  // Don't want to trigger on page change
  useEffect(() => {
    setNewDrivers([]);
  }, [mappedFilters.driverId, mappedFilters.driverName, mappedFilters.districtDetail, mappedFilters.truckNumber, mappedFilters.carrierName, mappedFilters.status]);

  const { data: driversData } = usePaginatedDrivers(mappedFilters);
  const driversResult = driversData?.drivers;

  const saveDisabled = useMemo(() =>
  (!message?.length ||
    !Object.values(recipients).some(Boolean)),
    [message, recipients]);

  useEffect(() => {
    setNewDrivers(oldDrivers => [...oldDrivers, ...(driversResult ?? [])]);
  }, [driversResult]);

  const handleInputMessage = e => {
    setMessage(e.target.value);
  };

  const renderDriverItem = item => (
    <div
      key={`driver-${item.id}`}
      className="vote-item"
      style={{ padding: '5px 5px' }}>
      <div className="row">
        <div className="col-lg-12" style={{ display: 'flex' }}>
          <div>
            <Input
              type="checkbox"
              isChecked={Boolean(recipients[item.id] || false)}
              onChange={e => checkDriver(item.id, targetValue(e))}
              testSelector={`drivers-list_send-notification_select-driver_${item.id
                }_input`}
            />
          </div>
          <label
            htmlFor={`drivers-list_send-notification_select-driver_${item.id
              }_input`}
            style={{ marginLeft: 5 }}>
            <strong
              className="text-navy"
              style={{ cursor: 'pointer', fontSize: 16 }}>
              <Icon icon="male" /> driver #{item.id}:
            </strong>
            <span> {item.name}</span>
            {item.status ? (
              <Label style={{ padding: '0px 5px' }} type="danger">
                active
              </Label>
            ) : (
              <Label style={{ padding: '0px 5px' }} type="primary">
                free
              </Label>
            )}
          </label>
        </div>
      </div>
      <div className="row footerSandSite" />
    </div>
  );

  const renderBody = () => (
    <>
      <Row>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            type="number"
            onChange={e => {
              setFilter(targetValue(e), 'driverId');
            }}
            label="Driver ID"
            value={filters.driverId}
            testSelector="drivers_send-notifications_driver-id_input"
          />
        </div>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setFilter(targetValue(e), 'name');
            }}
            label="Driver Name"
            testSelector="drivers_send-notifications_name_input"
          />
        </div>
        <div className="form-group has-feedback col-md-2 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setFilter(targetValue(e), 'district');
            }}
            label="District"
            testSelector="drivers_send-notifications_district_input"
          />
        </div>
        <div className="form-group has-feedback col-md-3 col-sm-6 col-lg-6">
          <Input
            type="number"
            onChange={e => {
              setFilter(targetValue(e), 'truck');
            }}
            label="Truck number"
            testSelector="drivers_send-notifications_truck-number_input"
          />
        </div>
        <div className="form-group has-feedback col-md-3 col-sm-6 col-lg-6">
          <Input
            onChange={e => {
              setFilter(targetValue(e), 'carrier');
            }}
            label="Carrier name"
            testSelector="drivers_send-notifications_carrier-name_input"
          />
        </div>
      </Row>
      <Row>
        <div className="col-lg-5">
          <Input
            onChange={handleInputMessage}
            label="Message"
            required
            testSelector="drivers_send-notifications_message_input"
          />
        </div>
        <div className="col-lg-7">
          <Button
            onClick={() => {
              setNewDrivers([]);
              selectFree();
            }}
            theme="small"
            inverse={filters.free}
            drivers-list_filter_toggle_btn
            testSelector="drivers-list_send-notifications_free_btn">
            Free
          </Button>
          <Button
            onClick={() => {
              setNewDrivers([]);
              selectBusy();
            }}
            theme="small"
            inverse={filters.busy}
            testSelector="drivers-list_send-notifications_busy_btn">
            Busy
          </Button>
        </div>
      </Row>
      <div
        id="scrollableDiv"
        style={{
          marginTop: 10,
          maxHeight: '500px',
          overflowY: 'scroll',
        }}>
        <Row className="vote-item" style={{ padding: '5px 5px' }}>
          <div className="col-lg-12">
            <Input
              type="checkbox"
              testSelector="drivers-list_send-notification_select-all_input"
              isChecked={selectAllLabel}
              onChange={e => {
                selectAll(newDrivers, targetValue(e));
              }}
              label="Select All"
            />
          </div>
        </Row>
        <InfiniteScroll
          dataLength={newDrivers.length}
          hasMore={Boolean(driversResult?.length)}
          loader={<Loader />}
          next={() => setPage(filters.page + 1)}
          scrollableTarget="scrollableDiv">
          {newDrivers.map(renderDriverItem)}
        </InfiniteScroll>
      </div>
    </>
  );

  return (
    <Modal
      show={showModalSendNotifications}
      onHide={closeSendNotifications}>
      <Modal.Header>
        <Modal.Title as="h3">Select Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeSendNotifications}
          colour="white"
          testSelector="drivers-list_send-notifications_close_btn">
          Close
        </Button>
        <Button
          onClick={sendMessage}
          disabled={saveDisabled}
          testSelector="drivers-list_send-notifications_send_btn">
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendNotifications;
