import React from 'react';
import swal from 'bootstrap-sweetalert';
import { history } from 'helpers/history';
import Button from 'components/shared/Button';
import { queryClient } from 'api';
import constants from './constants';
import eventsService from '../../../services/events/service';
import { users } from '../../../services/users/service';

const init = props => dispatch => {
  const request = () => ({ type: constants.EVENTS_REQUEST });
  const success = (events, lastEventId) => ({
    type: constants.EVENTS_REQUEST_SUCCESS,
    events,
    lastEventId,
  });
  const failure = error => ({ type: constants.EVENTS_REQUEST_FAILURE, error });

  dispatch(request());

  eventsService
    .get()
    .then(response => {
      const { events } = response.data.data;
      let lastEventId = 0;
      if (events.length > 0) {
        lastEventId = events[0].id;
      }
      dispatch(success(events, lastEventId));
      dispatch(initUpdateInterval(props));
    })
    .catch(error => {
      dispatch(failure(error.data.message));
    });
};

const notification = (event, props) => (dispatch, getState) => {
  const state = getState();
  const allowNotification = state.lookUp.user.settings.allowNotifications;

  // Always show the invoice complete message
  if (!allowNotification && event.type !== 'invoice_complete_message') {
    return false;
  }
  // Don't show invoice message if there is no accompanying message
  if (event.type === 'invoice_complete_message' && !event.eventMessage) {
    return false;
  }

  let bodyNotification = {};
  switch (event.type) {
    case 'order_created':
      bodyNotification = {
        variant: 'info',
        message: `New Order: Order #${event.orderId} was created`,
      };
      break;
    case 'order_assigned':
      bodyNotification = {
        variant: 'info',
        message: `Assigned: Order #${event.orderId} was assigned to ${
          event.driverName
        } 
        (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName})`,
      };
      break;
    case 'order_accepted':
      bodyNotification = {
        variant: 'success',
        message: `Accepted: Order #${event.orderId} was accepted by ${
          event.driverName
        } 
        (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName})`,
      };
      break;
    case 'order_rejected':
      bodyNotification = {
        variant: 'warning',
        message: `Rejected: Order #${event.orderId} was rejected by ${
          event.driverName
        } 
        (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName})`,
      };
      break;
    case 'order_completed':
      bodyNotification = {
        variant: 'success',
        message: `Completed: Order #${event.orderId} was completed by 
        ${event.driverName} (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName})`,
      };
      break;
    case 'driver_entering_sandsite':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName})
        entered origin for order #${event.orderId})`,
      };
      break;
    case 'driver_departing_sandsite':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, truck: ${
          event.truckNumber
        },
        carrier: ${event.carrierName}) 
        departed origin for order #${event.orderId})`,
      };
      break;
    case 'driver_entering_wellsite':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, truck: ${
          event.truckNumber
        }, 
        carrier: ${event.carrierName}) 
        entered destination for order #${event.orderId})`,
      };
      break;
    case 'driver_call_failed':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, truck: ${
          event.truckNumber
        }, 
        carrier: ${event.carrierName})  
        has been called for being inactive and call failed, order #${
          event.orderId
        })`,
      };
      break;
    case 'driver_call_busy':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, truck: ${
          event.truckNumber
        }, 
        carrier: ${event.carrierName}) 
        has been called for being inactive and call status is busy, order #${
          event.orderId
        })`,
      };
      break;
    case 'driver_call_completed':
      bodyNotification = {
        variant: 'info',
        message: `Tracking: ${event.driverName} (#${event.driverId}, 
        truck: ${event.truckNumber}, 
        carrier: ${event.carrierName}) 
        has been called for being inactive and call completed, order #${
          event.orderId
        })`,
      };
      break;
    case 'new_order_message':
      bodyNotification = {
        variant: 'info',
        message: `${event.text}: New message in Order #${event.orderId}`,
      };
      break;
    case 'new_driver_message':
      bodyNotification = {
        variant: 'info',
        message: `${event.text}: New message in driver's chat #${
          event.driverName
        }`,
      };
      break;
    case 'carrier_notification_message':
      bodyNotification = {
        variant: 'info',
        message: `Notification Alert: ${event.eventMessage}`,
      };
      break;
    case 'invoice_complete_message':
      bodyNotification = {
        variant: 'info',
        message: `Notification Alert: ${event.eventMessage}`,
      };
      break;
    case 'invoice_failed_message':
      bodyNotification = {
        variant: 'warning',
        message: `Notification Alert: ${event.eventMessage}`,
      };
      break;
    case 'notarization_failed':
      queryClient.invalidateQueries({
        queryKey: ['orders', Number(event.orderId), 'notarizations'],
      });
      bodyNotification = {
        variant: 'warning',
        message: `Notification Alert: ${event.eventMessage}`,
      };
      break;
    case 'notarization_succeeded':
      queryClient.invalidateQueries({
        queryKey: ['orders', Number(event.orderId), 'notarizations'],
      });
      bodyNotification = {
        variant: 'success',
        message: `Notification Alert: ${event.eventMessage}`,
      };
      break;
    default:
      break;
  }

  if (event.type === 'new_order_message') {
    props.addNotification(bodyNotification.message, {
      variant: bodyNotification.variant,
      action: key => (
        <>
          <Button
            testSelector="events_open-msg_btn"
            theme="small"
            colour="white"
            onClick={() =>
              history.push(
                `/${window.location.pathname.split('/')[1]}/chat/${
                  event.driverId
                }`,
              )
            }>
            Open Message
          </Button>
          <Button
            testSelector="events_dismiss_btn"
            colour="white"
            theme="small"
            onClick={() => props.removeNotification(key)}>
            Dismiss
          </Button>
        </>
      ),
    });
  } else {
    props.addNotification(bodyNotification.message, {
      variant: bodyNotification.variant,
      action: key => (
        <Button
          testSelector="events_dismiss_btn"
          colour="white"
          theme="small"
          onClick={() => props.removeNotification(key)}>
          Dismiss
        </Button>
      ),
    });
  }
};

const eventsUpdate = props => (dispatch, getState) => {
  const request = () => ({ type: constants.EVENTS_UPDATE_REQUEST });
  const success = (events, lastEventId) => ({
    type: constants.EVENTS_UPDATE_REQUEST_SUCCESS,
    events,
    lastEventId,
  });
  const failure = error => ({
    type: constants.EVENTS_UPDATE_REQUEST_FAILURE,
    error,
  });

  dispatch(request());

  const state = getState();
  const { events } = state;
  const { lastEventId, eventsList } = events;

  eventsService
    .update(lastEventId)
    .then(response => {
      const updateEvents = response.data.data.events.reverse();
      let id = lastEventId;
      if (updateEvents.length > 0) {
        id = updateEvents[updateEvents.length - 1].id;
      }

      updateEvents.forEach(event => {
        eventsList.unshift(event);
        dispatch(notification(event, props));
      });

      dispatch(success(eventsList, id));
      dispatch(initUpdateInterval(props));
    })
    .catch(error => {
      dispatch(failure(error.message));
    });
};

const initUpdateInterval = props => (dispatch, getState) => {
  const state = getState();
  const { events } = state;
  const { updateInterval } = events;
  setTimeout(() => dispatch(eventsUpdate(props)), updateInterval);
};

const changeEventsBarStatus = () => dispatch => {
  const change = () => ({ type: constants.EVENTS_CHANGE_BAR_STATUS });
  dispatch(change());
};

const changeAccessToNotification = () => (dispatch, getState) => {
  const change = value => ({
    type: 'LOOKUP_EVENTS_CHANGE_ACCESS_NOTIFICATION',
    value,
  });
  const state = getState();
  const value = !state.lookUp.user.settings.allowNotifications ? 1 : 0;
  users
    .notification(value)
    .then(msg => {
      swal(msg.data.message, '', 'success');
      dispatch(change(value));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const changeActiveTab = tab => (dispatch, getState) => {
  const change = () => ({ type: constants.EVENTS_CHANGE_ACTIVE_TAB, tab });
  dispatch(change());
};

const newChatMessage = event => (dispatch, getState) => {
  const state = getState();
  const { events } = state;
  const { eventsList } = events;
  const change = events => ({ type: constants.EVENTS_CHAT_MESSAGE, events });
  eventsList.unshift(event);

  dispatch(notification(event));
  dispatch(change(eventsList));
};
const actions = {
  init,
  changeEventsBarStatus,
  changeAccessToNotification,
  changeActiveTab,
  newChatMessage,
};

export default actions;
