import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react';

import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const usePolygon = ({
  onClick,
  onDrag,
  onDragEnd,
  onDragStart,
  onMouseOver,
  onMouseOut,
  encodedPaths,
  ...polygonOptions
}) => {
  const callbacks = useRef((e) => ({}));
  callbacks.current = {
    ...callbacks.current,
    onClick,
    onDrag,
    onDragEnd,
    onDragStart,
    onMouseOver,
    onMouseOut
  }

  const geometryLibrary = useMapsLibrary('geometry');

  const polygon = useRef(new google.maps.Polygon(polygonOptions)).current;

  useMemo(() => {
    if (!polygon) return;

    polygon.setOptions(polygonOptions);
  }, [polygon, polygonOptions]);

  const map = useMap();

  useMemo(() => {
    if (!encodedPaths || !geometryLibrary) return;

    const paths = encodedPaths.map(path => geometryLibrary.encoding.decodePath(path));
    polygon.setPaths(paths);
  }, [polygon, encodedPaths, geometryLibrary]);

  useEffect(() => {
    if (!map) {
      return;
    }

    polygon.setMap(map);

    return () => {
      polygon.setMap(null);
    };
  });

  useEffect(() => {
    if (!polygon) return;

    const gme = google.maps.event;
    [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut']
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polygon, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polygon);
    };

  }, [polygon]);

  return polygon
}

const Polygon = forwardRef((props, ref) => {
  const polygon = usePolygon(props);
  useImperativeHandle(ref, () => polygon.current);
  return null;
});

export default Polygon;
