import React, { useMemo, useState, useEffect } from 'react';
import Modal, { ModalForm } from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import targetValue from 'utils/targetValue';
import { ratesList } from 'components/globalInfo/rateList';
import { useChangeDriverRating, useDriverRating } from 'api/v2/drivers';

const ChangeDriverRate = ({
  showModalDriverRate,
  closeRate,
  driver,
}) => {
  const [rating, setRating] = useState(ratesList.find(item => item.value === driver.rating));
  const [comment, setComment] = useState(driver.comment ?? '');
  const { data: rateData, isLoading } = useDriverRating({ driverId: driver.id });
  const { mutate: changeDriverRating, isPending: changeRatingPending } = useChangeDriverRating();

  useEffect(() => {
    if (rateData?.comment && rateData?.rating && !isLoading) {
      setRating(ratesList.find(item => item.value === rateData.rating));
      setComment(rateData.comment);
    }
  }, [rateData, isLoading]);

  const saveDisabled = useMemo(() =>
    !rating ||
    (!comment.trim().length) ||
    changeRatingPending,
    [rating, comment, changeRatingPending]
  );


  const handleSave = e => {
    e.preventDefault();
    changeDriverRating(
      {
        driverId: Number(driver.id),
        rating: rating.value,
        comment
      },
      {
        onSuccess: closeRate
      }
    );
  }

  const header = <h3>Edit Rate</h3>;
  const body = (
    <ModalForm>
      <Select
        label="Rating"
        onChange={e => setRating(e)}
        options={ratesList}
        required
        value={rating}
        testSelector="drivers_rate-modal_rating_select"
      />
      <Input
        onChange={e => setComment(targetValue(e))}
        value={comment}
        label="Comment"
        required
        testSelector="drivers_change-rate_comment_input"
      />
    </ModalForm>
  );
  const footer = (
    <>
      <Button
        onClick={closeRate}
        colour="white"
        testSelector="drivers-list_change-rate_close_btn">
        Close
      </Button>
      <Button
        onClick={handleSave}
        disabled={saveDisabled}
        testSelector="drivers-list_change-rate_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalDriverRate}
      onClose={closeRate}
    />
  );
};

export default ChangeDriverRate;
