import { combineReducers } from '@reduxjs/toolkit';

const initialStateDriverProfile = {
  isLoadedDriverProfile: false,
  driverInf: {},
};

const driver = (state = initialStateDriverProfile, action) => {
  switch (action.type) {
    case 'GET_DRIVER_PROFILE_REQUEST':
      return {
        ...state,
      };
    case 'GET_DRIVER_PROFILE_SUCCESS':
      return {
        ...state,
        isLoadedDriverProfile: true,
        driverInf: action.driverInf,
      };
    case 'GET_DRIVER_PROFILE_FAILURE':
      return {};
    case 'CLEAR_STORE_DRIVER_PROFILE':
      return initialStateDriverProfile;
    default:
      return state;
  }
};

const initialStateCertificates = {
  isLoadedDriverCertificates: false,
  certificates: [],
};

const certificates = (state = initialStateCertificates, action) => {
  switch (action.type) {
    case 'GET_DRIVER_CERTIFICATES_REQUEST':
      return {
        ...state,
      };
    case 'GET_DRIVER_CERTIFICATES_SUCCESS':
      return {
        ...state,
        isLoadedDriverCertificates: true,
        certificates: action.certificates,
      };
    case 'GET_DRIVER_CERTIFICATES_FAILURE':
      return {};
    case 'CLEAR_STORE_DRIVER_PROFILE':
      return initialStateCertificates;
    default:
      return state;
  }
};
const initState = {
  editInfoDriver: {
    districtId: '',
    selectedDistricts: [],
    homeDistrict: '',
    phone: '',
    email: '',
    equipment: '',
    carrierId: '',
    driverId: '',
    rating: '',
    comment: '',
    truck: '',
    trailer: '',
    containers: [],
    percentage: '',
    longitude: '',
    latitude: '',
    name: '',
  },
  chat: {
    chatId: '',
    driverId: '',
    key: '',
    messages: [],
    messagesReady: false,
    text: '',
    isFile: false,
    file: null,
    fileName: '',
  },
};

const editInfo = (state = initState, action) => {
  switch (action.type) {
    case 'SET_DRIVER_PROFILE_DATA':
      return {
        ...state,
        editInfoDriver: {
          districtId: action.data.district_id,
          homeDistrict: action.data.home_district,
          phone: action.data.contact_no,
          email: action.data.email,
          equipment: action.data.equipment,
          carrierId: action.data.carrier_id,
          driverId: action.data.id,
          truck: action.data.truck_no,
          trailer: action.data.trailer_no,
          containers: action.data.containers,
          rating: action.data.rating,
          percentage: action.data.pay_percentage,
          longitude: action.data.longitude,
          latitude: action.data.latitude,
          name: action.data.name,
          status: action.data.status,
        },
      };
    case 'MODAL_EDIT_DRIVER_VALUE':
      return {
        ...state,
        editInfoDriver: {
          ...state.editInfoDriver,
          [action.name]: action.value,
        },
      };
    case 'CLOSE_MODAL_EDIT_INFO':
      return initState;
    default:
      return state;
  }
};

const chat = (state = initState.chat, action) => {
  switch (action.type) {
    case 'DRIVER_DETAILS_INIT_CHAT':
      return {
        ...state,
        chatId: action.chatId,
        driverId: action.driverId,
      };
    case 'DRIVER_DETAILS_SAVE_LAST_KEY':
      return {
        ...state,
        key: action.key,
      };
    case 'DRIVER_DETAILS_SAVE_MESSAGGES':
      return {
        ...state,
        messages: action.messages,
        messagesReady: true,
      };
    case 'DRIVER_DETAILS_SEND_MESSAGGES':
      return {
        ...state,
        text: '',
        isFile: false,
        fileName: '',
        file: null,
      };
    case 'DRIVER_DETAILS_SET_CHAT_INPUT_VALUE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'DRIVER_DETAILS_SET_FILE':
      return {
        ...state,
        file: action.file,
        fileName: action.file.name,
        isFile: true,
      };
    case 'DRIVER_DETAILS_UNSET_FILE':
      return {
        ...state,
        isFile: false,
        fileName: '',
        file: null,
      };
    case 'CLEAR_STORE_DRIVER_PROFILE':
      return initState.chat;
    default:
      return state;
  }
};

const initDistricts = {
  carrierDistricts: [],
  selectedDistricts: [],
};

const districts = (state = initDistricts, action) => {
  switch (action.type) {
    case 'DRIVER_DETAILS_SET_CARRIER_DISTRICTS':
      return {
        ...state,
        carrierDistricts: action.districts,
        selectedDistricts: action.selectedDistricts,
      };
    case 'DRIVER_DETAILS_SELECT_DISTRICT':
      return {
        ...state,
        selectedDistricts: action.value,
      };
    case 'DRIVER_DETAILS_SET_DISTRICT_SUCCESS':
      return initDistricts;
    default:
      return state;
  }
};

export const driverProfile = combineReducers({
  driver,
  certificates,
  editInfo,
  chat,
  districts,
});
