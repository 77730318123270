import { client } from 'api/client';

export const getPurchaseOrdersByJob = async (jobId) => {
  const response = await client.get(`v2/jobs/${jobId}/po`, { jobId: Number(jobId) });
  return response.data.data.po;
}

export const getPurchaseOrders = async ({ customerId, jobId, sandSiteId, isActive }) => {
  const response = await client.get('v2/purchase-orders',
    { params: { customerId, jobId, sandSiteId, isActive } });
  return response.data.data.purchaseOrder;
}

export const addPurchaseOrder = async (data) =>
  client.post(`v2/job/${data.jobId}/purchase-order`, data.newPo);

export const updatePurchaseOrder = async (data) =>
  client.put(`v2/purchase-orders/${data.poId}`, data.newPo);
