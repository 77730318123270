import { useEffect, useMemo, useReducer } from 'react';
import { useCarriers } from 'api/v2/carriers';
import { useCustomerBillingProfiles, useCustomers } from 'api/v2/customers';
import { useDistricts } from 'api/v2/districts';
import { useEquipment } from 'api/v2/equipment';
import {
  useJobCertificates,
  useJobDetails,
  useJobSharedOperators,
  useTicketTypes,
} from 'api/v2/jobs';
import { useLocations } from 'api/v2/locations';
import { useTags } from 'api/v2/tags';
import { getLocationType } from 'helpers/locations';
import multiSelectOptions from 'utils/multiSelectOptions';
import targetValue from 'utils/targetValue';
import { cloneDeep } from 'lodash';
import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';

import { formatJobDetails } from './helpers';

export const useTagOptions = () => {
  const { data: jobTags } = useTags({ type: 2 });
  const tagOptions = useMemo(
    () =>
      jobTags.map(tag => ({
        ...tag,
        label: tag.name,
        value: tag.id,
      })),
    [jobTags],
  );
  return tagOptions;
};

export const useLocationOptions = type => {
  const { data: locations } = useLocations({ type: type ? [type] : undefined });
  const locationOptions = useMemo(
    () =>
      locations.map(item => ({
        value: item.id,
        label: `${item.name} (${getLocationType(item.locationType)})`,
      })),
    [locations],
  );
  return locationOptions;
};

export const useEquipmentOptions = () => {
  const { data: equipment } = useEquipment();
  const equipmentOptions = useMemo(
    () => multiSelectOptions(equipment, 'id', 'title'),
    [equipment],
  );
  return equipmentOptions;
};

export const useTicketOptions = () => {
  const { data: ticketTypes } = useTicketTypes();
  const ticketOptions = useMemo(
    () =>
      multiSelectOptions(
        ticketTypes.filter(t => t.sandTicketTypeId !== 6),
        'sandTicketTypeId',
        'name',
      ),
    [ticketTypes],
  );
  return ticketOptions;
};

export const useDistrictOptions = () => {
  const { data: districts } = useDistricts();
  const districtOptions = useMemo(
    () =>
      multiSelectOptions(
        districts.filter(t => t.sandTicketTypeId !== 6),
        'id',
        'name',
      ),
    [districts],
  );
  return districtOptions;
};

export const useCertificateOptions = ({ jobId }) => {
  const { data: certData } = useJobCertificates({ jobId });
  const certificateOptions = useMemo(() => {
    if (certData) {
      const certificates = [...certData.certOn, ...certData.certOff];
      return multiSelectOptions(certificates, 'id', 'name');
    }
    return [];
  }, [certData]);
  return certificateOptions;
};

export const useOperatorOptions = customerId => {
  const { data: carriers } = useCarriers();
  const { data: customers } = useCustomers();
  const operatorOptions = useMemo(
    () => [
      ...carriers.map(carrier => ({
        value: `${carrier.id}/3`,
        label: `${carrier.name} (Carrier)`,
      })),
      ...customers
        .map(customer => ({
          value: `${customer.id}/2`,
          label: `${customer.name} (Customer)`,
        }))
        .filter(
          ({ value }) =>
            Number(value.substring(0, value.indexOf('/'))) !== customerId,
        ),
    ],
    [carriers, customerId, customers],
  );
  return operatorOptions;
};

export const useCommodityOptions = jobDesign => {
  const { data: commodities } = useCommodities();
  const commodityOptions = useMemo(
    () =>
      commodities.map(commodity => {
        // Find if commodity is already used in job design
        const isDisabled = jobDesign?.find(
          jd => {
            if (jd.commodity) {
              return jd.commodity.id === commodity.id;
            }
            return jd.sandTypeId === commodity.id;
          }
        );
        return { ...commodity, value: commodity.id, label: commodity.name, isDisabled };
      }),
    [commodities, jobDesign],
  );
  return commodityOptions;
};

export const useBillingProfileOptions = customerId => {
  const { data: billingProfiles } = useCustomerBillingProfiles({ id: customerId });
  const billingProfileOptions = useMemo(
    () => multiSelectOptions(billingProfiles, 'id', 'name'),
    [billingProfiles],
  );
  return billingProfileOptions;
};

export const useJobEditDetails = ({ jobId }) => {
  const { data: jobDetails, isLoading } = useJobDetails({ jobId });
  const { data: sharedOperators } = useJobSharedOperators({ jobId });

  const data = useMemo(() => {
    if (!jobDetails) {
      return {};
    }

    const details = formatJobDetails(jobDetails);

    details.storageConfig = jobDetails.storage.map(item => {
      const storageItem = { ...item };
      storageItem.type = Number(item.storageTypeId);

      return storageItem;
    });

    details.additionalOperators = sharedOperators.map(op => ({
      ...op,
      value: `${op.id}/${op.entityType}`,
      label: op.name,
    }));
    details.sharedCreator = details.additionalOperators.find(op => op.sharedItemPermission === 5);

    return details;
  }, [jobDetails, sharedOperators]);

  return {
    data,
    isLoading,
  };
};

/**
 * @typedef {typeof initialEditJob} EditJob
 */

const initialEditJob = {
  name: '',
  customerJobId: '',
  navId: '',
  customerProjectId: '',
  startDate: '',
  endDate: '',
  pumpTime: '',
  wirelineTime: '',
  numberOfStages: '',
  certificates: [],
  equipment: [],
  districtIds: [],
  sandTicketDesign: [],
  billingProfileId: [],
  directions: '',
  stagingSite: '',
  wellSiteId: '',
  totalWeightPerJob: '',
  targetStagesPerDay: '',
  additionalOperators: [],
  tags: [],
  jobDesign: [{}],
  storageConfig: [],
  assignClosesOnly: false,
  useCommodityPrice: false,
  alertsEnabled: false,
  messagesAllowed: false,
  isTestJob: false,
  phoneNumbers: [],
  minDriversAmount: '',
  demurrageAlert: '',
};

/**
 *
 * @param {EditJob} state
 * @returns {EditJob}
 */
const editJobReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'set_array_value': {
      const newArr = cloneDeep(state[action.arrayName]);

      if (!newArr[action.index]) {
        newArr[action.index] = {};
      }

      if (action.name === 'storage_id') {
        newArr[action.index][action.name] = action.value.trim();
      } else {
        newArr[action.index][action.name] = action.value;
      }

      return {
        ...state,
        [action.arrayName]: newArr,
      };
    }
    case 'add_array_value': {
      const newArr = [
        ...cloneDeep(state[action.arrayName]),
        action.content || {},
      ];
      return {
        ...state,
        [action.arrayName]: newArr,
      };
    }
    case 'delete_array_value': {
      const newArr = cloneDeep(state[action.arrayName]);
      newArr.splice(action.index, 1);
      return {
        ...state,
        [action.arrayName]: newArr,
      };
    }
    case 'clear_state':
      return initialEditJob;
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   valueChange: (name: string) => (e: any) => {};
 *   arrayValueChange: (index: number, name: string, arrayName: string) => (e: any) => {};
 *   arrayValueAdd: (arrayName: string, content: any) => () => {};
 *   arrayValueDelete: (index: number, arrayName: string) => () => {};
 *   clear: () => {};
 * }} EditJobHandlers
 */

/**
 * @typedef {{
 *   data: EditJob;
 *   isValid: boolean;
 *   handlers: EditJobHandlers;
 * }} EditJobFormReturn
 */

/**
 *
 * @param {{ defaultValue: any; showModal: boolean; }} params
 * @returns {EditJobFormReturn}
 */
export const useEditJobForm = ({ defaultValue, showModal }) => {
  /** @type {[EditJob, () => {}]} */
  const [data, dispatch] = useReducer(editJobReducer, initialEditJob);
  const { data: commodities, isLoading: customerIsLoading } = useCustomerCommodities(defaultValue?.customerId);

  useEffect(() => {
    if (defaultValue && showModal) {
      dispatch({
        type: 'init',
        data: {
          ...defaultValue,
          jobDesign: (defaultValue?.jobDesign ?? []).map(item => {
            const commodity = (commodities || []).find(c => c.id === item.sandTypeId);

            return {
              ...item,
              commodity: {
                ...commodity,
                value: commodity?.id || '',
                label: commodity?.name || '',
              },
            }
          })
        }
      });
    }
  }, [defaultValue, showModal, commodities]);

  const isValid = useMemo(
    () =>
      data.name?.trim()?.length &&
      data.startDate &&
      new Date(data.endDate).getTime() > new Date(data.startDate).getTime() &&
      (!data.messagesAllowed ||
        (data.messagesAllowed && data.phoneNumbers?.length > 0)) &&
      data?.pumpTime &&
      data?.wirelineTime &&
      data?.numberOfStages,
    [data],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e),
        }),
      arrayValueChange: (index, name, arrayName) => e =>
        dispatch({
          type: 'set_array_value',
          index,
          name,
          arrayName,
          value: targetValue(e),
        }),
      arrayValueAdd: (arrayName, content) => () =>
        dispatch({
          type: 'add_array_value',
          arrayName,
          content,
        }),
      arrayValueDelete: (index, arrayName) => () =>
        dispatch({
          type: 'delete_array_value',
          index,
          arrayName,
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
