import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/Modal';
import Select from 'components/shared/Select';
import { Textarea } from 'components/shared/Input';
import Button from 'components/shared/Button';
import { connect } from 'react-redux';
import { contestOrder, getDisputeReasons, jobPlansContestOrder } from 'store/actions/Orders';
import PropTypes from 'prop-types';

const ContestModal = ({
  open,
  onClose,
  contestOrderFunc,
  jobPlansContestOrderFunc,
  orderIds,
  getDisputeReasonsFunc,
  updateInit,
  jobPlansdata,
}) => {
  const [comment, setComment] = useState('');
  const [currentReason, setCurrentReason] = useState(null);
  const [disputeReasons, setDisputeReasons] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const determineAddDisabled = () => {
    let disabled = false;
    if (!currentReason || !comment?.trim().length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [currentReason, comment]);
  
  const handleDispute = () => {
    if(jobPlansdata){
      jobPlansContestOrderFunc(orderIds, comment, currentReason, jobPlansdata);
    } else {
      contestOrderFunc(orderIds, comment, currentReason, updateInit);
    }
    onClose();
  };

  const clearState = () => {
    setComment('');
    setCurrentReason(null);
  };

  useEffect(() => {
    if (open) {
      getDisputeReasonsFunc().then(item => {
        setDisputeReasons(item.data.data.disputeReasons);
      });
    }
  }, [open]);

  const disputeOptions = disputeReasons.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const body = (
    <div className="contest-modal">
      <Select
        onChange={item => setCurrentReason(item.value)}
        options={disputeOptions}
        placeholder="Add reason"
        required
        testSelector="contest-modal_add-reason_select"
      />
      <Textarea
        label="Add Comment"
        name="Add Comment"
        onChange={e => setComment(e.target.value)}
        value={comment}
        required
      />
    </div>
  );

  const footer = (
    <>
      <Button
        colour="white"
        onClick={() => {
          onClose();
          clearState();
        }}
        testSelector="contest-modal_close_btn">
        Close
      </Button>
      <Button
        onClick={handleDispute}
        disabled={saveDisabled}
        testSelector="contest-modal_save_btn">
        Save
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Contest order for Billing"
      body={body}
      footer={footer}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  contestOrderFunc: (orderIds, comments, disputeReasonId, updateInit) =>
    dispatch(contestOrder(orderIds, comments, disputeReasonId, updateInit)),
  jobPlansContestOrderFunc: (orderIds, comments, disputeReasonId, jobPlansdata) =>
    dispatch(jobPlansContestOrder(orderIds, comments, disputeReasonId, jobPlansdata)),
  getDisputeReasonsFunc: () => dispatch(getDisputeReasons()),
});

ContestModal.propTypes = {
  /** Boolean if contested */
  open: PropTypes.bool,
  /** Function return setContestModal(false) to close modal */
  onClose: PropTypes.func,
  /** Function contestOrderFunc(orderIds, comments, disputeReasonId),return Object */
  contestOrderFunc: PropTypes.func,
  /** Array of orderIds */
  orderIds: PropTypes.array,
  /** getDisputeReasonsFunc get dispute reasons from redux store */
  getDisputeReasonsFunc: PropTypes.func,
};

export default connect(
  null,
  mapDispatchToProps,
)(ContestModal);
