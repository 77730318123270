import { useMemo, useReducer } from 'react';
import targetValue from 'utils/targetValue';

/**
 * @typedef {{
 *  sandSiteReason?: number;
 *  wellsiteReason?: number;
 * }} DemurrageReason
 */

const initialDemurrageReason = {};

/**
 *
 * @param {DemurrageReason} state
 * @returns {DemurrageReason}
 */
const DemurrageReasonReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialDemurrageReason;
    default:
      return state;
  }
};

export const useDemurrageReasonForm = ({ demurrageData }) => {
  /** @type {[DemurrageReason, () => {}]} */
  const [data, dispatch] = useReducer(
    DemurrageReasonReducer,
    initialDemurrageReason,
  );

  const isValid = useMemo(
    () => {
      if (!demurrageData) {
        return true;
      }

      const originRequired = Number(demurrageData.sandDemurrage.price) !== 0 && !demurrageData.sandSiteDemurrageReasonId;
      const destinationRequired = Number(demurrageData.wellDemurrage.price) !== 0 && !demurrageData.wellSiteDemurrageReasonId;

      return (originRequired ? data.sandSiteReason : true) &&
      (destinationRequired ? data.wellsiteReason : true)
    },
    [data, demurrageData],
  );

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    isValid,
    handlers,
  };
};
