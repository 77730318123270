import { client } from 'api/client';

export const getDemurrageReasons = async () => {
  const { data } = await client.get(`v2/job-manager/demurrage-reason`);
  return data.data.demurageReasons;
};

/**
 * 
 * @param {{
 *  orderId: number;
 *  reason: any;
 * }} params
 * @returns 
 */
export const saveDemurrageReason = async ({ orderId, reason }) => {
  const { data } = await client.put(
    `v2/order/${orderId}/demurrage-reason`,
    reason,
  );
  return data.data;
};
