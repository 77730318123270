import { useOrder } from 'api/v2/orders';
import { useEffect, useMemo, useReducer, useState } from 'react';
import targetValue from 'utils/targetValue';

export const useOrderTicket = (orderId, selectedTicket) => {
  const { data: orderDetails } = useOrder({ orderId });
  const ticket = useMemo(() => {
    const sandTicketIds = orderDetails?.sandTicketIds;
    if (!sandTicketIds?.length || !selectedTicket) {
      return {};
    }

    const foundTicket = sandTicketIds.find(t => t.id === selectedTicket.id);
    return {
      id: foundTicket.id,
      ticketType: foundTicket.sand_ticket_type,
      ticketNumber: foundTicket.ticket_number,
    };
  }, [orderDetails, selectedTicket]);

  return ticket;
};

/**
 * @typedef {{
 *   id: number;
 *   ticketNumber: string;
 *   ticketType: number;
 * }} EditTicket
 */

const initialEditTicket = {
  modify: false,
  uploadTicket: false,
  divert: false,
  transfer: false,
  requeue: false,
};

/**
 *
 * @param {EditTicket} state
 * @returns {EditTicket}
 */
const EditTicketReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.data;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'clear_state':
      return initialEditTicket;
    default:
      return state;
  }
};

export const useEditTicketForm = ({ showModal, defaultValue }) => {
  /** @type {[EditTicket, () => {}]} */
  const [data, dispatch] = useReducer(EditTicketReducer, initialEditTicket);

  useEffect(() => {
    if (defaultValue && showModal) {
      dispatch({ type: 'init', data: defaultValue });
    }
  }, [defaultValue, showModal]);

  const isValid = useMemo(() => data.ticketType && data.ticketNumber?.trim()?.length, [data]);

  const handlers = useMemo(
    () => ({
      valueChange: name => e =>
        dispatch({
          type: 'set_value',
          name,
          value: targetValue(e.value ? e.value : e),
        }),
      clear: () => dispatch({ type: 'clear_state' }),
    }),
    [],
  );

  return {
    data,
    handlers,
    isValid
  };
};

export const useDemurrageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();

  const toggleModal = demurrageData => {
    setData(demurrageData);
    setShowModal(value => !value);
  };

  return {
    data,
    show: showModal,
    toggle: toggleModal,
  };
};
