import React, { useState } from 'react';
import Link from 'components/common/Link';
import { locationPermission } from 'components/globalInfo/locationPermission';
import DriverGridCard from 'components/shared/DriverGridCard';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import DropdownButton from 'components/shared/DropdownButton';
import { authorize } from 'components/common/Authorize';
import { deleteAlert, confirmAlert } from 'components/sweetalert';
import ChangeDistrict from 'components/views/DriversList/ChangeDistrictModal';
import ChangePercentage from 'components/views/DriversList/ChangePercentageModal';
import ChangeDriverRate from 'components/views/DriversList/ChangeDriverRateModal';
import { useEquipment } from 'api/v2/equipment';
import {
  useDeleteDriver,
  useRestoreDriver,
  useFreeDriver,
  useLogOutDriver,
} from 'api/v2/drivers';
import noPhoto from '../../../../../public/img/no_avatar.jpg';

const Driver = ({
  data,
}) => {
  const [showModalDistrict, openModalDistrict] = useState(false);
  const [showModalRate, openModalRate] = useState(false);
  const [showModalPercentage, openModalPercentage] = useState(false);
  const { data: equipmentRequired } = useEquipment();

  const { mutate: deleteDriver } = useDeleteDriver();
  const { mutate: restoreDriver } = useRestoreDriver();
  const { mutate: freeDriver } = useFreeDriver();
  const { mutate: logOutDriver } = useLogOutDriver();

  const getStatus = () => {
    if (data.is_logged_in === 0) {
      return (
        <ToolTip title="Logged out" placement="top">
          <Icon icon="logged_out" data-testid="logged_out_user" />
        </ToolTip>
      );
    }

    if (data.online_status === 1) {
      return (
        <ToolTip title="Online" placement="top">
          <Icon icon="true" data-testid="online_user" />
        </ToolTip>
      );
    }

    return (
      <ToolTip title="Offline" placement="top">
        <Icon icon="false" data-testid="offline_user" />
      </ToolTip>
    );
  };

  const status = getStatus();
  const formatOsVersion = osVersion => {
    if (!osVersion || typeof osVersion !== 'string') {
      return 'Unknown';
    }

    if (osVersion.split(' ').length < 2) {
      return 'Unknown';
    }

    return osVersion.split(' ')[1].replaceAll('"', '');
  };

  const titleBody = () => {
    const personalInfoReadPermissionCheck = authorize({
      abilityPermissions: [
        {
          resource: 'Personal-info',
          permissions: ['read'],
        },
      ],
    });
    if (!personalInfoReadPermissionCheck) {
      return `${data.carrierName} - ${data.truckNumber}`;
    }
    const driverReadPermissionCheck = authorize({
      abilityPermissions: [
        {
          resource: 'Drivers',
          permissions: ['read'],
        },
      ],
    });
    if (!driverReadPermissionCheck) {
      return `#${data.id} | ${data.name}`;
    }
    return (
      <>
        {` #`}
        {data.is_deleted ? (
          data.id || 0
        ) : (
          <Link to="driver-details" id={data.id || 0}>
            {data.id || 0}
          </Link>
        )}
        {` | ${data.name}`}
      </>
    );
  };

  const title = <strong className="text-white">{titleBody()}</strong>;

  const subTitle = authorize({
    abilityPermissions: [
      {
        resource: 'Personal-info',
        permissions: ['read'],
      },
    ],
  }) &&
    data.email && (
      <>
        <Icon icon="email" />
        <strong className="text-white" style={{ wordBreak: 'break-all' }}>
          {' '}
          {data.email || 'Unknown'}
        </strong>
      </>
    );

  const badge = (
    <>
      <span className="m-r m-l-sm">
        {data.status === 0 && (
          <ToolTip title="Free" placement="top">
            <Icon icon="free_driver" />
          </ToolTip>
        )}
        {data.status === 1 && (
          <ToolTip title="Busy" placement="top">
            <Icon icon="busy_driver" />
          </ToolTip>
        )}
      </span>
      <ToolTip
        title={locationPermission[data.location_permission].text}
        placement="top">
        <Icon icon={locationPermission[data.location_permission].icon} />
      </ToolTip>
    </>
  );

  const actionOptions = [
    {
      name: 'district',
      label: 'Edit district',
      onClick: () => {
        openModalDistrict(true);
      },
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    },
    {
      name: 'rate',
      label: 'Edit driver rating',
      onClick: () => {
        openModalRate(true);
      },
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    },
    {
      name: 'percentage',
      label: 'Edit pay percentage',
      onClick: () => {
        openModalPercentage(true);
      },
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    },
  ];
  if (data.is_logged_in && data.status === 0) {
    actionOptions.push({
      name: 'logout',
      label: 'Logout driver',
      onClick: () =>
        deleteAlert(id => logOutDriver({ driverId: Number(id) }), data.id, null, null, {
          confirmText: 'Yes, logout!',
          text: 'This will log the driver out of the app.',
        }),
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    });
  }
  if (!data.is_deleted) {
    actionOptions.push({
      name: 'delete',
      label: 'Delete driver',
      onClick: () => deleteAlert(id => deleteDriver({ driverId: Number(id) }), data.id),
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['delete'],
            },
          ],
        },
      },
    });
  } else {
    actionOptions.push({
      name: 'restore',
      label: 'Restore driver',
      onClick: () =>
        confirmAlert(id => restoreDriver({ driverId: Number(id) }), data.id, {
          confirmText: 'Yes, restore driver!',
          text: 'This will restore the driver to an active status.',
        }),
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    });
  }
  if (!!data.status && !data.is_deleted) {
    actionOptions.push({
      name: 'free',
      label: 'Free driver',
      onClick: () =>
        confirmAlert(id => freeDriver({ driverId: id }), data.id, {
          confirmText: 'Yes, free driver!',
          text: 'This will set the driver to a status of free.',
        }),
      permissions: {
        ...{
          abilityPermissions: [
            {
              resource: 'Drivers',
              permissions: ['update'],
            },
          ],
        },
      },
    });
  }

  const actions = (
    <DropdownButton
      theme="small"
      className="button--small--square"
      icon="edit"
      options={actionOptions}
    />
  );

  const bodyDistrictDetails = () => {
    if (!data.district_detail) {
      return 'Unknown';
    }
    if (data.district_detail.length && data.district_detail.length < 30) {
      return data.district_detail;
    }
    return `${data.district_detail.slice(0, 28)}...`;
  };

  const body = (
    <>
      <div>
        <div className="m-b-sm">
          <strong className="text-white">
            {authorize({
              abilityPermissions: [
                {
                  resource: 'Personal-info',
                  permissions: ['read'],
                },
              ],
            }) &&
              data.contact_no && (
                <>
                  <Icon icon="mobile" /> {data.contact_no || 'Unknown'}
                </>
              )}
          </strong>
        </div>
        <div className="m-b-sm">
          <Icon icon="district" />
          <strong className="text-white">{bodyDistrictDetails()}</strong>
        </div>
        <div className="m-b-sm">
          <span>Carrier #{data.carrier_id}: </span>
          <strong className="text-white">{data.carrier_name || '-'}</strong>
        </div>
        <div className="m-b-sm">
          <span>Equipment: </span>
          <strong className="text-white">
            {data.equipment
              ? equipmentRequired.find(item => item.id === data.equipment)?.title
              : '-'}
          </strong>
        </div>
      </div>
      <div>
        <div className="m-b-sm">
          {data.deviceType ? <Icon icon="ios" /> : <Icon icon="android" />}
          <strong className="text-white">
            {formatOsVersion(data.osVersion)}
          </strong>
        </div>
        <div className="m-b-sm">
          <Icon icon="mobile" />
          <strong className="text-white">
            {!data.deviceVersion || data.deviceVersion === 'unknown'
              ? 'Unknown'
              : data.deviceVersion}
          </strong>
        </div>

        <div className="m-b-sm">
          <Icon icon="star" />
          <strong className="text-white">{data.rating}</strong>
        </div>
        <div className="m-b-sm">
          <span>Truck: </span>
          <strong className="text-white">{data.truck_no || '-'}</strong>
        </div>
      </div>
    </>
  );

  const toggleBody = (
    <>
      <div className="m-b">
        <div>
          <span>Trailer: </span>
          <strong className="text-white">{data.trailer_no || '-'}</strong>
        </div>
      </div>
      <div>
        <div>
          <span>Pay percentage: </span>
          <strong className="text-white">{data.pay_percentage} %</strong>
        </div>
        <div>
          <span>Accepted orders: </span>
          <strong className="text-white">{data.accepted_jobs_count}</strong>
        </div>
        <div>
          <span>Rejected orders: </span>
          <strong className="text-white">{data.rejected_jobs_count}</strong>
        </div>
      </div>
    </>
  );

  return (
    <>
      {showModalDistrict && (
        <ChangeDistrict
          {...{
            showModalDistrict,
          }}
          driver={data}
          closeDistrict={() => {
            openModalDistrict(false);
          }}
        />
      )}
      {showModalRate && (
        <ChangeDriverRate
          showModalDriverRate={showModalRate}
          driver={data}
          closeRate={() => {
            openModalRate(false);
          }}
        />
      )}
      {showModalPercentage && (
        <ChangePercentage
          showModalPercentage={showModalPercentage}
          driver={data}
          closePercentage={() => {
            openModalPercentage(false);
          }}
        />
      )}
      <div className="scrollNone">
        <DriverGridCard
          id={data.id}
          icon={data.profile_photo ? data.profile_photo : noPhoto}
          title={title}
          badge={badge}
          body={body}
          bodyColumns={2}
          status={status}
          subTitle={subTitle}
          actions={
            authorize({
              abilityPermissions: [
                {
                  resource: 'Drivers',
                  permissions: ['update'],
                },
              ],
            })
              ? actions
              : null
          }
          toggle
          toggleBody={toggleBody}
        />
      </div>
    </>
  );
};

export default Driver;
