import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { equipmentTypes } from 'components/globalInfo/equipment';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { useEditDriverTruck } from 'api/v2/drivers';
import { useContainerOptions } from './EditDriver/hooks';
import { formattedDriverContainers } from './EditDriver/helpers';

const EditTruck = ({
  driverDistricts,
  showEditTruck,
  closeEditTruck,
  driverInf,
  requiredEquipment,
}) => {
  const [
    { equipmentType,
      truckNumber,
      trailerNumber,
      containers },
    setEditData] = useState({
      equipmentType: driverInf?.equipment ?? null,
      truckNumber: driverInf?.truck_no ?? '',
      trailerNumber: driverInf?.trailer_no ?? '',
      containers: driverInf?.containers ?? [],
    });

  const setModalValue = (e, name) => {
    setEditData(prevState => ({ ...prevState, [name]: targetValue(e) }));
  }
  const { mutate: changeDriverTruck, isPending: isChangeDriverTruckPending } = useEditDriverTruck({ id: Number(driverInf.id) });

  const handleSubmit = e => {
    e.preventDefault();

    changeDriverTruck(
      {
        driverId: Number(driverInf.id),
        equipmentType,
        truckNumber,
        trailerNumber,
        containers: containers.map(c => c.value)
      },
      {
        onSuccess: closeEditTruck
      }
    );
  }

  const equipmentOptions = requiredEquipment.map(item => ({
    value: item.id,
    label: item.title,
  }));
  const districtIds = driverDistricts?.length
    ? driverDistricts.map(d => d.id)
    : [];
  const containerOptions = useContainerOptions(driverInf.id, districtIds);

  const saveDisabled = useMemo(() => (
    !truckNumber?.trim().length ||
    !trailerNumber?.trim().length ||
    !equipmentType ||
    isChangeDriverTruckPending
  ), [truckNumber, trailerNumber, equipmentType, isChangeDriverTruckPending]);

  useEffect(() => {
    setModalValue(formattedDriverContainers(containers), 'containers');
  }, []);

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showEditTruck}
      onHide={closeEditTruck}>
      <form role="form" onSubmit={handleSubmit}>
        <Modal.Header>
          <h3>Edit Truck Information</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setModalValue(e, 'truckNumber')}
                value={truckNumber}
                label="Truck number"
                required
                testSelector="driver-details_edit-truck_truck-number_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setModalValue(e, 'trailerNumber')}
                value={trailerNumber}
                label="Trailer number"
                required
                testSelector="driver-details_edit-truck_trailer-number_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Please Select Equipment"
                onChange={item => {
                  setModalValue(item.value, 'equipmentType');
                  if (item.value !== equipmentTypes.Containerized) {
                    setModalValue([], 'containers');
                  }
                }}
                options={equipmentOptions}
                value={equipmentOptions.find(
                  equipmentOption => equipmentOption.value === equipmentType,
                )}
                required
                testSelector="driver-details_cedit-truck_equipment_select"
              />
            </div>
            {equipmentType === equipmentTypes.Containerized && (
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  placeholder="Container"
                  onChange={item => setModalValue(item, 'containers')}
                  value={containers}
                  closeMenuOnSelect={false}
                  options={containerOptions}
                  testSelector="driver-details_edit_containers_select"
                />
              </div>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeEditTruck}
            colour="white"
            testSelector="driver-details_edit-truck_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="driver-details_edit-truck_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditTruck;
