import React, { useState } from 'react';

import Authorize from 'components/common/Authorize';
import Page from 'components/shared/Page';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import DriverListBlock from 'components/views/DriversList/DriverListBlock';
import DeviceStatistics from 'components/views/DriversList/DevicesStats';
import DriverStatistics from 'components/views/DriversList/DriverStats';
import AddDriver from 'components/views/DriversList/AddDriverModal';
import SendNotifications from 'components/views/DriversList/SendNotificationsModal';

import { usePaginatedDrivers } from 'api/v2/drivers';
import { useQueryFilters, useDriverFilters } from './hooks';

const DriverList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  // Pagination, Results per page,
  const [filters, { setFilter, setPage }] = useDriverFilters();
  const queryFilters = useQueryFilters(filters);

  const { data: driverData, isLoading, refetch } = usePaginatedDrivers({
    ...queryFilters,
    page: filters.page,
    pageSize: filters.pageLimit,
  });

  const driverActions = (
    <>
      <Authorize
        abilityPermissions={[{ resource: 'Drivers', permissions: ['create'] }]}>
        <Button
          key="add-driver"
          inverse
          testSelector="drivers-list_add-driver_btn"
          onClick={() => {
            setOpenAdd(true);
          }}>
          <Icon className="icon--margin-right" icon="plus-square" />
          Add Driver
        </Button>
      </Authorize>
      <Authorize
        abilityPermissions={[{ resource: 'Drivers', permissions: ['update'] }]}>
        <Button
          key="send-notification"
          inverse
          testSelector="drivers-list_send-notification_btn"
          onClick={() => setOpenNotifications(true)}>
          Send Notification
        </Button>
      </Authorize>
      <Button onClick={refetch} testSelector="drivers-list_refresh_btn">
        <Icon icon="refresh" />
      </Button>
    </>
  );

  return (
    <>
      <Page
        title="Driver List"
        className="driver-list-wrapper"
        actions={driverActions}>
        {!driverData?.drivers?.length ? (
          <div className="ibox float-e-margins" style={{ marginTop: 10 }}>
            <div className="ibox-content text-center">
              <h3>No drivers available</h3>
            </div>
          </div>
        ) : (
          <div className="driver-list-wrapper__stats-block">
            <DriverStatistics drivers={driverData.drivers} />
            <DeviceStatistics drivers={driverData.drivers} />
          </div>
        )}
        <DriverListBlock
          totalPages={Math.ceil((driverData?.size ?? 0) / filters.pageLimit)}
          drivers={driverData?.drivers ?? []}
          filters={filters}
          apiIsLoading={isLoading}
          setFilter={setFilter}
          setPagination={setPage}
          pagination={{ currentPage: filters.page }}
          pageLimit={filters.pageLimit}
          setPageLimit={limit => setFilter(limit, 'pageLimit')}
        />
      </Page>

      {openAdd && (
        <AddDriver
          showModalAddDriver={openAdd}
          closeAddDriver={() => setOpenAdd(false)}
        />
      )}
      {openNotifications && (
        <SendNotifications
          showModalSendNotifications={openNotifications}
          closeSendNotifications={() => {
            setOpenNotifications(false);
          }}
        />
      )}
    </>
  );
};

export default DriverList;
