import { client } from 'api/client';

/**
 *
 * @param {number} orderId
 * @returns
 */
export const getOrderTicketTypes = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/sand-ticket-types`);
  const types = data.data.sandTicketTypes;
  return types;
};

/**
 *
 * @param {number} orderId
 * @returns {Promise<{ tickets: any[], config: any[] }>}
 */
export const getOrderTickets = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/tickets`);
  const tickets = data.data?.tickets || [];
  const config = data.data?.config || [];
  const filteredTickets = tickets.filter(ot => !ot.isDeleted);
  const formattedTickets = {
    tickets: filteredTickets,
    config,
  };

  return formattedTickets;
};

/**
 * @param {{ orderId: number; ticket: FormData }}
 */
export const uploadOrderTicket = async ({ orderId, ticket }) => {
  const { data } = await client.post(
    `v2/order/${orderId}/sand-ticket`,
    ticket,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
  return data.data;
};

/**
 *
 * @param {{
 *  orderId: number;
 *  ticketId: number;
 *  ticket: any;
 * }} param0
 * @returns
 */
export const editOrderTicket = async ({ orderId, ticketId, ticket }) => {
  const { data } = await client.put(
    `v2/job-manager/orders/${orderId}/sand-tickets/${ticketId}`,
    ticket,
  );
  return data.data;
};
