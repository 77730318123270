export const equipmentTypes = {
  'Pneumatic': 1,
  'Bottom Drop': 2,
  'Containerized': 3,
  'Water Tank': 4,
  'Production Water Tank': 5,
  'Slick': 6,
  'Winch': 7,
  'Flatbed': 8,
  'Other': 9,
  'Iso-Tank': 10,
  'Power Only': 11,
  'Tanker Truck': 12,
  'Vacuum Truck': 13,
  'Acid Tank': 14,
}