import React from 'react';
import { convertTimeWithSeconds } from 'utils/convertTime';
import orderDetailsModel from 'models/OrderDetails';
import { timelineActual as timelineActualFunction } from 'components/globalInfo/timeline';
import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';
import { useOrder, useOrderContainers } from 'api/v2/orders';
import Loader from 'components/shared/Loader';

const OrderTimeline = ({ orderId }) => {
  const { data: order, isLoading: loadingOrders } = useOrder({ orderId });
  const { data: containers, isLoading: loadingContainers } = useOrderContainers(
    { orderId },
  );

  const { loadingTimes } = orderDetailsModel;

  if (loadingOrders || loadingContainers) {
    return <Loader />;
  }

  const loadUnloadTimes = loadingTimes(order?.info);
  const timelineActual = timelineActualFunction(order?.info);

  return (
    <div className="row">
      <div className="col-md-5">
        <p className="timeline-title">
          <b>Expected timeline</b>
        </p>
        <div
          id="vertical-timeline"
          className="vertical-container dark-timeline">
          <div className="vertical-timeline-block">
            <div className="vertical-timeline-icon lazur-bg">
              <Icon icon="flag" />
            </div>
            <div className="vertical-timeline-content">
              <span>
                <b>Expected pickup time</b>
              </span>
              <span className="vertical-date">
                <small>
                  {convertTimeWithSeconds(order.info.expected_pickup_time) ||
                    'unknown'}
                </small>
              </span>
            </div>
          </div>
          {order?.info?.status !== 4 && (
            <div className="vertical-timeline-block">
              <div className="vertical-timeline-icon lazur-bg">
                <Icon icon="truck" />
              </div>
              <div className="vertical-timeline-content">
                <span>
                  <b>Expected time arrived</b>
                </span>
                <span className="vertical-date">
                  <small>
                    {convertTimeWithSeconds(order?.info?.eta) || 'unknown'}
                  </small>
                </span>
              </div>
            </div>
          )}
          <div className="vertical-timeline-block">
            <div className="vertical-timeline-icon lazur-bg">
              <Icon icon="check" />
            </div>
            <div className="vertical-timeline-content">
              <span>
                <b>Unload appt</b>
              </span>
              <span className="vertical-date">
                <small>
                  {convertTimeWithSeconds(order?.info?.unload_appt) || 'unknown'}
                </small>
              </span>
            </div>
          </div>
        </div>
        {!!containers?.length && (
          <span>
            <p className="timeline-title">
              <b>Containers</b>
            </p>
            {containers.map(item => (
              <div
                className="vertical-container dark-timeline"
                style={{
                  position: 'relative',
                  padding: 0,
                  marginTop: '2em',
                  marginBottom: '2em',
                }}
                key={`container-${item.id}`}>
                <div
                  className={`vertical-timeline-icon ${item.status === 1 &&
                    'lazur-bg'} ${item.status === 1 && 'yellow-bg'}`}>
                  <Icon icon="th-large" />
                </div>
                <div className="vertical-timeline-content">
                  <span>
                    <b>Number: {item.containerNumber}</b>
                  </span>
                  <span className="vertical-date">
                    <small>ID: {item.id}</small>
                    <br />
                    {item.status === 3 && <small>Empty</small>}
                  </span>
                </div>
              </div>
            ))}
          </span>
        )}
        {loadUnloadTimes && (
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="vertical-timeline-content">
                <span>
                  <b>Loading time:</b>
                </span>
                <span className="vertical-date">
                  <small>
                    <strong>{loadUnloadTimes.loading} min</strong>
                  </small>
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="vertical-timeline-content">
                <span>
                  <b>Unloading time:</b>
                </span>

                <span className="vertical-date">
                  <small>
                    <strong>{loadUnloadTimes.unloading} min</strong>
                  </small>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-7">
        <p className="timeline-title">
          <b>Actual timeline</b>
        </p>
        <div
          id="vertical-timeline2"
          className="vertical-container dark-timeline">
          {timelineActual.map((item, index) => (
            <div key={index} className="vertical-timeline-block">
              {item.url && (
                <div>
                  <div className="lightBoxGallery">
                    <a
                      href={item.url}
                      title="Ticket"
                      data-gallery=""
                      style={{ color: 'inherit' }}>
                      <div className="vertical-timeline-icon navy-bg">
                        <Icon icon={`${item.icon}`} />
                      </div>
                    </a>
                    <div className="vertical-timeline-content">
                      <span>
                        <b>{item.title}</b>
                      </span>
                      <br />
                      <span className="vertical-date">
                        <small>{item.date ? item.date : 'unknown'}</small>
                      </span>
                    </div>
                  </div>
                  <div id="blueimp-gallery" className="blueimp-gallery">
                    <div className="slides" />
                    <h3 className="title" />
                    <a className="prev">‹</a>
                    <a className="next">›</a>
                    <a className="close">×</a>
                    <a className="play-pause" />
                    <ol className="indicator" />
                  </div>
                </div>
              )}
              {!item.url && (
                <div>
                  <div
                    className={
                      item.isAlerted
                        ? 'vertical-timeline-icon red-bg'
                        : 'vertical-timeline-icon navy-bg'
                    }>
                    {item.isAlerted ? (
                      <ToolTip title={item.alternateTitle} placement="top">
                        <Icon icon={`${item.icon}`} />
                      </ToolTip>
                    ) : (
                      <Icon icon={`${item.icon}`} />
                    )}
                  </div>
                  <div className="vertical-timeline-content">
                    <span>
                      <b>{item.title}</b>
                    </span>
                    <br />
                    <span className="vertical-date">
                      <small>{item.date || 'unknown'}</small>
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OrderTimeline;
