import { driver } from 'services/driver/service';
import { carrier } from 'services/carrier/service';
import swal from 'bootstrap-sweetalert';
import { dynamicSort } from 'utils/dynamicSort';
import { lookUpActions } from 'store/actions/LookUp';

const initSortedDrivers = drivers => dispatch => {
  const success = (data, ios, android) => ({
    type: 'DRIVERS_REQUEST_SUCCEEDED',
    drivers: data,
    ios,
    android,
  });

  const iosArray = drivers
    .filter(i => Number(i.deviceType) === 1)
    .sort(dynamicSort('-deviceVersion'));
  const iosVersions = Array.from(
    new Set(iosArray.map(item => item.deviceVersion).filter(e => e)),
  );
  const androidArray = drivers
    .filter(i => Number(i.deviceType) === 0)
    .sort(dynamicSort('-deviceVersion'));
  const androidVersions = Array.from(
    new Set(androidArray.map(item => item.deviceVersion).filter(e => e)),
  );
  dispatch(success(drivers, iosVersions, androidVersions));
};

export const driversRequested = () => async dispatch => {
  dispatch({ type: 'DRIVERS_REQUESTED' });

  const drivers = await driver.get().catch(error => {
    swal(error.response.data.message, 'Unable to load Drivers', 'error');
    return dispatch({ type: 'DRIVERS_REQUEST_FAILED' });
  });

  dispatch(lookUpActions.refresh(drivers.data.data, 'drivers'));
  return dispatch(initSortedDrivers(drivers.data.data));
};

export const changeFilters = filters => ({
  type: 'DRIVER_LIST_CHANGE_FILTERS',
  filters,
});

export const setModalValue = (value, name) => ({
  type: 'DRIVER_LIST_SET_MODAL_VALUE',
  value,
  name,
});

export const closeEditModal = () => ({ type: 'DRIVER_LIST_CLEAR_EDIT_MODAL' });

export const resetDistrictsLoadedRequested = () => ({
  type: 'DRIVER_LIST_DISTRICTS_NOT_LOADED',
});

export const getCarriers = () => dispatch => {
  const save = carriers => ({
    type: 'DRIVER_LIST_SET_CARRIERS',
    carriers,
  });
  carrier
    .getCarriers()
    .then(response => {
      dispatch(save(response.data.data.carriers));
    })
    .catch(error => {
      swal(error.response.data.error || 'Something went wrong.', '', 'error');
    });
};

export const setFilterValue = (value, name) => ({
  type: 'DRIVER_LIST_SET_FILTER_VALUE',
  value,
  name,
});

// Driver notification actions
export const setDriverDetails = data => ({
  type: 'DRIVER_LIST_SET_DRIVER_DETAILS',
  driver: data,
});

export const sendMessage = close => (dispatch, getState) => {
  const state = getState();

  const {
    driversNew: {
      notification: { message, ids },
    },
  } = state;

  if (!Object.values(ids).includes(true)) {
    swal('Please select at least one driver', '', 'error');
    return;
  }

  let idsList = [];
  idsList = Object.keys(ids).filter(item => ids[item]);
  if (message.trim().length > 0) {
    driver
      .sendMessage({ driversId: idsList, message })
      .then(response => {
        swal(response.data.message, '', 'success');
        close();
      })
      .catch(error => {
        swal(
          error?.response?.data?.message ||
          'All Notifications could not be delivered',
          'Failed to send Message',
          'error',
        );
        close();
      });
  }
};

export const setNotificationValue = (value, name) => ({
  type: 'DRIVER_LIST_MODAL_NOTIFICATION_VALUE',
  value,
  name,
});

export const selectFree = () => ({ type: 'DRIVER_LIST_SELECT_FREE_DRIVER' });
export const selectBusy = () => ({ type: 'DRIVER_LIST_SELECT_BUSY_DRIVER' });

export const checkDriver = (id, status) => ({
  type: 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_DRIVER',
  id,
  status,
});
export const selectAll = (drivers, status) => dispatch => {
  const ids = {};
  drivers.forEach(d => {
    ids[d.id] = status;
  });
  dispatch({
    type: 'DRIVER_LIST_MODAL_NOTIFICATION_CHECK_ALL_DRIVER',
    ids,
    status,
  });
};

export const clearNotificationState = () => ({
  type: 'DRIVER_LIST_CLEAR_STORE_SEND_NOTIFICATION',
});
