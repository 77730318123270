import { useMemo, useEffect, useReducer, useCallback } from 'react';
import { useDebounce } from 'helpers/hooks';

export const useQueryFilters = (filters) => {
  const debouncedFilters = useDebounce(filters, 500);

  const mappedFilters = useMemo(
    () => ({
      driverName: debouncedFilters.name?.length
        ? debouncedFilters.name
        : undefined,
      isDeleted: debouncedFilters.deletedStatus ? 1 : 0,
      driverId: debouncedFilters.id?.length
        ? Number(debouncedFilters.id)
        : undefined,
      driverEmail: debouncedFilters.email?.length
        ? debouncedFilters.email
        : undefined,
      driverPhone: debouncedFilters.phone?.length
        ? debouncedFilters.phone
        : undefined,
      locationPermission:
        debouncedFilters.location !== null &&
          debouncedFilters.location !== undefined &&
          debouncedFilters.location !== ''
          ? Number(debouncedFilters.location)
          : undefined,
      districtDetail: debouncedFilters.district?.length
        ? debouncedFilters.district
        : undefined,
      truckNumber: debouncedFilters.truck?.length
        ? debouncedFilters.truck
        : undefined,
      carrierName: debouncedFilters.carrier?.length
        ? debouncedFilters.carrier
        : undefined,
      status: [0, 1].includes(debouncedFilters.freeBusyStatus)
        ? debouncedFilters.freeBusyStatus
        : undefined,
      androidVersion: debouncedFilters.android?.length
        ? debouncedFilters.android
        : undefined,
      iOSVersion: debouncedFilters.ios?.length
        ? debouncedFilters.ios
        : undefined,
      appVersion: debouncedFilters.appVersion?.length
        ? debouncedFilters.appVersion
        : undefined,
    }),
    [debouncedFilters],
  );

  return mappedFilters;
};

const initFilters = {
  id: '',
  name: '',
  email: '',
  contact_no: '',
  district: '',
  districts: [],
  areDistrictsLoaded: false,
  truck: '',
  carrier: '',
  location: '',
  android: '',
  ios: '',
  freeBusyStatus: '',
  deletedStatus: false,
  page: 1,
  pageLimit: 10,
};

const DriverFiltersReducer = (state, action) => {
  switch (action.type) {
    case 'set_filter':
      return {
        ...state,
        ...action.payload,
        page: 1,
      };
    case 'set_page':
      return {
        ...state,
        page: action.payload
      };
    case 'reset_filters':
      return {
        ...initFilters,
      };
    default:
      return state;
  }
};

export const useDriverFilters = () => {
  const [state, dispatch] = useReducer(DriverFiltersReducer, initFilters);

  const handlers = useMemo(() => ({
    setFilter: (value, name) => dispatch({ type: 'set_filter', payload: { [name]: value } }),
    setPage: ({ currentPage: page }) => dispatch({ type: 'set_page', payload: page })
  }), [dispatch]);

  useEffect(() => () => dispatch({ type: 'reset_filters' }), [dispatch]);

  return [state, handlers];
};
