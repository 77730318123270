import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import authService from 'services/auth/service';

import { getOrderComments, addOrderComment } from './queries';

export const useOrderComments = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'comments'],
    queryFn: () => getOrderComments(orderId),
    enabled: !!orderId,
  });

export const useAddOrderComment = ({ orderId }) => {
  const queryClient = useQueryClient();
  const user = authService.getUser();

  return useMutation({
    mutationFn: comment => addOrderComment({ orderId, comment }),
    onMutate: async newComment => {
      const queryKey = ['orders', Number(orderId), 'comments'];
      // Optimistic update
      await queryClient.cancelQueries(queryKey);
      const prevComments = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, oldComments => [
        ...oldComments,
        {
          comment: newComment,
          is_system: 0,
          character_id: user.id,
          character_type: user.name,
          created_at: new Date(),
          profile_url: 'https://api.dev.automatizelabs.com/v2/documents/1098',
        },
      ]);
      return prevComments;
    },
    onSuccess: () => {
      // swal('Successfully Added Order Comment', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['orders', Number(orderId), 'comments'],
      });
    },
    onError: error =>
      swal(
        'Unable to Add Order Comment',
        error.response?.data?.message,
        'error',
      ),
  });
};
