import React from 'react';

import Loader from 'components/shared/Loader';
import Label from 'components/shared/Label';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';

import { useOrderNotarizations } from 'api/v2/orders';

const NotarizationsReport = ({ order }) => {
  const { data: report, isLoading, isError } = useOrderNotarizations({
    orderId: order?.order_id,
  });

  if (isLoading) {
    return (
      <div className="row" style={{ paddingLeft: 0 }}>
        <Loader />
      </div>
    );
  }

  if (isError || !report?.steps?.length) {
    return (
      <div className="row" style={{ paddingLeft: 0 }}>
        <div>
          <ul className="unstyled">
            <li>
              <strong>Mileage: </strong>
              <span>{order.mileage || 'unknown'}</span>
            </li>
            <li>
              <Label
                type="warning"
                testSelector="notarization-report_warning_label">
                Notarization data is not available for this order
              </Label>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  const renderSteps = step => {
    if (step.stepType === 12) {
      return (
        <li key={step.stepType}>
          <Authorize access={['CARRIER', 'JOB_MANAGER']}>
            {step.isSuccessful ? <Icon icon="true" /> : <Icon icon="false" />}
            <div className="notarization__block">
              <strong className="notarization__block-title">
                {step.stepName}
              </strong>
              {step.description && step.description.length > 0 && (
                <span>: {step.description}</span>
              )}
            </div>
          </Authorize>
        </li>
      );
    }

    return (
      <li key={step.stepType}>
        <div className="notarization__block">
          {step.isSuccessful ? <Icon icon="true" /> : <Icon icon="false" />}
          <strong className="notarization__block-title">{step.stepName}</strong>
          {step.description && step.description.length > 0 && (
            <span>: {step.description}</span>
          )}
        </div>
      </li>
    );
  };

  return (
    <div className="row" style={{ paddingLeft: 0 }}>
      <ul className="unstyled">{report.steps.map(renderSteps)}</ul>
    </div>
  );
};

export default NotarizationsReport;
