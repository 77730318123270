import React, { useState, useMemo, useEffect } from 'react';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import Modal, { ModalForm, FullWidth } from 'components/shared/Modal';
import targetValue from 'utils/targetValue';
import { useCarrierDistricts } from 'api/v2/carriers';
import { useDriverDistricts, useChangeDriverDistricts } from 'api/v2/drivers';

const ChangeDistrict = ({
  showModalDistrict,
  closeDistrict,
  driver
}) => {
  const { data: driverDistricts, isLoading: isDriverDistrictsLoading } = useDriverDistricts({ driverId: Number(driver.id) });
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  useEffect(() => {
    if (driverDistricts?.length && !isDriverDistrictsLoading) {
      setSelectedDistricts(
        driverDistricts.map(district => ({
          ...district,
          value: district.id,
          label: district.name
        }))
      );
    }
  }, [driverDistricts, isDriverDistrictsLoading, setSelectedDistricts]);


  const { data: districts, isLoading: isCarrierDistrictsLoading } = useCarrierDistricts({ carrierId: driver.carrier_id });
  const districtOptions = useMemo(() => districts?.length && !isCarrierDistrictsLoading
    ? districts.map(item => ({
      ...item,
      value: item.id,
      label: `#${item.id} | ${item.name}`,
    }))
    : [], [districts, isCarrierDistrictsLoading]);

  const { mutate: changeDistricts, isPending: changeDistrictsPending } = useChangeDriverDistricts();

  const handleSubmit = e => {
    e.preventDefault();
    changeDistricts(
      {
        driverId: Number(driver.id),
        districtIds: selectedDistricts.map(district => district.id)
      },
      {
        onSuccess: closeDistrict
      }
    );
  }

  const saveDisabled = useMemo(() =>
  (!selectedDistricts?.length ||
    !districtOptions.length ||
    changeDistrictsPending),
    [selectedDistricts, districtOptions, changeDistrictsPending]
  );

  const header = <h3>Edit District</h3>;
  const body = (
    <ModalForm>
      <FullWidth>
        {(!isCarrierDistrictsLoading && (
          <>
            {
              districtOptions.length ? (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Districts"
                  onChange={e => setSelectedDistricts(targetValue(e))}
                  options={districtOptions}
                  value={selectedDistricts}
                  required
                  testSelector="drivers_change-district_districts_select"
                />
              ) : (
                <span>There is no districts for this driver</span>
              )
            }
          </>
        )) || <Loader />}
      </FullWidth>
    </ModalForm >
  );
  const footer = (
    <>
      <Button
        onClick={() => {
          closeDistrict();
        }}
        colour="white"
        testSelector="drivers-list_change-district_close_btn">
        Close
      </Button>
      <Button
        disabled={saveDisabled}
        onClick={handleSubmit}
        testSelector="drivers-list_change-district_save_btn">
        Save
      </Button>
    </>
  );
  return (
    <Modal
      header={header}
      body={body}
      footer={footer}
      open={showModalDistrict}
      onClose={closeDistrict}
    />
  );
}

export default ChangeDistrict;
