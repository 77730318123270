import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import FileSaver from 'file-saver';

import { exportService } from 'services/export/service';
import { setAllFields } from 'components/views/ExportPage/Templates/AddTemplate/helpers';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import { createFieldsUrl } from 'components/views/ExportPage/helpers';

const getTemplates = () => dispatch => {
  const successTemplate = templates => ({
    type: 'EXPORT_GET_TEMPLATES_SUCCESS',
    templates,
  });
  exportService
    .getTemplates()
    .then(response => {
      dispatch(successTemplate(response.data));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const init = () => (dispatch, getState) => {
  dispatch({ type: 'EXPORT_MODAL_PREVIEW_CLOSE' });
  const successConfig = config => ({
    type: 'EXPORT_GET_CONFIG_SUCCESS',
    config,
  });
  const {
    exportPage: {
      exportConfig: { isAllChecked },
    },
  } = getState();

  exportService
    .getConfig()
    .then(response => {
      dispatch(successConfig(setAllFields(response.data.fields, isAllChecked)));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));

  dispatch(getTemplates());
  dispatch(resourceActions.getAllCarriers());
  dispatch(resourceActions.getJobs());
  dispatch(resourceActions.getCustomers());
};

const clearModalAddTemplate = () => ({
  type: 'EXPORT_CLEAR_MODAL_ADD_TEMPLATE',
});

const deleteTemplate = id => dispatch => {
  exportService
    .deleteTemplate(id)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(getTemplates());
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getTemplate = id => dispatch => {
  const request = () => ({
    type: 'EXPORT_GET_TEMPLATE_REQUEST',
  });
  const success = template => ({
    type: 'EXPORT_GET_TEMPLATE_SUCCESS',
    template,
  });
  dispatch(request());
  exportService
    .getTemplate(id)
    .then(msg => {
      dispatch(success(msg.data.data.template));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getLookUpTemplates = () => dispatch => {
  const success = templates => ({
    type: 'EXPORT_GET_LOOKUP_TEMPLATE_SUCCESS',
    templates,
  });

  exportService
    .getLookupTemplates()
    .then(msg => {
      dispatch(success(msg.data.data.invoiceTemplates));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const clearTemplateModal = () => ({
  type: 'EXPORT_TEMPLATE_CLEAR_MODAL',
});

// Add template modal actions

const checkAllFields = () => ({ type: 'EXPORT_CHECK_ALL_FIELDS' });
const checkOneField = name => ({ type: 'EXPORT_CHECK_ONE_FIELD', name });
const setTemplateName = name => ({ type: 'EXPORT_SET_TEMPLATE_NAME', name });

const createTemplate = (closeModal, itemList) => (dispatch, getState) => {
  const {
    exportPage: {
      exportConfig: { name },
    },
  } = getState();
  exportService
    .createTemplate(name, createFieldsUrl(itemList))
    .then(() => {
      swal('Success', '', 'success');
      closeModal();
    })
    .catch(error => {
      swal(error.response?.data?.message || 'error', '', 'error');
    });
};

// Orders actions

const setFilterValue = (value, name) => ({
  type: 'SET_EXPORT_SETTINGS',
  value,
  name,
});

const download = (filters, selectedBtn) => (dispatch, getState) => {
  const state = getState();
  const { exportSettings } = state.exportPage;
  const { template, format, sort, timeZone, invoiceId } = exportSettings;
  const { templates } = state.exportPage.templates;

  const {
    statuses,
    carrierIds,
    customerIds,
    jobIds,
    startDate,
    endDate,
    districtIds,
  } = filters || {};

  let params = {
    fields: templates[template] && templates[template].fields,
    invoiceId,
    startDate: moment(
      moment(startDate)
        .hour(0)
        .minute(0)
        .second(0),
    )
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(
      moment(endDate)
        .hour(23)
        .minute(59)
        .second(59),
    )
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    format,
    sortBy: sort,
    statuses: statuses.map(item => item.value).join(','),
    customerIds: (customerIds || []).map(customer => Number(customer.value)),
    carrierIds: (carrierIds || []).map(carrier => Number(carrier.value)),
    jobIds: (jobIds || []).map(job => Number(job.value)),
    districtIds: (districtIds || []).map(district => Number(district.value)),
    tz: timeZone,
  };

  const removeFalsy = obj => {
    const newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop] != '') {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };
  // remove empty elements
  params = removeFalsy(params);

  switch (format) {
    case 'csv':
    case 'json':
      {
        switch (selectedBtn) {
          case 'preview':
            dispatch(previewFile(params));
            break;
          case 'download':
            getFile(params);
            break;
        }
      }
      break;
    case 'packet_invoice':
    case 'sand_tickets':
      dispatch(getPacket(params));
      break;
    default:
      break;
  }
};

const getFile = params => {
  exportService
    .getFile(params)
    .then(msg => {
      const hiddenElement = document.createElement('a');
      switch (params.format) {
        case 'csv':
          {
            const blob = new Blob([msg.data], {
              type: 'application/csv;charset=utf-8',
            });
            hiddenElement.href = window.URL.createObjectURL(blob);
            hiddenElement.download = 'export.csv';
          }
          break;
        case 'json':
          {
            const blob = new Blob([JSON.stringify(msg.data)], {
              type: 'application/json;charset=utf-8',
            });
            hiddenElement.href = window.URL.createObjectURL(blob);
            hiddenElement.download = 'export.json';
          }
          break;
        default:
          break;
      }
      hiddenElement.click();
    })
    .catch(error =>
      swal(
        'Error Downloading Export',
        error?.response?.data?.message || error?.response?.data,
        'error',
      ),
    );
};

const previewFile = params => dispatch => {
  const initPreviewModal = (data, format) => ({
    type: 'EXPORT_MODAL_PREVIEW_INIT_DATA',
    data,
    format,
  });
  params.limit = true;
  exportService
    .getFile(params)
    .then(msg => {
      dispatch(initPreviewModal(msg.data, params.format));
    })
    .catch(error =>
      swal(
        'Error Previewing Export',
        error?.response?.data?.message || error?.response?.data,
        'error',
      ),
    );
};

const getPacket = params => dispatch => {
  const saveModalPacketInvoiceData = info => ({
    type: 'EXPORT_MODAL_PACKET_INVOICE_INIT_DATA',
    info,
  });
  swal(
    {
      title: 'Are you sure?',
      text:
        'It may take a while to prepare and download packet invoice. Downloading may take up to 15 min',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true,
      showLoaderOnConfirm: true,
    },
    isConfirm => {
      if (isConfirm) {
        const {
          startDate,
          endDate,
          format,
          customerIds,
          carrierIds,
          jobIds,
          invoiceId,
        } = params;
        const data = {
          customerIds,
          carrierIds,
          jobIds,
          from: startDate,
          to: endDate,
          sandTicketsOnly: format == 'sand_tickets' ? 1 : 0,
          invoiceId,
        };
        exportService
          .bulkInvoicePreview(data)
          .then(msg => {
            const modalObj = {
              data,
              chunks: msg.data.data.chunks,
            };
            dispatch(saveModalPacketInvoiceData(modalObj));
          })
          .catch(error => swal(error.response.data.message, '', 'error'));
      }
    },
  );
};

// Preview Modal
const turnPreviewModal = () => ({ type: 'EXPORT_MODAL_PREVIEW_CLOSE' });

const initPreviewModal = () => (dispatch, getState) => {
  const dataReady = data => ({
    type: 'EXPORT_MODAL_PREVIEW_DATA_READY',
    data,
  });

  const state = getState();
  const { modalPreview } = state.exportPage;
  const { format, data } = modalPreview;
  let newData = [];

  const processData = allText => {
    const allTextLines =
      typeof allText === 'string' ? allText.split(/\r\n|\n/) : [];
    const headers = allTextLines.length ? allTextLines[0].split(',') : '';
    const lines = [];
    allTextLines.forEach(item => {
      const data = item.split(',');
      if (data.length == headers.length) {
        const tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    });
    return lines;
  };

  switch (format) {
    case 'csv':
      newData = processData(data);
      break;
    case 'json':
      newData = JSON.stringify(data, null, '\t');
  }

  dispatch(dataReady(newData));
};

// Packet Invoice Modal
const turnPacketInvoiceModal = () => ({
  type: 'EXPORT_MODAL_PACKET_INVOICE_TURN',
});

const downloadInvoice = () => (dispatch, getState) => {
  const addCount = () => ({ type: 'EXPORT_MODAL_PACKET_ADD_COUNT' });

  const state = getState();
  const { info } = state.exportPage.modalPacketInvoice;
  const { count } = state.exportPage.modalPacketInvoice;
  const data = {
    ...info.data,
    chunk: info.chunks[count],
  };
  exportService
    .bulkInvoiceGenerate(data)
    .then(msg => {
      const file = new Blob([msg.data], { type: 'application/zip' });
      FileSaver.saveAs(file, 'packetinvoice.zip');
      dispatch(addCount());
      if (count < info.chunks.length - 1) {
        dispatch(downloadInvoice());
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const actions = {
  init,
  clearModalAddTemplate,
  getTemplates,
  deleteTemplate,
  getTemplate,
  clearTemplateModal,
  getLookUpTemplates,

  checkAllFields,
  checkOneField,
  setTemplateName,
  createTemplate,

  setFilterValue,
  download,

  initPreviewModal,
  turnPreviewModal,

  turnPacketInvoiceModal,
  downloadInvoice,
};

export default actions;
