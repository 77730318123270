import React from 'react';
import Icon from 'components/shared/Icon';

const DistrictBox = ({ id, name }) => (
  <div className="col-md-2 col-sm-3 text-center district-item">
    <Icon icon="exchange" /> district
    <br />
    <strong className="text-navy">
      <span>#{id}: </span><span>{name}</span>
    </strong>
  </div>
);

export default DistrictBox;
