import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import authService from 'services/auth/service';
import actions from 'store/actions/Equipments';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import eventTarget from 'utils/dispatchWithFieldValue';
import { useLocation } from 'react-router-dom';
import { findCustomerWellSites, findCustomer } from './helpers';

const AddTrailer = ({
  showAddTrailer,
  closeAddTrailer,
  vendors,
  bulkUploadContainers,
  containerNumbers,
  wellSites,
  setTrailerNumber,
  setWellSiteLocation,
  setHomeLocation,
  setVendor,
  addTrailer,
  clearAddTrailerData,
  trailerInfo,
  isTrailer,
  customers,
  setCustomerId,
  customerId,
}) => {
  const { pathname } = useLocation();
  const user = authService.getUser();

  const header = () => {
    if (isTrailer) {
      return "Add Trailer";
    }

    if (!bulkUploadContainers) {
      return "Add Container";
    }

    return "Bulk Upload Containers";
  }

  const { trailerNumber, vendorId, wellSiteId, homeLocationId } = trailerInfo;

  const customerList = findCustomer(user, customers).map(customer => ({
    value: customer.id,
    label: `${customer.id} | ${customer.name}`,
  }));

  const vendorList = vendors.map(vendor => ({
    value: vendor.id,
    label: `${vendor.id} | ${vendor.name}`,
  }));

  const wellSitesList = findCustomerWellSites(customerId, wellSites).map(
    item => ({
      value: item.id,
      label: `${item.id} | ${item.name}`,
    }),
  );
  if (customerList.length === 1) {
    setCustomerId(customerList[0].value);
  }

  const saveDisabled = useMemo(() => (
    !customerId ||
    (!bulkUploadContainers && !trailerNumber.trim().length) ||
    !vendorId ||
    !wellSiteId ||
    (!isTrailer && !homeLocationId)
  ), [customerId, trailerNumber, vendorId, wellSiteId, homeLocationId, isTrailer, bulkUploadContainers]);


  const saveTrailer = e => {
    e.preventDefault();
    const type = pathname.split('/')[3] === 'containers' ? 2 : 1;
    addTrailer(type, bulkUploadContainers, containerNumbers);
  };

  useEffect(() => () => {
    clearAddTrailerData();
  }, []);

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showAddTrailer}
      onHide={closeAddTrailer}>
      <form onSubmit={saveTrailer} className="m-t" role="form">
        <Modal.Header>
          <h3>{header()}</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {!bulkUploadContainers && (
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={setTrailerNumber}
                  label={isTrailer ? 'Trailer number' : 'Container number'}
                  required
                  testSelector="equipment-trailers_add-trailer_trailer-number_input"
                />
              </div>)}
            <div className="form-group has-feedback col-md-6">
              {customerList.length > 1 ? (
                <Select
                  options={customerList}
                  value={customerList.find(item => item.value === customerId)}
                  onChange={item => {
                    setCustomerId(item.value);
                    setWellSiteLocation('');
                    setHomeLocation('');
                  }}
                  placeholder="Customer"
                  required
                  testSelector="equipment-trailers_add_customer_select"
                />
              ) : (
                <Input
                  type="text"
                  value={customerList[0].label}
                  label="Customer"
                  disabled
                  testSelector="equipment-trailers_add-trailer_customer_input"
                />
              )}
            </div>
            {bulkUploadContainers && (
              <div className="form-group has-feedback col-md-6">
                <Select
                  options={wellSitesList}
                  isDisabled={!customerId}
                  onChange={item => setHomeLocation(item.value)}
                  placeholder="Home Location"
                  required
                  testSelector="equipment-trailers_add_home_location_select"
                />
              </div>
            )}

          </Row>

          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                options={vendorList}
                onChange={item => setVendor(item.value)}
                placeholder="Vendor"
                required
                testSelector="equipment-trailers_add_vendor_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Select
                options={wellSitesList}
                isDisabled={!customerId}
                onChange={item => setWellSiteLocation(item.value)}
                placeholder="Location"
                value={wellSitesList.find(w => w.value === wellSiteId)} 
                required
                testSelector="equipment-trailers_add_location_select"
              />
            </div>
          </Row>
          {!isTrailer && !bulkUploadContainers && (
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  options={wellSitesList}
                  isDisabled={!customerId}
                  onChange={item => setHomeLocation(item.value)}
                  value={wellSitesList.find(w => w.value === homeLocationId)} 
                  placeholder="Home Location"
                  required
                  testSelector="equipment-trailers_add_home_location_select"
                />
              </div>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeAddTrailer}
            colour="white"
            testSelector="equipments_add-trailer_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            testSelector="equipments_add-trailer_save_btn"
            disabled={saveDisabled}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  vendors: state.resourceReducer.containerVendors.vendors,
  wellSites: state.lookUp.wellSites,
  customerId: state.equipmentTrailers.equipmentStatus.customerId,
  trailerInfo: state.equipmentTrailers.addTrailer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTrailerNumber: eventTarget(actions.setTrailerNumber, dispatch),
  setVendor: vendor => dispatch(actions.setTrailerVendor(vendor)),
  setWellSiteLocation: wellsite =>
    dispatch(actions.setWellSiteLocation(wellsite)),
  setHomeLocation: homeLocation =>
    dispatch(actions.setHomeLocation(homeLocation)),
  addTrailer: (type, bulkUpload, containerNumbers) => {
    dispatch(actions.addTrailer(ownProps.closeAddTrailer, type, bulkUpload, containerNumbers));
  },
  setCustomerId: id => dispatch(actions.setCustomerId(id)),
  clearAddTrailerData: () => dispatch(actions.clearAddTrailerData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTrailer);
