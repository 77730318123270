import { useReducer, useEffect, useMemo } from 'react';

const initSendNotificationDriverFilters = {
  driverId: '',
  name: '',
  free: false,
  page: 1,
  busy: false,
  district: '',
  truck: '',
  carrier: '',
}

const SendNotificationDriverFiltersReducer = (state, action) => {
  switch (action.type) {
    case 'set_filter':
      return {
        ...state,
        ...action.payload,
        page: 1,
      };
    case 'set_page':
      return {
        ...state,
        page: action.payload
      };
    case 'reset_filters':
      return {
        ...initSendNotificationDriverFilters,
      };
    case 'select_free':
      return {
        ...state,
        free: !state.free,
        page: 1,
        busy: false
      }
    case 'select_busy':
      return {
        ...state,
        busy: !state.busy,
        page: 1,
        free: false
      }
    default:
      return state;
  }
}


export const useSendNotificationDriverFilters = () => {
  const [filters, dispatch] = useReducer(
    SendNotificationDriverFiltersReducer,
    initSendNotificationDriverFilters,
  );

  const handlers = useMemo(() => ({
    setFilter: (value, name) => dispatch({ type: 'set_filter', payload: { [name]: value } }),
    setPage: page => dispatch({ type: 'set_page', payload: page }),
    selectBusy: () => dispatch({ type: 'select_busy' }),
    selectFree: () => dispatch({ type: 'select_free' })
  }), []);

  useEffect(() => () => dispatch({ type: 'reset_filters' }), [dispatch]);

  return [filters, handlers];
}

const initSenNotificationForm = {
  recipients: {},
  selectAllLabel: false

}

const SendNotificationFormReducer = (state, action) => {
  switch (action.type) {
    case 'select_all':
      return {
        ...state,
        selectAllLabel: action.payload.status,
        recipients: action.payload.drivers.reduce((acc, driver) => {
          acc[driver.id] = action.payload.status;
          return acc;
        }, {})
      }
    case 'check_driver':
      return {
        ...state,
        recipients: {
          ...state.recipients,
          [action.payload.id]: action.payload.status
        }
      }
    case 'reset_form':
      return {
        ...initSenNotificationForm
      }
    default:
      return state;
  }
}

export const useSendNotificationForm = () => {
  const [form, dispatch] = useReducer(
    SendNotificationFormReducer,
    initSenNotificationForm
  );

  const handlers = useMemo(() => ({
    selectAll: (drivers, status) => dispatch({ type: 'select_all', payload: { status, drivers } }),
    checkDriver: (id, status) => dispatch({ type: 'check_driver', payload: { id, status } })
  }), [dispatch]);

  useEffect(() => () => dispatch({ type: 'reset_form' }), [dispatch]);

  return [form, handlers];
}
